import { baseUrl, headers, jwtHeaders, logoutUtil } from "../common/data";

export const fetchAllFeedbacks = async (branchDetails) => {
    const response = await fetch(`${baseUrl}/api/feedback/getallfeedbacks?branchDetails=${branchDetails}`, {
        method: "GET",
        headers: jwtHeaders(),
        redirect: "follow",
    });
    if (response?.status === 401) {
        logoutUtil();
    }
    const resData = await response?.json();
    
    return resData;
};

export const createFeedback = async (data) => {

        const response = await fetch(`${baseUrl}/api/feedback/create`, {
            method: 'POST',
            body: data,
            // headers: headers
            headers: jwtHeaders()
        })
        const resData = await response?.json();
        
        return resData;

};

export const deleteFeedback = async (id) => {

        const response = await fetch(`${baseUrl}/api/billing/deletebill/${id}`, {
            method: 'DELETE',
            // headers: headers,
            headers: jwtHeaders(),
        })
        const resData = await response?.json();
        
        return resData;

};