// // correct pagination code
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import FieldData from './FieldData';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import SearchImg from '../../assets/search.svg';
// import { useNavigate } from 'react-router';

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllStaffs = () => {
//   const [data, setData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [filteredData, setFilteredData] = useState([]);
//   const { fetchAllStaffs, activeBranchId } = useMain();

//   const navigate = useNavigate()

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setGenderBy('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     try {
//       setShowLoader(true);
//       const res = await fetchAllStaffs(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//         // Handle error
//       }
//     } catch (error) {
//       // Handle error
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, genderBy, search, data]);

//   const applyFilters = () => {
//     let filteredData = data?.filter((item) => {
//       let isGenderMatch = true;
//       let isNameMatch = true;

//       if (genderBy) {
//         isGenderMatch = item?.gender === genderBy;
//       }

//       if (search) {
//         isNameMatch = (item?.name?.toLowerCase().includes(search.toLowerCase()) || item?.mail?.toLowerCase().includes(search.toLowerCase()) || item?.phone.toString().includes(search));
//       }

//       return isGenderMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilteredData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="Manage Active Staffs" description="This contains all data">
//       <DashboardCard2 title="Manage Active Staffs">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2} marginTop={3.4} marginRight={-40}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   // width: '100%',
//                   width: '80%',
//                   marginLeft: '-5rem',
//                   background: '#6174dd',
//                   '&:hover': {
//                     background: '#5d87ff',
//                   },
//                 }}
//                 onClick={() => {
//                   navigate('/WorkAnalysis')
//                 }}
//               >
//                 Work Analysis
//               </Button>
//             </Grid>
//             <Grid item xs={1.3} marginRight={-30}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={1.3} marginRight={-40}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   // width: '100%',
//                   width: '60%',
//                   marginLeft:'5rem',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) : filteredData && filteredData?.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData data={filteredData} setData={setData} getData={getData} />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         {/* Only show pagination if there is data */}
//         {data.length > 0 && (
//           <Box display={'flex'} justifyContent={'flex-end'}>
//             <Pagination
//               count={Math.ceil(data.length / limit)}
//               page={page}
//               color="primary"
//               onChange={(e, page) => setPage(page)}
//             />
//           </Box>
//         )}
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllStaffs;

import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import FieldData from './FieldData';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import { useNavigate } from 'react-router';
import ClearIcon from '@mui/icons-material/Close';

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Both', label: 'Both' },
];

const AllStaffs = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  // const [clear, setClear] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const { fetchAllStaffs, activeBranchId } = useMain();

  const navigate = useNavigate()

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    // if (clear) {
    //   getData('clear');
    //   setClear(false);
    // }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      // setClear(true);
    }
  };

  const getData = async (key) => {
    try {
      setShowLoader(true);
      const res = await fetchAllStaffs(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      // setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  // useEffect(() => {
  //   applyFilters();
  // }, [limit, page, genderBy, search, data]);

  // const applyFilters = () => {
  //   let filteredData = data?.filter((item) => {
  //     let isGenderMatch = true;
  //     let isNameMatch = true;

  //     if (genderBy) {
  //       isGenderMatch = item?.gender === genderBy;
  //     }

  //     if (search) {
  //       isNameMatch = (item?.name?.toLowerCase().includes(search.toLowerCase()) || item?.mail?.toLowerCase().includes(search.toLowerCase()) || item?.phone.toString().includes(search));
  //     }

  //     return isGenderMatch && isNameMatch;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilteredData(filteredData);
  // };

  useEffect(() => {
    const applyFilters = () => {
      let filteredData = data?.filter((item) => {
        let isGenderMatch = true;
        let isNameMatch = true;

        // if (genderBy) {
        //   isGenderMatch = item?.gender === genderBy;
        // }

        if (genderBy && genderBy !== 'Both') {
          isGenderMatch = item?.gender === genderBy;
        }

        if (search) {
          isNameMatch = (
            item?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item?.mail?.toLowerCase().includes(search.toLowerCase()) ||
            item?.phone.toString().includes(search)
          );
        }

        return isGenderMatch && isNameMatch;
      });

      // Update filtered data
      setFilteredData(filteredData);
      // Reset page to 1 when filters change
      setPage(1);
    };

    applyFilters();
  }, [data, limit, genderBy, search]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const currentData = filteredData.slice((page - 1) * limit, page * limit);

  return (
    <PageContainer title="Manage Active Staffs" description="This contains all data">
      <DashboardCard2 title="Manage Active Staffs">

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem',
          marginLeft: '4rem',
        }}>
          <div style={{ flex: '1 1 100px', marginLeft: '-4rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                // width: '90%',
                width: '83%',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{
            flex: '0 1 120px',
            marginLeft: '37rem'
          }}>
            <InputLabel style={{marginLeft:'0.9rem'}}>Gender</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                width: '100%',
                marginLeft:'0.8rem'
              }}
              value={genderBy}
              onChange={(e) => setGenderBy(e.target.value)}
            >
              {filterGenderWise.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div
            style={{ flex: '1 1 120px', marginLeft: '1.7rem' }}
            onClick={() => document.getElementById('search-input').focus()}
          >
            <InputLabel>Search</InputLabel>
            <div
              className="search-input"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={SearchImg} alt="" style={{ marginRight: '8px' }} />
              <input
                id="search-input"
                style={{
                  flex: 1,
                  padding: '4px 2px',
                  borderRadius: '7px',
                }}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent focus change when clearing
                    setSearch('');
                    // setClear(false);
                  }}
                  style={{ padding: 1 }}
                >
                  <ClearIcon style={{
                    color: '#000',
                    marginRight: '-0.2rem',
                    marginTop: '0.1rem',
                    fontSize: '1.1rem'
                  }} />
                </IconButton>
              )}
            </div>
          </div>

          {/* Buttons in a separate flex container */}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1.1rem', // Space between buttons
            flex: '1 1 auto', // Allows the button container to shrink/grow
            marginTop: '1.6rem', // Decreased margin top to reduce space
          }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  width: 'auto', // Auto width for the button
                  background: '#6174dd',
                  '&:hover': {
                    background: '#5d87ff',
                  },
                }}
                onClick={() => navigate('/WorkAnalysis')}
              >
                Work Analysis
              </Button>
            </div>

            {/* <Button
              variant="contained"
              color="primary"
              sx={{
                height: '40px',
                width: '100px', // Fixed width for the button
                background: '#ff4d4d',
                '&:hover': {
                  background: '#ff6666',
                },
              }}
              onClick={clearDataHandler}
            >
              Clear
            </Button> */}
          </div>
        </div>

        {showLoader ? (
          <ShowLoader />
        ) : currentData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <FieldData data={currentData} setData={setData} getData={getData} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        {/* Only show pagination if there is data */}
        {filteredData.length > 0 && (
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Pagination
              count={Math.ceil(filteredData.length / limit)}
              page={page}
              color="primary"
              onChange={(e, page) => setPage(page)}
            />
          </Box>
        )}
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllStaffs;