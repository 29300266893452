// import React, { useState } from 'react';
// import { HANDLE_PACKAGE_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

// const PackageCard = ({ data, state, handleUpdateServices, dispatch, onClose }) => {
//   const [selectedServices, setSelectedServices] = useState([]);
//   console.log(data, 'dataP');

//   const container = {
//     display: 'flex',
//     flexDirection: 'column',
//     width: '50vw',
//     minHeight: '90vh',
//     padding: '30px',
//     gap: '10px',
//   };

//   const button = {
//     background: '#6174DD',
//     color: 'white',
//     border: 'none',
//     padding: '10px 30px',
//     cursor: 'pointer',
//     borderRadius: '5px',
//   };

//   const tableContainerStyle = {
//     display: 'grid',
//     gridTemplateColumns: '1fr',
//     gap: '10px',
//   };

//   const serviceCardStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//     padding: '20px',
//     backgroundColor: '#f9f9f9', // Default background
//     borderRadius: '10px',
//     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//     transition: 'background-color 0.3s ease',
//     cursor: 'pointer',
//   };

//   const applyHandler = (data) => {
//     let updatedServices = selectedServices.map((service) => ({
//       ...service,
//       service: service._id,
//       serviceProvider: '',
//       quantity: 1,
//       discount: 0,
//       discountType: '%',
//       amount: service.price,
//     }));

//     dispatch({
//       type: HANDLE_PACKAGE_INPUT_CHANGE,
//       payload: { usePackage: true, item: data, selectedServices: updatedServices },
//     });
//     handleUpdateServices(updatedServices);
//     onClose();
//   };

//   const removeHandler = (data) => {
//     dispatch({
//       type: HANDLE_PACKAGE_INPUT_CHANGE,
//       payload: { usePackage: false, item: data },
//     });
//     onClose();
//   };

//   const handleServiceCheckbox = (service) => {
//     if (selectedServices.some((selected) => selected._id === service._id)) {
//       setSelectedServices(selectedServices.filter((selected) => selected._id !== service._id));
//     } else {
//       setSelectedServices([...selectedServices, service]);
//     }
//   };

//   return (
//     <div style={{ ...container, overflow: 'auto' }}>
//       {/* Table for Package Details */}
//       <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
//         <thead>
//           <tr>
//             {['Package Name', 'Price', 'Validity'].map((h, index) => (
//               <th key={index} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{h}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data?.map((item, index) => (
//             <tr key={index}>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item?.packageName}</td>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>₹ {item?.price}</td>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item?.validity}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Cards for Services */}
//       <div style={tableContainerStyle}>
//         {data?.map((item) =>
//           item?.services?.map((service, serviceIndex) => (
//             <div
//               key={serviceIndex}
//               style={{
//                 ...serviceCardStyle,
//                 backgroundColor: selectedServices.some((selected) => selected._id === service._id) ? '#6174DD' : '#f9f9f9',
//                 color: selectedServices.some((selected) => selected._id === service._id) ? 'white' : 'black',
//               }}
//             >
//               <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <input
//                   type="checkbox"
//                   checked={selectedServices.some((selected) => selected._id === service._id)}
//                   onChange={() => handleServiceCheckbox(service)}
//                 />
//                 <span style={{ marginLeft: '10px' }}>{service.serviceName}</span>
//               </div>
//               <span>₹ {service.price}</span>
//             </div>
//           ))
//         )}
//       </div>

//       {data[0]?.services.length === 0 ? (
//         <div style={{ color: 'red', padding: '20px' }}>
//           The package you purchased has been used. Please buy a new package.
//         </div>
//       ) : (
//         hasMatchingServiceId(data[0]?.services, selectedServices) ? (
//           <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
//             {selectedServices.length > 0 ? (
//               <>
//                 <button style={{ ...button }} onClick={() => applyHandler(data[0])}>
//                   Apply
//                 </button>
//                 {state.usePackage && (
//                   <button style={{ ...button, background: 'red' }} onClick={() => removeHandler(data[0])}>
//                     Remove
//                   </button>
//                 )}
//               </>
//             ) : (
//               <span style={{ color: 'red' }}>Please select a matched service.</span>
//             )}
//           </div>
//         ) : (
//           <span style={{ color: 'red' }}>Please select a matched service.</span>
//         )
//       )}
//     </div>
//   );
// };

// export default PackageCard;

// function hasMatchingServiceId(array1, array2) {
//   const serviceIdsSet = new Set(array1?.map((item) => item._id));
//   return array2.some((item) => serviceIdsSet.has(item._id));
// }

import React, { useState } from 'react';
import { HANDLE_PACKAGE_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

const PackageCard = ({ data, state, handleUpdateServices, dispatch, onClose }) => {
  const [selectedServices, setSelectedServices] = useState([]);
  console.log(data, 'dataP');

  const container = {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    minHeight: '60vh',
    padding: '30px',
    gap: '10px',
  };

  const button = {
    background: '#6174DD',
    color: 'white',
    border: 'none',
    padding: '10px 30px',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  const tableContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '10px',
  };

  const serviceCardStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: '#f9f9f9', // Default background
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
  };

  const applyHandler = (data) => {
    let updatedServices = selectedServices.map((service) => ({
      ...service,
      service: service._id,
      serviceProvider: '',
      quantity: 1,
      discount: 0,
      discountType: '%',
      amount: service.price,
    }));

    dispatch({
      type: HANDLE_PACKAGE_INPUT_CHANGE,
      payload: { usePackage: true, item: data, selectedServices: updatedServices },
    });
    handleUpdateServices(updatedServices);
    onClose();
  };

  const removeHandler = (data) => {
    dispatch({
      type: HANDLE_PACKAGE_INPUT_CHANGE,
      payload: { usePackage: false, item: data },
    });
    onClose();
  };

  const handleServiceCheckbox = (service) => {
    if (selectedServices.some((selected) => selected._id === service._id)) {
      setSelectedServices(selectedServices.filter((selected) => selected._id !== service._id));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  return (
    <div style={{
      ...container,
      overflow: 'hidden', // Prevent outer scroll bars, use inner scroll bars
      maxHeight: '70vh', // Set max height for the dialog
      overflowY: 'auto', // Add vertical scroll if content exceeds 60vh
      padding: '20px', // Optional padding for better spacing
      boxSizing: 'border-box', // Ensure padding is included in height calculation
    }}>

      <div style={{
        // backgroundColor: '#6174DD', // Background color for the heading
        color: 'black', // Text color
        padding: '15px 20px', // Padding for spacing
        borderRadius: '10px 10px 0 0', // Rounded top corners
        fontSize: '1.5rem', // Font size for better visibility
        fontWeight: 'bold', // Bold font for emphasis
        textAlign: 'center', // Center-align text
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        marginBottom: '20px', // Space between heading and content
      }}>
        Package Card
      </div>

      {/* Table for Package Details */}
      <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        overflow: 'hidden',
      }}>
        <thead>
          <tr>
            {['Package Name', 'Price', 'Validity'].map((h, index) => (
              <th
                key={index}
                style={{
                  padding: '15px',
                  backgroundColor: '#6174DD',
                  color: 'white',
                  textAlign: 'center',
                  border:'none',
                }}
              >
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} style={{
              backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
              transition: 'background-color 0.3s',
            }}
            >
              <td style={{
                padding: '12px',
                textAlign: 'center',
              }}>
               <strong>  {item?.packageName} </strong>
              </td>
              <td style={{
                padding: '12px',
                textAlign: 'center',
              }}>
               <strong>  ₹ {item?.price} </strong>
              </td>
              <td style={{
                padding: '12px',
                textAlign: 'center',
              }}>
              <strong>  {item?.validity}</strong>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Cards for Services */}
      <div style={tableContainerStyle}>
        {data?.map((item) =>
          item?.services?.map((service, serviceIndex) => (
            <div
              key={serviceIndex}
              style={{
                ...serviceCardStyle,
                backgroundColor: selectedServices.some((selected) => selected._id === service._id) ? '#F4F0F0' : '#f9f9f9',
                color: selectedServices.some((selected) => selected._id === service._id) ? 'black' : 'black',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={selectedServices.some((selected) => selected._id === service._id)}
                  onChange={() => handleServiceCheckbox(service)}
                />
                <span style={{ marginLeft: '10px' }}>{service.serviceName}</span>
              </div>
              <span>₹ {service.price}</span>
            </div>
          ))
        )}
      </div>

      {data[0]?.services.length === 0 ? (
        <div style={{ color: 'red', padding: '20px' }}>
          The package you purchased has been used. Please buy a new package.
        </div>
      ) : (
        hasMatchingServiceId(data[0]?.services, selectedServices) ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            {selectedServices.length > 0 ? (
              <>
                <button style={{ ...button }} onClick={() => applyHandler(data[0])}>
                  Apply
                </button>
                {state.usePackage && (
                  <button style={{ ...button, background: 'red' }} onClick={() => removeHandler(data[0])}>
                    Remove
                  </button>
                )}
              </>
            ) : (
              <span style={{ color: 'red' }}>Please select a matched service.</span>
            )}
          </div>
        ) : (
          <span style={{ color: 'red' }}>Please select a matched service.</span>
        )
      )}
    </div>

  );
};

export default PackageCard;

function hasMatchingServiceId(array1, array2) {
  const serviceIdsSet = new Set(array1?.map((item) => item._id));
  return array2.some((item) => serviceIdsSet.has(item._id));
}