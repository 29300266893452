import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import FieldData from './FieldData';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import * as XLSX from 'xlsx';
import SearchImg from '../../assets/search.svg';
import Book1service from '../../assets/excel/Book1service.xlsx';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const filterServiceFor = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Both', label: 'Both' },
];

const AllServices = () => {
  const [data, setData] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [serviceFor, setServiceFor] = useState('');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const { fetchAllServices, activeBranchId, createManyService, setShowMessage, deleteManyService } = useMain();

  useEffect(() => {
    getData();
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [data, limit, page, search, serviceFor]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        setUploadedData(jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setServiceFor('');
    getData(); // Re-fetch data without filters
  };

  const getData = async () => {
    try {
      setShowLoader(true);
      const res = await fetchAllServices(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
        setPage(1); // Reset page to 1 when data is fetched
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const applyFilters = () => {
    let filtered = data.filter((item) => {
      const matchesServiceFor = serviceFor ? item?.serviceFor === serviceFor : true;
      const matchesSearch = search ? item?.serviceName?.toLowerCase().includes(search.toLowerCase()) : true;
      return matchesServiceFor && matchesSearch;
    });

    const totalPages = Math.ceil(filtered.length / limit);

    // Reset page if the current page exceeds total pages
    if (page > totalPages) {
      setPage(totalPages || 1);
    }

    // Set filtered data based on the current page and limit
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    setFilteredData(filtered.slice(startIndex, endIndex));
  };

  // Handle clearing the search input
  const handleSearchClear = () => {
    setSearch('');
    setPage(1); // Reset to the first page
    applyFilters(); // Reapply filters to show all data
  };

  // Modify the search input clear button
  {
    search && (
      <IconButton onClick={handleSearchClear} style={{ padding: 1 }}>
        <ClearIcon style={{ color: '#000', marginRight: '-0.2rem', marginTop: '0.1rem', fontSize: '1.1rem' }} />
      </IconButton>
    )
  }


  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1); // Reset page to 1 on limit change
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (uploadedData) {
      setShowLoader(true);
      try {
        const res = await createManyService(activeBranchId, { bulkData: uploadedData });
        setShowMessage({
          message: res?.statusCode === 200 ? 'Service was successfully uploaded' : 'Service failed to upload',
          messageType: res?.statusCode === 200 ? 'success' : 'error',
        });
        getData();
      } catch (error) {
        setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
      } finally {
        setShowLoader(false);
        closeModal();
      }
    }
  };

  const downloadExcelTemplate = () => {
    const templateFileName = Book1service;
    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = templateFileName;
    a.download = 'service_template.xlsx';
    // Trigger the click event to download the file
    document.body.appendChild(a);
    a.click();
    // Remove the anchor element
    document.body.removeChild(a);
  };

  const handleBulkDelete = async () => {
    setOpenDialogue(true);
  };

  // const submitBulkDelete = async () => {
  //   try {
  //     setDeleteLoading(true);
  //     const res = await deleteManyService({ ids: checkedServices }, `?branchId=${activeBranchId}`);
  //     setShowMessage({
  //       message: res?.statusCode === 200 ? 'Successfully deleted service' : 'Error occurred deleting service',
  //       messageType: res?.statusCode === 200 ? 'success' : 'error',
  //     });
  //     setCheckedServices([]);
  //     getData();
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
  //   } finally {
  //     setDeleteLoading(false);
  //     setOpenDialogue(false);
  //   }
  // };
  const submitBulkDelete = async () => {
    try {
      setDeleteLoading(true);
      const serviceIds = checkedServices.map(service => service?._id);
      const payload = {
        ids: serviceIds,
      };

      const response = await deleteManyService(payload, activeBranchId);

      if (response.statusCode === 200) {
        setShowMessage({ message: 'Successfully deleted Services', messageType: 'success' });
        setCheckedServices([]);
        getData();
      } else {
        setShowMessage({ message: response.message || 'Error occurred while deleting Services', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setDeleteLoading(false);
      setOpenDialogue(false);
    }
  };

  return (
    <PageContainer title="Manage Services" description="this contains all products">
      <DashboardCard2 title="Manage Services">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.7rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{ borderRadius: '7px', border: '0.5px solid #6174DD', padding: '4px 12px', background: '#FFF', height: '40px' }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginRight: '-a1rem' }}>
            <div style={{ marginRight: '1rem' }}>
              <InputLabel style={{ marginRight: '0.5rem' }}>Gender</InputLabel>
              <Select
                style={{ borderRadius: '7px', border: '0.5px solid #6174DD', padding: '4px 12px', background: '#FFF', height: '40px', width: '140px' }}
                value={serviceFor}
                onChange={(e) => setServiceFor(e.target.value)}
              >
                {filterServiceFor.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div style={{ marginRight: '0rem' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SearchImg} alt="" style={{ marginRight: '0.5rem' }} />
                <input
                  id="search-input"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ borderRadius: '7px' }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      setPage(1); // Reset to first page when clearing search
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{ color: '#000', marginRight: '-0.2rem', marginTop: '0.1rem', fontSize: '1.1rem' }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <Grid container justifyContent="flex-end">
          <Grid item justifyContent="flex-end">
            {checkedServices.length ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ background: '#ff4d4d', '&:hover': { background: '#ff6666' } }}
                onClick={handleBulkDelete}
              >
                Delete
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ background: '#6174DD' }}
                onClick={openModal}
              >
                Services Upload
              </Button>
            )}
          </Grid>
        </Grid>

        {/* Modal Dialog */}
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Create Many Services</DialogTitle>
          <form onSubmit={handleFormSubmit}>
            <Box p={2}>
              <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            </Box>
            {showLoader && <ShowLoader />}
            <Box p={2}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={downloadExcelTemplate}>
                <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
                Sample Download
              </Button>
            </Box>
          </form>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog open={openDialogue} onClose={() => setOpenDialogue(false)}>
          <DialogTitle>Delete service</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this Service?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogue(false)}>Cancel</Button>
            <Button onClick={submitBulkDelete} autoFocus>Delete</Button>
          </DialogActions>
        </Dialog>

        {showLoader ? (
          <ShowLoader />
        ) : filteredData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldData
                  data={filteredData}
                  setData={setData}
                  getServices={getData}
                  setCheckedServices={setCheckedServices}
                  checkedServices={checkedServices}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )}

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(data.filter(item => {
              const matchesServiceFor = serviceFor ? item?.serviceFor === serviceFor : true;
              const matchesSearch = search ? item?.serviceName?.toLowerCase().includes(search.toLowerCase()) : true;
              return matchesServiceFor && matchesSearch;
            }).length / limit) || 1}
            page={page}
            color="primary"
            onChange={(e, value) => setPage(value)}
            siblingCount={1}
            boundaryCount={1}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllServices;