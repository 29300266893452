import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';
import LineGraph from './LineGraph';

const filterOptions = [
  { label: 'Current Year', value: 'Current Year' },
  { label: 'Current Month', value: 'Current Month' },
  { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
  { label: 'Today', value: 'Today' },
  { label: 'Custom Date', value: 'customDate' },
];

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const ClientAnalytics = () => {
  const { activeBranchId, fetchVisitedClinetAnalytics, branchData } = useMain();

  const [graphData, setGraphData] = useState({ labels: [], data: [] });
  const [filterBy, setFilterBy] = useState('Today');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleApply = () => {
    getData();
  };

  const getData = async () => {
    const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''
      }`;
    setLoading(true);
    try {
      const res = await fetchVisitedClinetAnalytics(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res?.data?.clientVisitingData?.clientDetails || []);
        setGraphData((pre) => ({
          ...pre,
          labels: res.data.clientVisitingData.labels,
          data: res.data.clientVisitingData.data,
        }));
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }
    return () => {
      setFilterBy('Today');
      setFrom('');
      setTo('');
      setLoading(true);
      setData([]);
    };
  }, [activeBranchId]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <PageContainer tittle="Client Analytics" description="This is the client analytics component">
      <DashboardCard2 title="Client Analytics">
        {loading ? (
          <ShowLoader />
        ) : (
          <>
            <div className="buttons-container">
              <div className="row">
                <div className="col-2">
                  <FormControl fullWidth>
                    <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter By</div>
                    <Select
                      name="Filter Options"
                      onChange={(e) => setFilterBy(e.target.value)}
                      value={filterBy}
                    >
                      {filterOptions?.map((sourceOption) => (
                        <MenuItem key={sourceOption.value} value={sourceOption.value}>
                          {sourceOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {filterBy === 'customDate' && (
                  <>
                    <div className="col-2">
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div>
                      <input
                        type="date"
                        className="form-control"
                        id="exampleInputEmail1"
                        style={{ padding: '16px' }}
                        aria-describedby="emailHelp"
                        name="from"
                        onChange={(e) => setFrom(e.target.value)}
                        value={from}
                      />
                    </div>
                    <div className="col-2">
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div>
                      <input
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        className="form-control"
                        id="exampleInputEmail1"
                        style={{ padding: '16px' }}
                        aria-describedby="emailHelp"
                        name="to"
                        onChange={(e) => setTo(e.target.value)}
                        value={to}
                      />
                    </div>
                  </>
                )}
                <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button
                    disabled={!!(filterBy === 'customDate' && (from === '' || to === ''))}
                    variant="contained"
                    // fullWidth
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                      padding: '14px 30px',
                    }}
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            {data.length !== 0 && (
              <Box sx={{
                overflow: 'auto',
                boxsizing: 'border-box',
                display: 'block',
                height: '500px',
                width: '1000px',
                marginLeft: '4rem' }}>
            <LineGraph graphData={graphData} />
          </Box>
            )}
        {data.length === 0 ? (
          <p>No Data Found</p>
        ) : (
          <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <table className="table-container mt-3">
              <thead className="table-thead">
                <tr>
                  {['Name', 'Contact Number', 'Gender', 'Date Of Visiting'].map((h, index) => {
                    return (
                      <th key={index} style={{ width: '100%' }}>
                        {h}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="table-tbody">
                {data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td style={{ width: '100%' }}>{item?.clientName}</td>
                      <td style={{ width: '100%' }}>
                        {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                          ? 'loading...'
                          : maskData(item?.clientNumber, branchData?.isMasked)}
                      </td>
                      <td style={{ width: '100%' }}>{item?.gender}</td>
                      <td style={{ width: '100%' }}>{formatDate(item?.dateOfVisiting)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </>
        )}
    </DashboardCard2>
    </PageContainer >
  );
};

export default ClientAnalytics;
