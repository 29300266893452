// //loader added to gstbill button
// import React, { useEffect, useRef, useState } from 'react';
// import './GstBillsData.css';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { useMain } from 'src/views/hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import CloseIcon from '@mui/icons-material/Close';
// import { Grid } from 'rsuite';
// import { Button, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import { Box } from '@mui/system';
// import SearchImg from '../../../assets/search.svg';

// const GstBillsPage = () => {
//   const [tableData, setTableData] = useState([]);
//   const [filterData, setFilterData] = useState([]);
//   const [isTableVisible, setIsTableVisible] = useState(false);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const { activeBranchId, getGstData } = useMain();
//   const pdfRef = useRef();

//   // Fetch GST Bill Details
//   const fetchGstBillDetails = async () => {
//     setLoading(true);
//     try {
//       const res = await getGstData(activeBranchId);
//       if (res && res.data && res.data.bills) {
//         setTableData(res.data.bills);
//         setFilterData(res.data.bills); // Set initial filterData
//         setIsTableVisible(true);
//       } else {
//         console.error('Invalid response structure:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching API:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Apply filters whenever relevant states change
//   useEffect(() => {
//     applyFilters();
//   }, [tableData, limit, genderBy, page, search, startDate, endDate]);

//   const applyFilters = () => {
//     let filteredData = tableData.filter((item) => {
//       let isGenderMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (genderBy) {
//         isGenderMatch = item.gender === genderBy;
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch =
//           clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isNameMatch && isDateMatch;
//     });

//     // Apply pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setGenderBy('');
//     setEndDate('');
//     setStartDate('');
//     setClear(false);
//     fetchGstBillDetails(); // Re-fetch all data when cleared
//   };

//   const downloadPdf = () => {
//     const input = pdfRef.current;
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 190;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
//       pdf.save('GST_Bill_Details.pdf');
//     });
//   };

//   // const closeTable = () => {
//   //   setIsTableVisible(false);
//   // };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   // Only fetch data on component mount
//   useEffect(() => {
//     fetchGstBillDetails();
//   }, [activeBranchId]);

//   return (
//     <>
//       <div>
//         {loading ? (
//           <ShowLoader />
//         ) : (
//           <>
//             {isTableVisible && (
//               <>
//                 <div className="dateInput-container">
//                   <Grid item xs={2}>
//                     <InputLabel>Show per page</InputLabel>
//                     <Select
//                       style={{
//                         borderRadius: '7px',
//                         border: '0.5px solid #6174DD',
//                         padding: '4px 12px',
//                         background: '#FFF',
//                         height: '40px',
//                       }}
//                       value={limit}
//                       onChange={handleLimitChange}
//                     >
//                       {[10, 20, 50].map((value) => (
//                         <MenuItem key={value} value={value}>
//                           {value}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </Grid>

//                   <Grid
//                     item
//                     xs={2}
//                     display={'flex'}
//                     flexDirection={'column'}
//                     justifyContent={'flex-end'}
//                   >
//                     <Typography variant="subtitle2" fontWeight={500} component="label">
//                       {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//                     </Typography>
//                     <Button
//                       variant="outlined"
//                       target="_blank"
//                       sx={{ color: 'black', height: '40px', width: '100%' }}
//                       onClick={() => setIsModalOpen(true)}
//                       style={{ width: '15rem', marginTop: '23px' }}
//                     >
//                       Choose Date
//                     </Button>
//                   </Grid>

//                   <Grid item xs={2} style={{ width: '15rem' }}>
//                     <div>
//                       <InputLabel>Search</InputLabel>
//                       <div className="search-input">
//                         <img src={SearchImg} alt="" />
//                         <input
//                           type="text"
//                           placeholder=""
//                           value={search}
//                           onChange={(e) => setSearch(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                   </Grid>

//                   <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         height: '40px',
//                         marginTop: '1.7rem',
//                         width: '100%',
//                         background: '#ff4d4d',
//                         '&:hover': { background: '#ff6666' },
//                       }}
//                       onClick={clearDataHandler}
//                     >
//                       Clear
//                     </Button>
//                   </Grid>
//                 </div>

//                 <DateRangeInputs
//                   isOpen={isModalOpen}
//                   onClose={() => setIsModalOpen(false)}
//                   onDateRangeSelected={handleDateRangeSelected}
//                   selectedEndDate={endDate}
//                   selectedStartDate={startDate}
//                 />

//                 <div className="gst-table-container" ref={pdfRef}>
//                   <table>
//                     <thead>
//                       <tr>
//                         {/* <th colSpan="7" style={{ textAlign: 'right' }}>
//                           <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
//                         </th> */}
//                       </tr>
//                       <tr>
//                         <th>Date</th>
//                         <th>Client Name</th>
//                         <th>Invoice No</th>
//                         <th>Amount</th>
//                         <th>Payable Amount</th>
//                         <th>Gst Fair Amount</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {filterData.map((item) => {
//                         const originalDate = item?.dateOfBilling;
//                         let formattedBillDate = '';
//                         if (originalDate) {
//                           const [year, month, day] = originalDate.split('-');
//                           formattedBillDate = `${day}-${month}-${year}`;
//                         }
//                         return (
//                           <tr key={item._id}>
//                             <td>{formattedBillDate}</td>
//                             <td>{item.clientName}</td>
//                             <td>{item.invoice}</td>
//                             <td>{item.subTotal}</td>
//                             <td>{item.amountPayable}</td>
//                             <td>{item.amountPayable - item.subTotal}</td>
//                           </tr>
//                         );
//                       })}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="download-pdf">
//                   <button onClick={downloadPdf}>Download PDF</button>
//                 </div>

//                 <Box display={'flex'} justifyContent={'flex-end'}>
//                   <Pagination
//                     count={filterData && filterData?.length < limit ? page : page + 1}
//                     page={page}
//                     color="primary"
//                     onChange={(e, page) => setPage(page)}
//                   />
//                 </Box>
//               </>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default GstBillsPage;

//gst bill button loader added
import React, { useEffect, useRef, useState } from 'react';
import './GstBillsData.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from 'rsuite';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import { Box } from '@mui/system';
import { FaDownload } from "react-icons/fa";
import SearchImg from '../../../assets/search.svg';
import { ClearIcon } from '@mui/x-date-pickers-pro';
import * as XLSX from 'xlsx';


const GstBillsPage = () => {
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [securityCode, setSecurityCode] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const { activeBranchId, getGstData, verifyCode, sendCodeToEmail, ownerData, setShowMessage } = useMain();
  const pdfRef = useRef();

  // Fetch GST Bill Details
  const fetchGstBillDetails = async () => {
    setLoading(true);
    try {
      const res = await getGstData(activeBranchId);
      if (res && res.data && res.data.bills) {
        setTableData(res.data.bills);
        setFilterData(res.data.bills); // Set initial filterData
        setIsTableVisible(true);
      } else {
        console.error('Invalid response structure:', res);
      }
    } catch (error) {
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  // Apply filters whenever relevant states change
  useEffect(() => {
    applyFilters();
  }, [tableData, limit, genderBy, page, search, startDate, endDate]);

  const applyFilters = () => {
    let filteredData = tableData.filter((item) => {
      let isGenderMatch = true;
      let isNameMatch = true;
      let isDateMatch = true;

      if (genderBy) {
        isGenderMatch = item.gender === genderBy;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const billDate = new Date(item.dateOfBilling);
        isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
      }

      return isGenderMatch && isNameMatch && isDateMatch;
    });

    // Apply pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    setClear(false);
    fetchGstBillDetails(); // Re-fetch all data when cleared
  };

  // const downloadPdf = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 190;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  //     pdf.save('GST_Bill_Details.pdf');
  //   });
  // };

  const handleDownloadRequest = async () => {
    try {
      setOtpLoading(true);
      const response = await sendCodeToEmail(ownerData?._id);
      if (response?.statusCode === 200) {
        setOpenDialog(true);
        setShowMessage({
          message: response.message || 'The Otp has been Successfully sent to the owner email',
          messageType: 'success',
        });
      } else {
        alert('Failed to send Security Code. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    } finally {
      setOtpLoading(false);
    }
  };

  const handleSubmitOtp = async () => {
    // Check if the security code is empty before proceeding
    if (!securityCode) {
      alert('Please enter the security code.'); // Inform the user that the security code is required
      return; // Exit the function early without submitting
    }

    try {
      setOtpLoading(true);
      // Call the function to verify the OTP or security code
      const response = await verifyCode(ownerData?._id, { securityCode });

      // Check if the security code is valid based on response status code
      if (response?.statusCode === 200) { // Assuming 200 means the security code is valid
        setShowMessage({
          message: 'The security code has been successfully verified.',
          messageType: 'success',
        });
        downloadExcel(); // Only download if verification is successful
        setOpenDialog(false); // Close the OTP dialog after successful OTP validation
      } else if (response?.statusCode === 400) {
        // Display an error message if the security code does not match
        setShowMessage({
          message: response.message || 'The security code does not match. Please try again.',
          messageType: 'error',
        });
      } else {
        // Handle other unexpected status codes
        setShowMessage({
          message: 'An unexpected error occurred. Please try again.',
          messageType: 'error',
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('An error occurred while verifying the security code. Please try again.');
    } finally {
      setOtpLoading(false);
    }
  };


  const downloadExcel = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(
      filterData.map(item => ({
        Date: item.dateOfBilling,
        "Client Name": item.clientName,
        "Invoice No": item.invoice,
        Amount: item.totalAmount,
        "Payable Amount": item.amountPayable,
        "GST Fair Amount": item.amountPayable - item.subTotal,
      }))
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Bills');
    XLSX.writeFile(wb, 'Bills.xlsx');
  };

  // const closeTable = () => {
  //   setIsTableVisible(false);
  // };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // Only fetch data on component mount
  useEffect(() => {
    fetchGstBillDetails();
  }, [activeBranchId]);

  return (
    <>
      <div>
        {loading ? (
          <ShowLoader />
        ) : (
          <>
            {isTableVisible && (
              <>
                <div className="dateInput-container">
                  <Grid item xs={2}>
                    <InputLabel>Show per page</InputLabel>
                    <Select
                      style={{
                        borderRadius: '7px',
                        border: '0.5px solid #6174DD',
                        padding: '4px 12px',
                        background: '#FFF',
                        height: '40px',
                      }}
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      {[10, 20, 50].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    {/* <Typography variant="subtitle2" fontWeight={500} component="label">
                      {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
                    </Typography> */}

                    <div style={{ flex: '1 1 150px' }}>
                      <Button
                        variant="outlined"
                        sx={{
                          color: 'black',
                          height: '40px',
                          // width: startDate && endDate ? '100%' : '60%', // Use full width when dates are selected
                          width: startDate && endDate ? '240px' : '150px', // Use full width when dates are selected
                          marginTop: '0.4rem',
                          // marginBottom: '0.3rem',
                          marginLeft: startDate && endDate ? '-16rem' : '-19rem', // Adjust margin based on date selection
                          display: 'flex',
                          justifyContent: startDate && endDate ? 'space-between' : 'center',
                          padding: '0 8px',
                          whiteSpace: 'nowrap', // Prevent text wrapping
                          overflow: 'hidden', // Hide overflow
                          textOverflow: 'ellipsis', // Add ellipsis for overflow text
                          marginRight: '18rem',
                          marginBottom: '-1.3rem',
                        }}
                        onClick={() => setIsModalOpen(true)}
                      >
                        {startDate && endDate ? (
                          <>
                            <span>{`${startDate} to ${endDate}`}</span>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartDate('');
                                setEndDate('');
                                setClear(false);
                                applyFilters();
                              }}
                              sx={{
                                padding: 0,
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              <ClearIcon style={{ color: '#000', height: '1rem' }} />
                            </IconButton>
                          </>
                        ) : (
                          'Choose Date'
                        )}
                      </Button>
                    </div>
                  </Grid>


                  <Grid item xs={2} style={{ width: '15rem', marginRight: '0.8rem' }}>
                    <div style={{ marginLeft: '6.3rem' }}>
                      <InputLabel>Search</InputLabel>
                      <div className="search-input">
                        <img src={SearchImg} alt="" />
                        <input
                          type="text"
                          placeholder=""
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        {search && (
                          <IconButton
                            onClick={() => {
                              setSearch('');
                              setClear(false);
                            }}
                            style={{ padding: 1 }}
                          >
                            <ClearIcon style={{
                              // color: '#ff4d4d',
                              color: '#000',
                              // color: '#A2A3AE',
                              marginRight: '-0.2rem', marginTop: '0.1rem',
                              fontSize: '1.1rem'
                            }} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </Grid>

                  {/* <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: '40px',
                        marginTop: '1.7rem',
                        width: '100%',
                        background: '#ff4d4d',
                        '&:hover': { background: '#ff6666' },
                      }}
                      onClick={clearDataHandler}
                    >
                      Clear
                    </Button>
                  </Grid> */}
                </div>

                <DateRangeInputs
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  onDateRangeSelected={handleDateRangeSelected}
                  selectedEndDate={endDate}
                  selectedStartDate={startDate}
                />

                <div className="gst-table-container" ref={pdfRef}>
                  <table>
                    <thead>
                      <tr>
                        {/* <th colSpan="7" style={{ textAlign: 'right' }}>
                          <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
                        </th> */}
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Client Name</th>
                        <th>Invoice No</th>
                        <th>Amount</th>
                        <th>Payable Amount</th>
                        <th>Gst Fair Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData.map((item) => {
                        const originalDate = item?.dateOfBilling;
                        let formattedBillDate = '';
                        if (originalDate) {
                          const [year, month, day] = originalDate.split('-');
                          formattedBillDate = `${day}-${month}-${year}`;
                        }
                        return (
                          <tr key={item._id}>
                            <td>{formattedBillDate}</td>
                            <td>{item.clientName}</td>
                            <td>{item.invoice}</td>
                            <td>{item.subTotal}</td>
                            <td>{item.amountPayable}</td>
                            <td>{item.amountPayable - item.subTotal}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="download-pdf">
                  <button
                    title="Download Bills"
                    onClick={handleDownloadRequest}
                    disabled={otpLoading} // Disable button when otpLoading is true
                    style={{
                      pointerEvents: otpLoading ? 'none' : 'auto', // Disable clicks when loading
                      opacity: otpLoading ? 0.7 : 1, // Change opacity when loading
                    }}
                  >
                    {/* Show CircularProgress when otpLoading is true */}
                    <FaDownload style={{ height: "1.6rem", fontSize: "1.2rem" }} />
                    {otpLoading && <CircularProgress size={24} style={{ marginLeft: '0.5rem' }} />}
                  </button>
                </div>

                {/* OTP Dialog */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                  {/* <DialogTitle>Enter OTP</DialogTitle> */}
                  <DialogTitle>Enter Security Code</DialogTitle>
                  <DialogContent>
                    <TextField
                      // label="OTP"
                      label="Security Code"
                      variant="outlined"
                      fullWidth
                      value={securityCode}
                      onChange={(e) => setSecurityCode(e.target.value)}
                      autoFocus
                      style={{ marginTop: '0.5rem', width: '15rem' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleSubmitOtp} color="primary" disabled={otpLoading}>
                      {otpLoading ? 'Verifying...' : 'Submit'}
                    </Button>
                  </DialogActions>
                </Dialog>


                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Pagination
                    count={filterData && filterData?.length < limit ? page : page + 1}
                    page={page}
                    color="primary"
                    onChange={(e, page) => setPage(page)}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GstBillsPage;