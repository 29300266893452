// import { baseUrl, headers } from '../common/data';
import { baseUrl, headers, jwtHeaders} from '../common/data';

export const IvrCall = async (data) => {
  const response = await fetch(`${baseUrl}/api/ivr/connecttocall`, {
    method: 'POST',
    body: JSON.stringify(data),
    // headers,
    headers:jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const fetchIvr = async () => {
  const response = await fetch(`${baseUrl}/api/ivr/getallivrlogs`, {
    // headers,
    headers:jwtHeaders(),
  });

  const resData = await response?.json();
  return resData;
};

export const updateIvrDetails = async (params, data) => {
  const response = await fetch(`${baseUrl}/api/ivr/updateleads/${params}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    // headers,
    headers:jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};
