import React from 'react';
import {
    Box
} from '@mui/material';
const StaffWorkTable = ({ data }) => {
    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <table className="table-container">
                <thead className="table-thead">
                    <tr>
                        {['Date', 'Client Name', 'Client Number', 'Services'].map((h, index) => {
                            return (
                                <th key={index} style={{ width: '100%' }}>
                                    {h}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="table-tbody">
                    {data.map((row) => (
                        <tr key={row._id}>
                            <td style={{width: '100%'}}>{row.dateOfBilling}</td>
                            <td style={{width: '100%'}}>{row.clientName}</td>
                            <td style={{width: '100%'}}>{row.clientNumber}</td>
                            <td style={{width: '100%'}}>
                                {row.services.map((service, index) => (
                                    <div key={index}>
                                        {service.serviceName}
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    );
};

export default StaffWorkTable;