// import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination } from "@mui/material";
// import { Box } from "@mui/system";
// import { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router";
// import { Button } from "reactstrap";
// import { Grid } from "rsuite";
// import ShowLoader from "src/components/CustomComponents/ShowLoader";
// import TableCells from "src/components/Tables/TableCells";
// import SelectInputs from "src/components/inputs/SelectInputs";
// import TextInputs from "src/components/inputs/TextInputs";
// import { useMain } from "src/views/hooks/useMain";

// const initialValue = {
//     packageName: '',
//     price: '',
//     validTill: '',
// };
// const AllPackages = ({ data, setPackageData, getPackageData }) => {

//     const [selectedItemId, setSelectedItemId] = useState(null);
//     const [updateFormData, setUpdateFormData] = useState(initialValue);
//     const [openDialogue, setOpenDialogue] = useState(false);
//     const [loading, setLoading] = useState(false);
   
   
    

//     const { activeBranchId, updatePackage, setShowMessage } =
//         useMain();


//     const navigate = useNavigate()
//     const { state } = useLocation()

     
   

//     const handleCloseDialogue = () => {
//         setOpenDialogue(false);
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setUpdateFormData((pre) => ({ ...pre, [name]: value }));
//     };

//     const handleUpdate = (item) => {
//         setSelectedItemId(item._id);
//         let { packageName, price, validTill } = item;

//         setUpdateFormData((pre) => ({
//             ...pre,
//             packageName,
//             price,
//             validTill,
//         }));
//         setOpenDialogue(true);
//     };

//     const hadleUpdatePackage = async () => {
//         try {
//             setLoading(true);
//             const res = await updatePackage(
//                 selectedItemId,
//                 updateFormData,
//                 `?branchId=${activeBranchId}`,
//             );
//             if (res.statusCode === 200) {
//                 setShowMessage({
//                     message: res.message || 'Update Package successfully',
//                     messageType: 'success',
//                 });
//                 setPackageData([]);
//                 getPackageData();
//                 setUpdateFormData((pre) => ({
//                     ...pre,
//                     ...initialValue,
//                 }));
//             } else {
//                 setShowMessage({
//                     message: res.message || 'Update Package failed',
//                     messageType: 'error',
//                 });
//             }
//         } catch (error) {
//             setShowMessage({ message: error.messag || 'Something went wrong', messageType: 'error' });
//         } finally {
//             setLoading(false);
//             setOpenDialogue(false);
//         }
//     };

//     const handleDetails = (id) => {
//         navigate(`/packagedetail/${id}`, { state });
//     };

    
//     return (
//         <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
//             <table className="table-container mt-3">
//                 <thead className="table-thead">
//                     <tr>
//                         {[
//                             'Package Name',
//                             'Package Price',
//                             'Package Validity',
//                             'Actions',
//                         ].map((h, index) => {
//                             return (
//                                 <th key={index} style={{ width: '100%' }}>
//                                     {h}
//                                 </th>
//                             );
//                         })}
//                     </tr>
//                 </thead>
//                 <tbody className="table-tbody">
//                     {data.map((item) => {
//                         return (
//                             <tr key={item._id}>
//                                 <td style={{ width: '100%' }}>{item.packageName}</td>
//                                 <td style={{ width: '100%' }}>{item.price}</td>
//                                 <td style={{ width: '100%' }}>{item.validTill}</td>

//                                 <td style={{ width: '100%' }}>
//                                     <TableCells
//                                         type="button"
//                                         handleDeleteField={""}
//                                         handleDetails={handleDetails}
//                                         handleUpdate={() => handleUpdate(item)}
//                                         field={item?._id}
//                                     />
//                                 </td>
//                             </tr>
//                         );
//                     })}
//                 </tbody>
//             </table>



//             <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
//                 <DialogTitle> Package Details</DialogTitle>
//                 <DialogContent>
//                     <DialogContentText>Here you can update Package details.</DialogContentText>
//                     <Box style={{ marginTop: '20px' }}>
//                         <Grid container spacing={4}>
//                             <Grid item xs={12} md={3}>
//                                 <TextInputs
//                                     title={'Package Name'}
//                                     name={'packageName'}
//                                     value={updateFormData?.packageName}
//                                     handleChange={handleInputChange}
//                                     type={'text'}
//                                     required={false}
//                                 />
//                             </Grid>

//                             <Grid item xs={12} md={3}>
//                                 <TextInputs
//                                     title={'Package Price'}
//                                     name={'price'}
//                                     value={updateFormData?.price}
//                                     handleChange={handleInputChange}
//                                     type={'number'}
//                                     required={false}
//                                 />
//                             </Grid>

//                             <Grid item xs={12} md={3}>
//                                 <TextInputs
//                                     title={'Valid Till'}
//                                     name={'validTill'}
//                                     value={updateFormData?.validTill}
//                                     handleChange={handleInputChange}
//                                     type={'date'}
//                                     min={new Date().toISOString().split('T')[0]}
//                                 // fieldValidation={validTillValidation && formValidation.validTillValidation}
//                                 />
//                             </Grid>
                          
//                         </Grid>
//                     </Box>
//                 </DialogContent>
//                 <DialogActions>
//                     {loading ? (
//                         <ShowLoader />
//                     ) : (
//                         <>
//                             <div style={{ margin: '3rem auto' }}>
//                                 <Button
//                                     variant="contained"
//                                     fullWidth
//                                     color="primary"
//                                     target="_blank"
//                                     sx={{
//                                         background: '#6174DD',
//                                     }}
//                                     onClick={handleCloseDialogue}
//                                 >
//                                     Cancel
//                                 </Button>
//                             </div>
//                             <div style={{ margin: '3rem auto' }}>
//                                 <Button
//                                     variant="contained"
//                                     fullWidth
//                                     color="primary"
//                                     target="_blank"
//                                     sx={{
//                                         background: '#6174DD',
//                                     }}
//                                     onClick={hadleUpdatePackage}
//                                 >
//                                     update
//                                 </Button>
//                             </div>
//                         </>
//                     )}
//                 </DialogActions>
//             </Dialog>
//         </Box>
//     )
// }

// export default AllPackages

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button } from "reactstrap";
import { Grid } from "rsuite";
import ShowLoader from "src/components/CustomComponents/ShowLoader";
import TableCells from "src/components/Tables/TableCells";
import SelectInputs from "src/components/inputs/SelectInputs";
import TextInputs from "src/components/inputs/TextInputs";
import { useMain } from "src/views/hooks/useMain";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const initialValue = {
    packageName: '',
    price: '',
    validTill: '',
};
const AllPackages = ({ data, setPackageData, getPackageData }) => {

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [updateFormData, setUpdateFormData] = useState(initialValue);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [loading, setLoading] = useState(false);


    const { activeBranchId, updatePackage, setShowMessage } =
        useMain();


    const navigate = useNavigate()
    const { state } = useLocation()




    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateFormData((pre) => ({ ...pre, [name]: value }));
    };

    const handleUpdate = (item) => {
        setSelectedItemId(item._id);
        let { packageName, price, validTill } = item;

        setUpdateFormData((pre) => ({
            ...pre,
            packageName,
            price,
            validTill,
        }));
        setOpenDialogue(true);
    };

    const hadleUpdatePackage = async () => {
        try {
            setLoading(true);
            const res = await updatePackage(
                selectedItemId,
                updateFormData,
                `?branchId=${activeBranchId}`,
            );
            if (res.statusCode === 200) {
                setShowMessage({
                    message: res.message || 'Update Package successfully',
                    messageType: 'success',
                });
                setPackageData([]);
                getPackageData();
                setUpdateFormData((pre) => ({
                    ...pre,
                    ...initialValue,
                }));
            } else {
                setShowMessage({
                    message: res.message || 'Update Package failed',
                    messageType: 'error',
                });
            }
            } catch (error) {
                setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
        } finally {
            setLoading(false);
            setOpenDialogue(false);
        }
    };

    const handleDetails = (id) => {
        navigate(`/packagedetail/${id}`, { state });
    };


    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <table className="table-container mt-3">
                <thead className="table-thead">
                    <tr>
                        {[
                            'Package Name',
                            'Package Price',
                            'Package Validity',
                            'Actions',
                        ].map((h, index) => {
                            return (
                                <th key={index} style={{ width: '100%' }}>
                                    {h}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="table-tbody">
                    {data.map((item) => {
                        const originalDate = item?.validTill;

                        // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
                        let formattedValidityDate = '';
                        if (originalDate) {
                            const [year, month, day] = originalDate.split('-');
                            formattedValidityDate = `${day}-${month}-${year}`;
                        }
                        return (
                            <tr key={item._id}>
                                <td style={{ width: '100%' }}>{item.packageName}</td>
                                <td style={{ width: '100%' }}>{item.price}</td>
                                <td style={{ width: '100%' }}>{formattedValidityDate}</td>

                                {/* <td style={{ width: '100%' }}>
                                    <TableCells
                                        type="button"
                                        handleDeleteField={""}
                                        handleDetails={handleDetails}
                                        handleUpdate={() => handleUpdate(item)}
                                        field={item?._id}
                                    />
                                </td> */}
                                <td style={{
                                    width: '116%'
                                }}>
                                    <IconButton onClick={() => handleDetails(item._id)} color="primary" title="View Details" style={{ marginRight: '-5px' }}>
                                        <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem', marginLeft: '1.5rem' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                                        <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                                    </IconButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>



            <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
                <DialogTitle> Package Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>Here you can update Package details.</DialogContentText>
                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Package Name'}
                                    name={'packageName'}
                                    value={updateFormData?.packageName}
                                    handleChange={handleInputChange}
                                    type={'text'}
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Package Price'}
                                    name={'price'}
                                    value={updateFormData?.price}
                                    handleChange={handleInputChange}
                                    type={'number'}
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Valid Till'}
                                    name={'validTill'}
                                    value={updateFormData?.validTill}
                                    handleChange={handleInputChange}
                                    type={'date'}
                                    min={new Date().toISOString().split('T')[0]}
                                // fieldValidation={validTillValidation && formValidation.validTillValidation}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <ShowLoader />
                    ) : (
                        <>
                            {/* <div style={{ width: '200px', margin: '3rem auto' }}> */}
                            <div style={{ margin: '3rem auto' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    target="_blank"
                                    sx={{
                                        background: '#6174DD',
                                    }}
                                    onClick={handleCloseDialogue}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div style={{ margin: '3rem auto' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    target="_blank"
                                    sx={{
                                        background: '#6174DD',
                                    }}
                                    onClick={hadleUpdatePackage}
                                >
                                    update
                                </Button>
                            </div>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default AllPackages

