import { Box, Button, FormControl, Grid, MenuItem, Select, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SelectInputs from 'src/components/inputs/SelectInputs';
// import NewSelectInput from '../Appointments/NewSelectInput';
import ServiceSuggestion from '../Billings/ServiceSuggestion';
import { IoStar } from 'react-icons/io5';

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  servicesOffered: [
    {
      service: '',
      serviceName: '',
      availableCount: '',
    },
  ],
};

const initialFormValidation = {
  membershipNameValidation: false,
  membershipPriceValidation: false,
  durationValidation: false,
  servicesOfferedValidation: [
    {
      serviceValidation: false,
      availableCountValidation: false,
    },
  ],
};

const ServiceMembership = ({ membershipType = 'serviceType' }) => {
  const { createMembership, activeBranchId, getBranch, setShowMessage } = useMain();

  const [formData, setFormData] = useState({ ...initialValue, membershipType });
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [servicesCat, setServicesCat] = useState([]);
  const [focusedServiceIndex, setFocusedServiceIndex] = useState(null);
  const [serviceSuggested, setServiceSuggested] = useState([]);

  const membershipNameValidation = !formData.membershipName.trim();
  const membershipPriceValidation =
    !formData.membershipPrice || !(Number(formData.membershipPrice) > 0);
  const durationValidation = !formData.duration || !(Number(formData.duration) > 0);
  const servicesOfferedValidation = formData.servicesOffered.map((item) => ({
    serviceValidation: !item.service.trim(),
    availableCountValidation: !item.availableCount || !(Number(item.availableCount) > 0),
  }));

  // const updateServiceSuggested = (inputValue) => {
  //   if (inputValue === '') {
  //     setServiceSuggested(services);
  //   } else {
  //     const serviceSuggestions = services?.filter((service) =>
  //       service.serviceName.toLowerCase().includes(inputValue.toLowerCase()),
  //     );
  //     setServiceSuggested(serviceSuggestions);
  //   }
  // };

  // not use one service again
  const updateServiceSuggested = (inputValue) => {
    // Collect all selected service IDs
    const selectedServiceIds = formData.servicesOffered
      .map((service) => service.service)
      .filter((serviceId) => serviceId !== ''); // Exclude empty selections

    if (inputValue === '') {
      // Exclude already selected services from the suggestions
      setServiceSuggested(services.filter((service) => !selectedServiceIds.includes(service._id)));
    } else {
      const serviceSuggestions = services?.filter(
        (service) =>
          service.serviceName.toLowerCase().includes(inputValue.toLowerCase()) &&
          !selectedServiceIds.includes(service._id), // Exclude already selected services
      );
      setServiceSuggested(serviceSuggestions);
    }
  };

  const handleServiceInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'service') {
      const updatedservicesOffered = [...formData.servicesOffered];
      updatedservicesOffered[index]['service'] = '';
      updatedservicesOffered[index]['serviceName'] = value;
      setFormData((prev) => ({ ...prev, servicesOffered: updatedservicesOffered }));
      updateServiceSuggested(value);
      setFocusedServiceIndex(index);
    }
  };

  const handleServiceSuggestedClick = (service) => {
    const index = focusedServiceIndex;
    const updatedservicesOffered = [...formData.servicesOffered];
    updatedservicesOffered[index]['service'] = service?._id;
    updatedservicesOffered[index]['serviceName'] = service?.serviceName;
    setFormData((prev) => ({ ...prev, servicesOffered: updatedservicesOffered }));
    setServiceSuggested([]);
  };

  console.log(formData);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Add a new row for service
  const addServiceRow = () => {
    const hasUnselectedService = formData.servicesOffered.some(
      (item) => item.service === '' || item.availableCount == 0,
    );

    if (!hasUnselectedService) {
      setFormData((prevData) => ({
        ...prevData,
        servicesOffered: [
          ...prevData.servicesOffered,
          {
            service: '',
            availableCount: '',
          },
        ],
      }));
    } else {
      setShowMessage({
        message: 'Select a service and count for the existing row(s) before adding a new row.',
        messageType: 'error',
      });
    }
  };

  // Remove a service row
  const removeServiceRow = (index) => {
    setFormData((prevData) => {
      const updatedServicesOffered = [...prevData.servicesOffered];
      updatedServicesOffered.splice(index, 1);
      return {
        ...prevData,
        servicesOffered: updatedServicesOffered,
      };
    });
  };

  // Handle change in service select and count
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServicesOffered = [...formData.servicesOffered];
    updatedServicesOffered[index] = {
      ...updatedServicesOffered[index],
      [name]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      servicesOffered: updatedServicesOffered,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const { membershipName, membershipPrice, duration, servicesOffered } = formData;

    const validation = {
      membershipNameValidation: !membershipName.trim(),
      membershipPriceValidation: !membershipPrice || !(Number(membershipPrice) > 0),
      durationValidation: !duration || !(Number(duration) > 0),
      servicesOfferedValidation: servicesOffered.map((item) => ({
        serviceValidation: !item.service.trim(),
        availableCountValidation: !item.availableCount || !(Number(item.availableCount) > 0),
      })),
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (
      Object.values(validation).some((item) =>
        Array.isArray(item)
          ? item.some(
              (nestedItem) => nestedItem.serviceValidation || nestedItem.availableCountValidation,
            )
          : item,
      )
    )
      return;

    try {
      setLoading(true);
      const res = await createMembership(
        JSON.stringify({
          ...formData,
          branchDetails: activeBranchId,
        }),
      );

      if (res?.statusCode === 200) {
        setFormData({ ...initialValue, membershipType });
        setFormValidation((pre) => ({ ...initialFormValidation }));
        setShowMessage({
          message: res.message || 'Service Type Membership created successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Membership not created', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getBranch(activeBranchId);
        const services = res?.data.services || [];
        setServices(services);

        const servicesByCategory = services.reduce((acc, service) => {
          const { category = 'Others', ...rest } = service;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(rest);
          return acc;
        }, {});

        setServicesCat(servicesByCategory);
      } catch (error) {
        console.error(error);
      }
    };

    if (activeBranchId) {
      getData();
    }

    return () => {
      setServices([]);
    };
  }, [activeBranchId]);
  //console.log(servicesCat,"servicecattttttttttttt")

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Membership Name'}
              name={'membershipName'}
              value={formData?.membershipName}
              handleChange={handleInputChange}
              type={'text'}
              placeholder={'Membership Name'}
              fieldValidation={membershipNameValidation && formValidation.membershipNameValidation}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInputs
              title={'Duration (in Months)'}
              name={'duration'}
              placeholder={'0'}
              value={formData?.duration}
              handleChange={handleInputChange}
              type={'number'}
              fieldValidation={durationValidation && formValidation.durationValidation}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInputs
              title={'Membership Price'}
              name={'membershipPrice'}
              placeholder={'0'}
              value={formData?.membershipPrice}
              handleChange={handleInputChange}
              type={'number'}
              fieldValidation={
                membershipPriceValidation && formValidation.membershipPriceValidation
              }
            />
          </Grid>
        </Grid>
      </Box>
      {formData.servicesOffered.map((item, index) => (
        <Box style={{ marginTop: '20px' }} key={index}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <label>
                Services
                <IoStar
                  color="red"
                  style={{ height: '6px', fontWeight: 'bold', marginBottom: '10px' }}
                />
              </label>
              <input
                type="text"
                className={`form-control ${item?.serviceName === '' ? 'border border-2' : ''}`}
                placeholder="Service"
                name="service"
                value={item?.serviceName}
                onChange={(e) => handleServiceInputChange(e, index)}
                autoComplete="off"
                style={{
                  padding: '14px',
                  border: '1px solid #dee2e6',
                  outline: isFocused ? '2px solid #6174DD' : 'none', // Focus style
                  // outline: isHovered ? '1px solid #000' : 'none', // Focus style
                  // outline: isFocused ? '2px solid #6174DD' : 'none', // Focus style
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
              {item?.service === '' && (
                // <div className="text-danger">Please select service</div>
                <div className="text-danger"></div>
              )}
              {serviceSuggested.length > 0 && focusedServiceIndex === index && (
                <ServiceSuggestion
                  data={serviceSuggested}
                  handleServiceSuggestedClick={handleServiceSuggestedClick}
                />
              )}
            </Grid>
            <Grid item xs={12} md={2}>
              <TextInputs
                title={'Count'}
                name={'availableCount'} // Use a unique name for each input
                placeholder={'0'}
                value={item.availableCount}
                handleChange={(e) => handleChange(e, index)}
                type={'number'}
                fieldValidation={
                  servicesOfferedValidation[index]?.availableCountValidation &&
                  formValidation.servicesOfferedValidation[index]?.availableCountValidation
                }
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} md={4} mt={'22px'}>
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                    width: '100px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '0.35rem',
                  }}
                  onClick={addServiceRow}
                >
                  Add
                </Button>
              </Grid>
              {index !== 0 ? (
                <Grid item xs={12} md={4} mt={'22px'}>
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#ff0000',
                      width: '100px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginTop: '0.35rem',
                      '&:hover': {
                        background: '#ff6666', // Change the background color on hover
                      },
                    }}
                    onClick={() => removeServiceRow(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Box>
      ))}

      {loading ? (
        <ShowLoader />
      ) : (
        <div style={{ width: '200px', margin: '3rem auto' }}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            target="_blank"
            sx={{
              background: '#6174DD',
            }}
            onClick={handleSubmit}
          >
            Create Membership
          </Button>
        </div>
      )}
    </div>
  );
};

export default ServiceMembership;
