import React, { useEffect, useReducer, useState } from 'react';
import './AddBill.css';
import { useMain } from '../hooks/useMain';
import ClinetSuggestion from './ClinetSuggestion';
import ProductSuggestion from './ProductSuggestion';
import ServiceSuggestion from './ServiceSuggestion';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { INITIAL_STATE, billReducer } from 'src/reducers/billReducer/billReducer';
import {
  ADD_PRODUCT_ROW,
  DELETE_PRODUCT_ROW,
  HANDLE_CLIENT_SUGGESTED_CLICK,
  HANDLE_CLIENT_INPUT_CHANGE,
  HANDLE_PRODUCT_INPUT_CHANGE,
  HANDLE_PRODUCT_SUGGESTED_CLICK,
  HANDLE_SERVICE_INPUT_CHANGE,
  HANDLE_SERVICE_SUGGESTED_CLICK,
  ADD_SERVICE_ROW,
  DELETE_SERVICE_ROW,
  HANDLE_DISCOUNT_INPUT_CHANGE,
  HANDLE_TAXE_INPUT_CHANGE,
  HANDLE_AMOUNTPAID_INPUT_CHANGE,
  ADD_AMOUNTPAID_ROW,
  DELETE_AMOUNTPAID_ROW,
  REST_BILL_STATE,
  HANDLE_COUPON_INPUT_CHANGE,
  HANDLE_CLIENT_NUMBER_INPUT_CHANGE,
  HANDLE_MEMBERSHIP_INPUT_CHANGE,
  HANDLE_PACKAGE_INPUT_CHANGE,
  HANDLE_ADD_BILL_BY_APPOINTMENTID,
  HANDLE_REWARD_POINT_CHANGE,
  SET_DRAFT_BILL,
  UPDATE_AMOUNT_PAID,
} from 'src/reducers/billReducer/billConstent';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Switch,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import CouponCard from './CouponCard';
import MembershipCard from './MembershipCard';
import { useLocation, useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Box } from '@mui/system';
import RemainingAmountCard from './pdf/RemainingAmountCard';
import PackageCard from './PackageCard';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const NewBilling = () => {
  const location = useLocation();

  const [state, dispatch] = useReducer(billReducer, INITIAL_STATE);
  const [selectedClient, setSelectedClient] = useState({});
  const [createdBill, setCreatedBill] = useState({});
  const [activeField, setActiveField] = useState('');
  const [clientMembership, setClientMembership] = useState({});
  const [clientPackage, setClientPackage] = useState([]);
  const [openPackageDialogue, setOpenPackageDialogue] = useState(false);
  const [clientCoupons, setClientCoupons] = useState([]);
  const [genderWiseService, setGenderWiseService] = useState([]);
  const [allClinetsData, setAllClinetsData] = useState([]);
  const [allProductsData, setAllProductsData] = useState([]);
  const [allServicesData, setAllServicesData] = useState([]);
  const [allCouponsData, setAllCouponsData] = useState([]);
  const [allStaffData, setAllStaffData] = useState([]);
  const [clientSuggested, setClientSuggested] = useState([]);
  const [productSuggested, setProductSuggested] = useState([]);
  const [focusedProductIndex, setFocusedProductIndex] = useState(null);
  const [serviceSuggested, setServiceSuggested] = useState([]);
  const [focusedServiceIndex, setFocusedServiceIndex] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openMembershipDialogue, setOpenMembershipDialogue] = useState(false);
  const [openRemainingAmountDialogue, setOpenRemainingAmountDialogue] = useState(false);
  const [openBillDialogue, setOpenBillDialogue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [stateValidation, setStateValidation] = useState(false);
  const [isBillHistoryDrawerOpen, setIsBillHistoryDrawerOpen] = useState(false);
  const [showMembershipDetails, setShowMembershipDetails] = useState(false);
  const [showPackageDetails, setShowPackageDetails] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [updatedServices, setUpdatedServices] = useState([]);
  const [updatedPackageServices, setUpdatedPackageServices] = useState([]);
  const [draftBillData, setDraftBillData] = useState({});

  const buttonStyle = {
    borderRadius: '5px',
    backgroundColor: '#6174DD',
    color: 'white',
    padding: '3px 7px',
    border: 'none',
    fontSize: '10px',
    marginLeft: '5px',
  };

  const navigate = useNavigate();
  const {
    activeBranchId,
    getBranch,
    fetchClientByNumber,
    createBilling,
    setShowMessage,
    fetchAppointmentById,
    branchData,
    createDraftBilling,
  } = useMain();

  const discount_type = ['%', 'INR'];

  const searchParams = new URLSearchParams(location.search);
  const appointmentId = searchParams.get('appointmentId');

  useEffect(() => {
    const draftBill = location.state?.draftBill;

    if (draftBill) {
      console.log(draftBill, 'draftBillADDBILL');
      dispatch({ type: SET_DRAFT_BILL, payload: { draftBill } });
      setDraftBillData(draftBill);
    }
  }, [location.state?.draftBill, draftBillData]);

  const handleUpdateServices = (services) => {
    setUpdatedServices(services); // Update the state with the received services
  };

  const handlePackageService = (services) => {
    setUpdatedPackageServices(services); // Update the state with the received services
  };

  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleOpenMembershipDialogue = () => {
    setOpenMembershipDialogue(true);
  };

  const handleOpenPackageDialogue = () => {
    setOpenPackageDialogue(true);
  };

  const handleClosePackageDialogue = () => {
    setOpenPackageDialogue(false);
  };

  const handleCloseMembershipDialogue = () => {
    setOpenMembershipDialogue(false);
  };

  const handleBillCloseDialogue = () => {
    setCreatedBill({});
    setOpenBillDialogue(false);
  };

  const handleOpenRemainingAmountDialogue = () => {
    setOpenRemainingAmountDialogue(true);
  };

  const handleCloseRemainingAmountDialogue = () => {
    setOpenRemainingAmountDialogue(false);
  };

  const closeBillHistoryDrawer = () => {
    setIsBillHistoryDrawerOpen(false);
  };

  const toggleMembershipDetails = () => {
    setShowMembershipDetails(!showMembershipDetails);
  };

  const togglePackageDetails = () => {
    setShowPackageDetails(!showPackageDetails);
  };

  const toggleDrawer = () => {
    setIsBillHistoryDrawerOpen(!isBillHistoryDrawerOpen);
  };

  const handleClientInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: HANDLE_CLIENT_INPUT_CHANGE, payload: { name, value } });
    if (name === 'clientNumber' || name === 'clientName') {
      if (name === 'clientNumber') setClientMembership({});
      updateSuggested(name, value);
    }
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (inputValue === '') {
      setClientSuggested([]);
    }
    const suggestions = allClinetsData.filter((client) =>
      client[fieldName === 'clientName' ? 'clientName' : 'clientNumber']
        ?.toLowerCase()
        .includes(inputValue.toLowerCase()),
    );
    setClientSuggested(suggestions);
  };

  const handleSuggestedClick = (client) => {
    dispatch({ type: HANDLE_CLIENT_SUGGESTED_CLICK, payload: client });
    setClientSuggested([]);
  };

  const handleProductInputChange = (e, index) => {
    const { name, value } = e.target;
    dispatch({ type: HANDLE_PRODUCT_INPUT_CHANGE, payload: { name, value, index } });
    if (name === 'productName') {
      updateProductSuggested(value);
      setFocusedProductIndex(index);
    }
  };

  // product using again and again
  // const updateProductSuggested = (inputValue) => {
  //   if (inputValue === '') {
  //     setProductSuggested([]);
  //   } else {
  //     const productSuggestions = allProductsData?.filter((product) =>
  //       product.productName.toLowerCase().includes(inputValue.toLowerCase()) &&
  //       product.productCount > 0
  //     );
  //     setProductSuggested(productSuggestions);
  //   }
  // };
  const updateProductSuggested = (inputValue) => {
    if (inputValue === '') {
      setProductSuggested([]);
    } else {
      const productSuggestions = allProductsData?.filter((product) =>
        product.productName.toLowerCase().includes(inputValue.toLowerCase()) &&
        product.productCount >= 0 // Ensure we are considering all products
      );
      setProductSuggested(productSuggestions);
    }
  };


  //  not use one product again
  // const updateProductSuggested = (inputValue) => {
  //   if (inputValue === '') {
  //     setProductSuggested([]);
  //   }
  //   else {
  //     // Get the names of already selected products
  //     const selectedProductNames = state.products.map((product) => product.productName.toLowerCase());

  //     const productSuggestions = allProductsData?.filter((product) => product?.productName?.toLowerCase().includes(inputValue.toLowerCase()) &&
  //       !selectedProductNames.includes(product?.productName?.toLowerCase()));
  //     setProductSuggested(productSuggestions);
  //   }
  // };

  const handleProductSuggestedClick = (product) => {
    const index = focusedProductIndex;
    dispatch({ type: HANDLE_PRODUCT_SUGGESTED_CLICK, payload: { product, index, item: clientMembership, itemPackage: clientPackage[0] } });
    setProductSuggested([]);
    setFocusedProductIndex(null);
  };

  const addProductRow = () => {
    if (state.products?.length) {
      const hasUnselectedProduct = state?.products?.some((item) => item.product === '');
      if (hasUnselectedProduct)
        return setShowMessage({ message: 'Please select a product', messageType: 'error' });
    }
    dispatch({ type: ADD_PRODUCT_ROW });
  };

  const deleteProductRow = (index) => {
    dispatch({ type: DELETE_PRODUCT_ROW, payload: { index } });
  };

  const handleServiceInputChange = (e, index) => {
    const { name, value } = e.target;
    // Convert tipAmount to a number if the field is tipAmount
    const processedValue = name === 'tipAmount' ? Number(value) : value;

    dispatch({
      type: HANDLE_SERVICE_INPUT_CHANGE,
      payload: {
        name,
        value: processedValue, // Use the processed value here
        index,
        item: clientMembership,
        itemPackage: clientPackage[0]
      }
    });

    // Update suggestions and focused index if serviceName changes
    if (name === 'serviceName') {
      updateServiceSuggested(value);
      setFocusedServiceIndex(index);
    }
  };

  // multiple service use again and again
  const updateServiceSuggested = (inputValue) => {
    if (inputValue === '') {
      setServiceSuggested([]);
    } else {
      const serviceSuggestions = genderWiseService?.filter((service) =>
        service.serviceName.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setServiceSuggested(serviceSuggestions);
    }
  };

  //  not use one service again
  // const updateServiceSuggested = (inputValue) => {
  //   if (inputValue === '') {
  //     setServiceSuggested([]);
  //   } else {
  //     const selectedServiceNames = state.services.map((service) => service.serviceName.toLowerCase());

  //     const serviceSuggestions = genderWiseService?.filter((service) => {
  //       return service.serviceName.toLowerCase().includes(inputValue.toLowerCase()) &&
  //         !selectedServiceNames.includes(service.serviceName.toLowerCase());
  //     });

  //     setServiceSuggested(serviceSuggestions);
  //   }
  // };

  const handleServiceSuggestedClick = (service) => {
    const index = focusedServiceIndex;
    if (index === null) return;

    dispatch({
      type: HANDLE_SERVICE_SUGGESTED_CLICK,
      payload: {
        service,
        index,
        item: clientMembership, // Pass the membership item (could include package)
        itemPackage: clientPackage[0]
      },
    });

    // Update the input field value directly
    const input = document.querySelector(`input[name="serviceName"][data-index="${index}"]`);
    if (input) {
      input.value = service.serviceName;
    }

    setServiceSuggested([]);
    setFocusedServiceIndex(null);
    setActiveField('');
  };

  const addServiceRow = () => {
    if (state.services?.length) {
      const hasUnselectedService = state?.services?.some(
        (item) => item.serviceProvider === '' || item.service === '',
      );
      if (hasUnselectedService)
        return setShowMessage({
          message: 'please select a service or add a service provider',
          messageType: 'error',
        });
    }
    dispatch({ type: ADD_SERVICE_ROW });
  };

  const deleteServiceRow = (index) => {
    dispatch({ type: DELETE_SERVICE_ROW, payload: { index } });
  };

  const handleDiscountInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: HANDLE_DISCOUNT_INPUT_CHANGE, payload: { name, value } });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTaxeInputChange = (e) => {
    const selectedValue = e.target.value;
    const [taxType, SGST, PGST] = selectedValue.split(',');
    dispatch({ type: HANDLE_TAXE_INPUT_CHANGE, payload: { taxType, SGST, PGST } });
  };

  useEffect(() => {
    if (state.amountPaid.length > 0) {
      dispatch({
        type: UPDATE_AMOUNT_PAID,
        payload: { index: 0, amount: state.amountPayable },
      });
    }
  }, [state.amountPayable]);

  const handleAmountPaidInputChange = (e, index) => {
    const { name, value } = e.target;
    dispatch({
      type: HANDLE_AMOUNTPAID_INPUT_CHANGE,
      payload: {
        name,
        value,
        index,
        advanceGiven: selectedClient.advancedGiven,
        setSnackbar: setSnackbar,
      },
    });
  };

  const handleToggleChange = (event) => {
    const selectedValue = event.target.checked;
    dispatch({
      type: HANDLE_REWARD_POINT_CHANGE,
      payload: {
        selectedValue,
        rewardPoint: selectedClient?.rewardPointsEarned,
        perRewardPointsPrice: branchData?.perRewardPointsPrice,
      },
    });
  };

  const addAmountPaidRow = () => {
    const isUnselectedamountPaid = state?.amountPaid?.some(
      (item) => item.amount === '' || Number(item.amount) === 0,
    );
    if (isUnselectedamountPaid)
      return setShowMessage({ message: 'please fill the amount field', messageType: 'error' });
    dispatch({ type: ADD_AMOUNTPAID_ROW });
  };

  const deleteAmountPaidRow = (index) => {
    dispatch({ type: DELETE_AMOUNTPAID_ROW, payload: { index } });
  };

  const submitHanlder = async () => {
    setStateValidation(true);

    const totalPaidAmount = state.amountPaid.reduce(
      (acc, item) => acc + parseFloat(item.amount || 0),
      0,
    );

    const exceedsPayable = totalPaidAmount > state.amountPayable;

    // Treat advanceGiven as wallet amount
    let walletAmount = Number(selectedClient.advancedGiven);

    const walletPayment = state.amountPaid.some((payment) => payment.paymentType === 'Wallet');

    const validate =
      !state.clientNumber ||
      state.clientNumber.length !== 10 ||
      !state.clientName ||
      (state?.services.length &&
        state?.services.some((item) => item.serviceProvider === '' || item.service === '')) ||
      (state?.products.length && state?.products.some((item) => item.product === '')) ||
      (state?.amountPaid.length && state?.amountPaid?.some((item) => item.amount === '')) ||
      exceedsPayable ||
      (walletPayment && walletAmount === 0);

    if (validate) {
      setStateValidation(true);

      if (walletPayment && walletAmount === 0) {
        setShowMessage({
          message:
            'Insufficient wallet balance. Please recharge your wallet or select another payment method.',
          messageType: 'error',
        });
        return; // Block the submission if walletAmount is 0 and wallet is selected.
      }

      if (exceedsPayable) {
        setShowMessage({
          message:
            'You cannot give advance here. If you want to give advance, please recharge your wallet.',
          messageType: 'error',
        });
        return; // Block the submission if validation fails.
      }
      return setShowMessage({ message: 'Please fill required field', messageType: 'error' });
    }
    setShowLoader(true);
    const servicesWithoutMembership = state.services.map(({ membershipApplied, ...rest }) => rest);
    console.log(servicesWithoutMembership);
    try {
      const { usePackage, ...rest } = state;
      const payload = {
        ...rest,
        branchDetails: activeBranchId,
      };

      // Conditionally add draftBillId if it exists
      if (draftBillData?.draftBillId) {
        payload.draftBillId = draftBillData.draftBillId;
      }

      const res = await createBilling(JSON.stringify(payload));
      if (res?.statusCode === 200) {
        setShowMessage({ message: res.message || 'Successfully created', messageType: 'success' });
        getData();
        dispatch({ type: REST_BILL_STATE });
        setFocusedServiceIndex(null);
        setServiceSuggested([]);
        setFocusedProductIndex(null);
        setProductSuggested([]);
        setActiveField('');
        setClientSuggested([]);
        setClientMembership({});
        setSelectedClient({});
        setStateValidation(false);
        setCreatedBill(res.data);
        setOpenBillDialogue(true);
      } else {
        setShowMessage({ message: res?.message || 'failed to create bill', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setShowLoader(false);
    }
  };

  const getData = async () => {
    try {
      const res = await getBranch(activeBranchId);
      const data = res?.data;
      if (data?.gstNumber)
        dispatch({
          type: HANDLE_TAXE_INPUT_CHANGE,
          payload: { taxType: 'Exclusive', SGST: 18, PGST: 18 },
        });
      setAllProductsData(data?.products || []);
      setAllServicesData(data?.services || []);
      setGenderWiseService(data?.services || []);
      setAllClinetsData(data?.client || []);
      const validCoupons = await filterValidCoupons(data?.coupons);
      setClientCoupons(validCoupons);
      setAllCouponsData(validCoupons);
      setAllStaffData(data?.staffs || []);
    } catch (error) { }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }

    return () => {
      setClientMembership({});
      setFocusedServiceIndex(null);
      setServiceSuggested([]);
      setFocusedProductIndex(null);
      setClientSuggested([]);
      setAllStaffData([]);
      setAllCouponsData([]);
      setAllServicesData([]);
      setGenderWiseService([]);
      setAllProductsData([]);
      setAllClinetsData([]);
      setClientCoupons([]);
      setActiveField('');
      setSelectedClient({});
      dispatch({ type: REST_BILL_STATE });
    };
  }, [activeBranchId]);

  const getClinet = async (clientNumber) => {
    try {
      const res = await fetchClientByNumber(clientNumber, activeBranchId);
      if (res.statusCode === 200) {
        const client = res?.data;
        dispatch({ type: HANDLE_CLIENT_NUMBER_INPUT_CHANGE, payload: client });
        setSelectedClient(client);
        setClientMembership(checkMembershipValidation(client.membershipDetails));
        setClientPackage(client?.packageDetails || checkPackageValidation(client?.packageDetails));
        setClientCoupons(filterClinetsCoupons(allCouponsData, res?.data?.coupons));
      } else {
      }
    } catch (error) { }
  };
  console.log(selectedClient, 'selectedClient');
  console.log(clientPackage, 'clientPackage');
  // console.log(clientMembership, "clientMembership")
  useEffect(() => {
    if (state.clientNumber?.length === 10) {
      getClinet(state.clientNumber);
    }
    return () => {
      setSelectedClient({});
      setClientMembership({});
      setClientPackage({});
      setClientCoupons(allCouponsData);
    };
  }, [state.clientNumber]);
  const handleInputFocus = () => {
    const activeElement = document.activeElement;
    const attributeName = activeElement.getAttribute('name');
    if (activeElement && attributeName) {
      setActiveField(attributeName);
    } else {
      setActiveField('');
    }
  };

  useEffect(() => {
    document.addEventListener('focus', handleInputFocus, true);
    return () => {
      document.removeEventListener('focus', handleInputFocus, true);
    };
  }, [activeField]);

  const getAppointmentData = async (id) => {
    try {
      setLoading(true);
      const res = await fetchAppointmentById(id, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        dispatch({ type: HANDLE_ADD_BILL_BY_APPOINTMENTID, payload: res.data });
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointmentId) getAppointmentData(appointmentId);
    else {
      setLoading(false);
    }
    return () => {
      dispatch({ type: REST_BILL_STATE });
    };
  }, [appointmentId]);

  useEffect(() => {
    dispatch({ type: REST_BILL_STATE });
  }, []);

  const handleRemainingTotalClick = () => {
    handleOpenRemainingAmountDialogue();
  };

  useEffect(() => {
    if (!!!selectedClient?.remainingTotal) handleCloseRemainingAmountDialogue();
  }, [selectedClient?.remainingTotal]);

  const submitDraftHandler = async () => {
    setStateValidation(true);

    const totalPaidAmount = state.amountPaid.reduce(
      (acc, item) => acc + parseFloat(item.amount || 0),
      0,
    );

    const exceedsPayable = totalPaidAmount > state.amountPayable;

    let walletAmount = Number(selectedClient.advancedGiven);

    const walletPayment = state.amountPaid.some((payment) => payment.paymentType === 'Wallet');

    const validate =
      !state.clientNumber ||
      state.clientNumber.length !== 10 ||
      !state.clientName ||
      // (state?.services.length &&
      //   state?.services.some((item) => item.serviceProvider === '' || item.service === '')) ||
      (state?.products.length && state?.products.some((item) => item.product === '')) ||
      // (state?.amountPaid.length && state?.amountPaid?.some((item) => item.amount === '')) ||
      exceedsPayable ||
      (walletPayment && walletAmount === 0);

    if (validate) {
      setStateValidation(true);

      if (walletPayment && walletAmount === 0) {
        setShowMessage({
          message:
            'Insufficient wallet balance. Please recharge your wallet or select another payment method.',
          messageType: 'error',
        });
        return;
      }

      if (exceedsPayable) {
        setShowMessage({
          message:
            'You cannot give advance here. If you want to give advance, please recharge your wallet.',
          messageType: 'error',
        });
        return;
      }

      return setShowMessage({ message: 'Please fill required field', messageType: 'error' });
    }

    setShowLoader(true);

    const servicesWithoutEmptyProvider = state.services.map(({ serviceProvider, ...rest }) => ({
      ...rest,
      ...(serviceProvider ? { serviceProvider } : {}),
    }));
    const amountPaid =
      state.amountPaid.length === 0 ? [{ paymentType: 'Cash', amount: '0' }] : state.amountPaid;
    const servicesWithoutMembership = state.services.map(({ membershipApplied, ...rest }) => rest);
    console.log(servicesWithoutMembership);

    try {
      const { usePackage, ...rest } = state;
      const res = await createDraftBilling(
        JSON.stringify({
          ...rest,
          amountPaid,
          services: servicesWithoutEmptyProvider,
          branchDetails: activeBranchId,
        }),
      );

      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Draft saved successfully',
          messageType: 'success',
        });
        getData();
        dispatch({ type: REST_BILL_STATE });
        setFocusedServiceIndex(null);
        setServiceSuggested([]);
        setFocusedProductIndex(null);
        setProductSuggested([]);
        setActiveField('');
        setClientSuggested([]);
        setClientMembership({});
        setSelectedClient({});
        setStateValidation(false);
      } else {
        setShowMessage({ message: res?.message || 'Failed to save draft', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <PageContainer title="Create Bill" description="this is add Bills page">
      <DashboardCard title="Create Bill">
        {loading ? (
          <ShowLoader />
        ) : (
          <div>
            <div className="mb-3">
              <form className="row">
                <div className="col-md-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Date of Billing <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={state?.dateOfBilling}
                    onChange={handleClientInputChange}
                    name="dateOfBilling"
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${(state?.clientNumber === '' || state?.clientNumber.length !== 10) &&
                      stateValidation
                      ? 'border border-danger'
                      : ''
                      }`}
                    id="exampleInputEmail1"
                    placeholder="87******22"
                    aria-describedby="emailHelp"
                    value={state?.clientNumber}
                    onChange={handleClientInputChange}
                    name="clientNumber"
                  />
                  {(state.clientNumber === '' || state?.clientNumber.length !== 10) &&
                    stateValidation && (
                      <div className="text-danger">Please enter client number</div>
                    )}
                  {clientSuggested.length > 0 && activeField === 'clientNumber' && (
                    <ClinetSuggestion
                      data={clientSuggested}
                      handleSuggestedClick={handleSuggestedClick}
                    />
                  )}
                </div>
                <div className="col-md-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Client Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${state?.clientName === '' && stateValidation ? 'border border-danger' : ''
                      }`}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Client Name"
                    value={state?.clientName}
                    onChange={handleClientInputChange}
                    name="clientName"
                  />
                  {state.clientName === '' && stateValidation && (
                    <div className="text-danger">Please enter client Name</div>
                  )}
                  {clientSuggested.length > 0 && activeField === 'clientName' && (
                    <ClinetSuggestion
                      data={clientSuggested}
                      handleSuggestedClick={handleSuggestedClick}
                    />
                  )}
                </div>
                <div className="col-md-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Time of Billing <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={state?.timeOfBilling}
                    onChange={handleClientInputChange}
                    name="timeOfBilling"
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Service For <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    className={`form-select ${state?.gender === '' && stateValidation ? 'border border-danger' : ''
                      }`}
                    aria-label="Default select example"
                    name="gender"
                    value={state?.gender}
                    onChange={handleClientInputChange}
                  >
                    <option value="" disabled>
                      --select gender--
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Both">Both</option>
                  </select>
                  {state.gender === '' && stateValidation && (
                    <div className="text-danger">Please select gender</div>
                  )}
                </div>
                {/* <div>
                  <Button
                    onClick={submitDraftHandler}
                    color="primary"
                    variant="contained"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                    }}
                    style={{ marginTop: '1rem' }}
                  >
                    Save Bill
                  </Button>
                </div> */}
              </form>
              {selectedClient?.clientNumber && (
                <div>
                  <Button
                    onClick={toggleDrawer}
                    color="primary"
                    variant="contained"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                    }}
                    style={{ marginTop: '1rem' }}
                  >
                    Open Bill History
                  </Button>
                  <Drawer
                    anchor="right"
                    open={isBillHistoryDrawerOpen}
                    onClose={closeBillHistoryDrawer}
                    PaperProps={{
                      sx: {
                        width: '700px',
                        boxSizing: 'border-box',
                      },
                    }}
                  >
                    {loading ? (
                      <ShowLoader />
                    ) : (
                      <div style={{ display: 'flex', width: '100%', padding: '0.5rem' }}>
                        <div style={{ width: '55%', paddingLeft: '0.5rem' }}>
                          <DialogTitle
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#6174DD',
                              color: 'white',
                            }}
                          >
                            Client Details
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText style={{ marginTop: '2rem' }}>
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                              >
                                <div>
                                  <strong>Bill Frequency:</strong> {selectedClient?.billsFrequency}
                                </div>
                                <div>
                                  <strong>Remaining Total:</strong> {selectedClient?.remainingTotal}
                                </div>
                                <div>
                                  <strong>Total Bill Amount Till Date:</strong>{' '}
                                  {selectedClient?.totalBillsAmount}
                                </div>
                                <div
                                  onClick={toggleMembershipDetails}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  <strong>Membership Buy : </strong>{' '}
                                  {selectedClient?.membershipDetails &&
                                    selectedClient?.membershipDetails?.membershipType
                                    ? 'YES'
                                    : 'NO'}
                                </div>
                                {showMembershipDetails && (
                                  <div
                                    style={{
                                      marginTop: '1rem',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '0.6rem',
                                      border: '1px solid #CFCFCF',
                                      borderRadius: '6px',
                                      backgroundColor: '#FAFAFA',
                                      padding: '0.5rem',
                                    }}
                                  >
                                    <div>
                                      <strong>Membership Type:</strong>{' '}
                                      {selectedClient?.membershipDetails?.membershipType}
                                    </div>
                                    {selectedClient?.membershipDetails?.membershipType ===
                                      'discountType' ? (
                                      <>
                                        <div>
                                          <strong>Discount on Service:</strong>{' '}
                                          {
                                            selectedClient?.membershipDetails?.discount
                                              ?.discountOnService
                                          }
                                          {
                                            selectedClient?.membershipDetails?.discount
                                              ?.serviceDiscountType
                                          }
                                        </div>
                                        <div>
                                          <strong>Discount on Product:</strong>{' '}
                                          {
                                            selectedClient?.membershipDetails?.discount
                                              ?.discountOnProduct
                                          }
                                          {
                                            selectedClient?.membershipDetails?.discount
                                              ?.productDiscountType
                                          }
                                        </div>
                                      </>
                                    ) : (
                                      <div>
                                        <strong>Services:</strong>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.5rem',
                                          }}
                                        >
                                          {selectedClient?.membershipDetails?.services.map(
                                            (service, index) => (
                                              <div key={index}>
                                                {service.serviceName} (count = {service.count})
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div>
                                      <strong>Membership Validity:</strong>{' '}
                                      {selectedClient?.membershipDetails?.startDate} to{' '}
                                      {selectedClient?.membershipDetails?.endDate}
                                    </div>
                                  </div>
                                )}
                                <div
                                  onClick={togglePackageDetails}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  <strong>Package Buy:</strong>{' '}
                                  {selectedClient?.packageDetails?.length > 0 ? 'YES' : 'NO'}
                                </div>
                                {showPackageDetails && (
                                  <div
                                    style={{
                                      marginTop: '1rem',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '0.6rem',
                                      border: '1px solid #CFCFCF',
                                      borderRadius: '6px',
                                      backgroundColor: '#FAFAFA',
                                      padding: '0.5rem',
                                    }}
                                  >
                                    {selectedClient?.packageDetails?.map((pkg, index) => (
                                      <div key={index}>
                                        <div>
                                          <strong>Package Name:</strong> {pkg.packageName}
                                        </div>
                                        <div>
                                          <strong>Price:</strong> {pkg.price}
                                        </div>
                                        <div>
                                          <strong>Validity:</strong> {pkg.validity}
                                        </div>
                                        <div>
                                          <strong>Services:</strong>{' '}
                                          {pkg.services
                                            .map((service) => service.serviceName)
                                            .join(', ')}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </DialogContentText>
                          </DialogContent>
                        </div>
                        <div
                          style={{
                            width: '45%',
                            paddingRight: '0.5rem',
                            overflowY: 'auto',
                            maxHeight: '100vh',
                          }}
                        >
                          <DialogTitle
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#6174DD',
                              color: 'white',
                            }}
                          >
                            Bill History of {selectedClient?.clientName}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {selectedClient?.bills && selectedClient?.bills.length > 0 ? (
                                selectedClient?.bills.map((bill, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      margin: '2rem 0rem',
                                      backgroundColor: '#fff',
                                      padding: '0.8rem',
                                      boxShadow: '2px 2px 4px gray',
                                      borderRadius: '6px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: '1rem',
                                      }}
                                    >
                                      <div style={{ fontWeight: 'bold', color: '#3E3E3E' }}>
                                        {bill.dateOfBilling}
                                      </div>
                                      <div style={{ fontWeight: 'bold', color: '#3E3E3E' }}>
                                        <strong>Total:</strong> {bill.amountPayable}
                                      </div>
                                    </div>
                                    <div>
                                      <strong>Services:</strong>
                                      {bill?.services?.map((service, serviceIndex) => (
                                        <div key={serviceIndex}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                              padding: '0 0 0.5rem 0rem',
                                            }}
                                          >
                                            <div>
                                              <div>{service.category}</div>
                                              <div>{service.serviceName}</div>
                                            </div>
                                            <div>{service.serviceProvider?.name}</div>
                                            <div>{service?.amount}</div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div>
                                        <strong>Amount Paid :</strong>{' '}
                                        {bill?.amountPaid?.map((el) => el?.amount).join(', ')}
                                      </div>
                                      <div>
                                        <strong>Amount due :</strong> {bill?.paidDues}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>No bill history available.</div>
                              )}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeBillHistoryDrawer} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                        </div>
                      </div>
                    )}
                  </Drawer>
                </div>
              )}
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                {!!selectedClient?.remainingTotal && (
                  <div className="mt-3 blinking" onClick={handleRemainingTotalClick}>
                    <div style={{ width: 'fit-content' }}>
                      <span className="font-weight-bold" style={{ fontSize: '18px' }}>
                        Remaining Amount :
                      </span>{' '}
                      <span className="text-danger font-weight-bold" style={{ fontSize: '18px' }}>
                        INR {selectedClient?.remainingTotal}
                      </span>
                    </div>
                  </div>
                )}
                {!!selectedClient?.advancedGiven && (
                  <div className="mt-3 blinking">
                    <div style={{ width: 'fit-content' }}>
                      <span className="font-weight-bold" style={{ fontSize: '18px' }}>
                        Wallet Amount :
                      </span>{' '}
                      <span
                        className="font-weight-bold"
                        style={{ fontSize: '18px', color: 'green' }}
                      >
                        INR {selectedClient?.advancedGiven}
                      </span>
                    </div>
                  </div>
                )}
                {!!selectedClient?.rewardPointsEarned && (
                  <div className="mt-3 blinking">
                    <div style={{ width: 'fit-content' }}>
                      <span className="font-weight-bold" style={{ fontSize: '18px' }}>
                        Reward Points :
                      </span>{' '}
                      <span
                        className="font-weight-bold"
                        style={{ fontSize: '18px', color: '#6174DD' }}
                      >
                       {selectedClient?.rewardPointsEarned}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="table-responsive responsive_tbl">
              <table className="table table-bordered">
                <thead>
                  <tr className="list">
                    <th style={{ width: '4%' }}></th>
                    <th className="col-md-3">Service / Products / Packages</th>
                    <th className="col-md-2">Service provider</th>
                    <th className="col-md-1">Tip</th>
                    <th className="col-md-1">Price</th>
                    <th className="col-md-1">Qty</th>
                    <th className="col-sm-2">Discount</th>
                    <th className="col-md-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.services?.map((formServices, index) => {

                    const isDisabled = formServices?.count !== undefined;

                    let isMembershipService = clientMembership?.services?.find(
                      (membershipService) => membershipService?.id === formServices?.service
                    );
                    // Make sure isMembershipService exists before accessing its count
                    const isCountExceeding = state?.useMembership && formServices.amount === 0 && formServices.quantity > isMembershipService?.count

                    return (
                      <tr key={index}>
                        <td style={{ width: '4%', textAlign: 'center' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            color="red"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            // viewBox="0 0 16 16"
                            style={{ cursor: 'pointer', marginTop: '0.6rem' }}
                            onClick={() => deleteServiceRow(index)}
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                          </svg>
                        </td>
                        <td className="col-md-3">
                          <div className="d-flex grid gap-2 align-items-center col-md-12">
                            <input
                              type="text"
                              className={`form-control ${formServices?.serviceName === '' && stateValidation
                                ? 'border border-danger'
                                : ''
                                }`}
                              placeholder="Service"
                              name="serviceName"
                              value={formServices?.serviceName || ''}
                              data-index={index} // Add a custom attribute to identify the input field
                              onChange={(e) => handleServiceInputChange(e, index)}
                            />
                          </div>
                          {formServices?.serviceName === '' && stateValidation && (
                            <div className="text-danger">Please select service</div>
                          )}
                          {serviceSuggested.length > 0 &&
                            focusedServiceIndex === index &&
                            activeField === 'serviceName' && (
                              <ServiceSuggestion
                                data={serviceSuggested}
                                handleServiceSuggestedClick={handleServiceSuggestedClick}
                              />
                            )}
                        </td>
                        <td
                          className={`col-md-2 ${formServices?.serviceProvider === '' && stateValidation
                            ? 'text-danger'
                            : ''
                            }`}
                        >
                          <select
                            className={`form-select ${formServices?.serviceProvider === '' && stateValidation
                              ? 'border border-danger'
                              : ''
                              }`}
                            aria-label="Default select example"
                            name="serviceProvider"
                            value={formServices?.serviceProvider}
                            onChange={(e) => handleServiceInputChange(e, index)}
                          >
                            <option value="" disabled>
                              Select Provider
                            </option>
                            {allStaffData?.map((staff, index) => (
                              <option key={staff._id} value={staff._id}>
                                {staff.name}
                              </option>
                            ))}
                          </select>
                          {formServices?.serviceProvider === '' && stateValidation && (
                            <div className="text-danger">Please select staff</div>
                          )}
                        </td>
                        <td className="col-md-1">
                          <input
                            type="number"
                            min={1}
                            className="form-control ser_cat"
                            placeholder="0"
                            name="tipAmount"
                            //value={}
                            value={formServices?.tipAmount || 0}
                            onChange={(e) => handleServiceInputChange(e, index)}
                            disabled={isDisabled || formServices?.discount === 100}
                          />
                        </td>
                        <td className="col-md-1">
                          <input
                            type="number"
                            min={1}
                            className="form-control ser_cat"
                            placeholder=""
                            name="price"
                            value={formServices?.price}
                            onChange={(e) => handleServiceInputChange(e, index)}
                            disabled={isDisabled || formServices?.discount === 100}
                          />
                        </td>
                        <td className="col-md-1">
                          <input
                            type="number"
                            min={1}
                            className="form-control ser_cat"
                            placeholder=""
                            name="quantity"
                            value={formServices?.quantity}
                            onChange={(e) => handleServiceInputChange(e, index)}
                            disabled={state.usePackage && formServices?.discount === 100}
                          />
                          {isCountExceeding && (
                            <span style={{ color: 'red' }}>
                              Quantity exceeds available membership count!
                            </span>
                          )}
                        </td>
                        <td className="col-sm-2">
                          <div className="col-md-12 d-flex grid gap-2 align-items-center">
                            <input
                              type="number"
                              className="form-control ser_cat"
                              min={0}
                              placeholder="0"
                              name="discount"
                              value={formServices?.discount}
                              // onChange={(e) => handleServiceInputChange(e, index)}
                              disabled
                            />
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="discountType"
                              value={formServices?.discountType}
                              // onChange={(e) => handleServiceInputChange(e, index)}
                              disabled
                            >
                              <option value="%">%</option>
                              <option value="INR">INR</option>
                            </select>
                          </div>
                        </td>
                        <td className="col-md-2">
                          <input
                            type="number"
                            min={0}
                            className="form-control ser_cat"
                            placeholder="amount"
                            name="amount"
                            value={formServices?.amount}
                            // onChange={(e) => handleServiceInputChange(e, index)}
                            disabled
                          />
                        </td>
                      </tr>
                    );
                  })}

                  {state?.products?.map((formProduct, index) => (
                    <tr key={index}>
                      <td style={{ width: '4%', textAlign: 'center' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          color="red"
                          fill="currentColor"
                          className="bi bi-trash3-fill"
                          // viewBox="0 0 16 16"
                          style={{ cursor: 'pointer', marginTop: '0.6rem' }}
                          onClick={() => deleteProductRow(index)}
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                      </td>
                      <td className="col-md-3">
                        <div className="d-flex grid gap-2 align-items-center col-md-12">
                          <input
                            type="text"
                            className={`form-control ${formProduct?.productName === '' && stateValidation
                              ? 'border border-danger'
                              : ''
                              }`}
                            placeholder="Product"
                            name="productName"
                            autoComplete='off'
                            value={formProduct?.productName}
                            onChange={(e) => handleProductInputChange(e, index)} // Add onChange handler
                          />
                        </div>
                        {formProduct?.productName === '' && stateValidation && (
                          <div className="text-danger">Please select product</div>
                        )}
                        {/* {productSuggested.length > 0 &&
                          focusedProductIndex === index &&
                          activeField === 'productName' && (
                            <ProductSuggestion
                              data={productSuggested}
                              handleProductSuggestedClick={handleProductSuggestedClick}
                            />
                          )} */}
                        {productSuggested.length > 0 &&
                          focusedProductIndex === index &&
                          activeField === 'productName' && (
                            <ProductSuggestion
                              data={productSuggested}
                              handleProductSuggestedClick={handleProductSuggestedClick}
                            />
                          )}
                      </td>
                      <td
                        className={`col-md-2 ${formProduct?.providerStaff === '' && stateValidation ? 'text-danger' : ''
                          }`}
                      >
                        <select
                          className={`form-select ${formProduct?.providerStaff === '' && stateValidation
                            ? 'border border-danger'
                            : ''
                            }`}
                          aria-label="Default select example"
                          name="providerStaff"
                          value={formProduct?.providerStaff || ''}
                          onChange={(e) => handleProductInputChange(e, index)}
                        >
                          <option value="" disabled>
                            Select Provider
                          </option>
                          {allStaffData?.map((staff) => (
                            <option key={staff._id} value={staff._id}>
                              {staff.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="col-md-1">
                        <input
                          type="number"
                          min={1}
                          className="form-control ser_cat"
                          placeholder="0"
                          name="tipAmount"
                          //value={}
                          value={formProduct?.tipAmount || 0}
                          onChange={(e) => handleProductInputChange(e, index)}
                          disabled
                        />
                      </td>
                      <td className="col-md-1">
                        <input
                          type="number"
                          // min={0}
                          className="form-control ser_cat"
                          placeholder=""
                          name="price"
                          //value={}
                          value={formProduct?.price}
                          onChange={(e) => handleProductInputChange(e, index)}
                        />
                      </td>
                      <td className="col-sm-1">
                        <input
                          type="number"
                          className="form-control ser_cat"
                          min={1}
                          placeholder="0"
                          name="quantity"
                          value={formProduct?.quantity}
                          onChange={(e) => handleProductInputChange(e, index)}
                        />
                      </td>
                      <td className="col-sm-2">
                        <div className="col-md-12 d-flex grid gap-2 align-items-center">
                          <input
                            type="number"
                            min={0}
                            className="form-control ser_cat"
                            placeholder="0"
                            name="discount"
                            value={formProduct?.discount}
                            // onChange={(e) => handleProductInputChange(e, index)}
                            disabled
                          />
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="discountType"
                            value={formProduct?.discountType}
                            // onChange={(e) => handleProductInputChange(e, index)}
                            disabled
                          >
                            <option value="%">%</option>
                            <option value="INR">INR</option>
                          </select>
                        </div>
                      </td>
                      <td className="col-md-2">
                        <input
                          type="number"
                          min={0}
                          className="form-control ser_cat"
                          name="amount"
                          value={formProduct?.amount}
                          // onChange={(e) => handleProductInputChange(e, index)}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="9">
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#6174DD',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          marginRight: '10px',
                          marginLeft: '990px',
                        }}
                        onClick={addServiceRow}
                      >
                        Add Service
                      </Button>
                      {/* <button
                        className="btn btn-success"
                        style={{
                          background: '#6174DD',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#FFF',
                        }}
                        onClick={addProductRow}
                      >
                        Add Product
                      </button> */}
                      <Button
                        className="btn btn-success"
                        style={{
                          background: '#6174DD',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#FFF',
                          border: 'none',
                          boxShadow: 'none',
                        }}
                        onClick={addProductRow}
                      >
                        Add Product
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>INR&nbsp;&nbsp;{state?.subTotal}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>Membership</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      {state.useMembership && (
                        <>
                          <span style={{ color: 'green' }}>Applied</span>
                          <button
                            onClick={() =>
                              dispatch({
                                type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
                                payload: {
                                  useMembership: false,
                                  item: clientMembership,
                                  selectedServices: updatedServices,
                                },
                              })
                            }
                          >
                            <DisabledByDefaultIcon style={{ fontSize: '12px', border: 'none' }} />
                          </button>
                        </>
                      )}
                      {Object.keys(clientMembership).length !== 0 && !state.useMembership && (
                        <button
                          style={{
                            ...buttonStyle,
                            backgroundColor:
                              !!state?.coupon?.couponId ||
                                !!Number(state?.discount?.discount) ||
                                state.usePackage
                                ? 'gray'
                                : buttonStyle.backgroundColor,
                          }}
                          variant="contained"
                          disabled={
                            !!state?.coupon?.couponId ||
                            !!Number(state?.discount?.discount) ||
                            state.usePackage
                          }
                          onClick={handleOpenMembershipDialogue}
                        >
                          Membership
                        </button>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Package</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      {state.usePackage && (
                        <>
                          <span style={{ color: 'green' }}>Applied</span>
                          <button
                            onClick={() =>
                              dispatch({
                                type: HANDLE_PACKAGE_INPUT_CHANGE,
                                payload: { usePackage: false, item: clientPackage, selectedServices: updatedPackageServices },
                              })
                            }
                          >
                            <DisabledByDefaultIcon style={{ fontSize: '12px', border: 'none' }} />
                          </button>
                        </>
                      )}
                      {Object.keys(clientPackage).length !== 0 && !state.usePackage && (
                        <button
                          style={{
                            ...buttonStyle,
                            backgroundColor:
                              !!state?.coupon?.couponId ||
                                !!Number(state?.discount?.discount) ||
                                state.useMembership
                                ? 'gray'
                                : buttonStyle.backgroundColor,
                          }}
                          variant="contained"
                          disabled={
                            !!state.coupon.couponId ||
                            !!Number(state?.discount?.discount) ||
                            state.useMembership
                          }
                          onClick={handleOpenPackageDialogue}
                        >
                          Package
                        </button>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Taxes</td>
                    <td className="col-md-4">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="tax"
                        id="tax"
                        data-validation="required"
                        value={`${state?.taxes?.taxType === 'Inclusive' ? 'Inclusive' : 'Exclusive'
                          },${state?.taxes?.SGST},${state?.taxes?.PGST}`}
                        onChange={handleTaxeInputChange}
                        disabled
                      >
                        <option value="" disabled>
                          Select Taxes
                        </option>
                        <optgroup label="Inclusive Taxes">
                          <option value="Inclusive,0,0">
                            Inclusive Gst on Products(0) and Service(0)
                          </option>
                        </optgroup>
                        <optgroup label="Exclusive Taxes">
                          <option value="Exclusive,18,18">
                            Exclusive Gst on Products(18) and Service(18)
                          </option>
                        </optgroup>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td className="col-md-2">
                      <div className="col-md-12 d-flex grid gap-2 align-items-center">
                        <input
                          type="number"
                          className="form-control ser_cat"
                          min={0}
                          placeholder="0"
                          name="discount"
                          value={state?.discount?.discount}
                          onChange={handleDiscountInputChange}
                          disabled={
                            state?.useMembership || !!state?.coupon?.couponId || state.usePackage
                          }
                        />
                        {/* <select
                          className="form-select"
                          aria-label="Default select example"
                          name="discountType"
                          value={state?.discount?.discountType}
                          onChange={handleDiscountInputChange}
                          disabled={
                            state?.useMembership || !!state?.coupon?.couponId || state.usePackage
                          }
                        >
                          <option value="%">%</option>
                          <option value="INR">INR</option>
                        </select> */}
                        <ToggleButtonGroup
                          value={state?.discount?.discountType}
                          exclusive
                          onChange={(event, newDiscountType) => {
                            if (newDiscountType !== null) {
                              dispatch({
                                type: HANDLE_DISCOUNT_INPUT_CHANGE,
                                payload: { name: 'discountType', value: newDiscountType },
                              });
                            }
                          }}
                          aria-label="discount type"
                          fullWidth
                          sx={{
                            borderRadius: '4px',
                            height: '38px',
                            width: '180px',
                            backgroundColor: '#f0f0f0',
                          }}
                          disabled={
                            state?.useMembership || !!state?.coupon?.couponId || state.usePackage
                          }
                        >
                          {discount_type.map((type) => (
                            <ToggleButton
                              key={type}
                              value={type}
                              aria-label={type}
                              sx={{
                                color: '#000',
                                backgroundColor: '#e0e0e0',
                                '&.Mui-selected': {
                                  backgroundColor: '#6174DD',
                                  color: '#fff',
                                  '&:hover': {
                                    backgroundColor: '#6174DD',
                                  },
                                },
                                '&:not(.Mui-selected)': {
                                  '&:hover': {
                                    backgroundColor: '#d0d0d0',
                                  },
                                },
                              }}
                            >
                              {type}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <td> Total</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>INR&nbsp;&nbsp;{state?.totalAmount}</span>
                    </td>
                  </tr> */}
                  <tr>
                    <td> Coupon</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      {state?.coupon?.couponId && (
                        <>
                          <span>{state?.coupon?.couponCode} </span>
                          <button
                            onClick={() =>
                              dispatch({ type: HANDLE_COUPON_INPUT_CHANGE, payload: {} })
                            }
                          >
                            <DisabledByDefaultIcon style={{ fontSize: '12px', border: 'none' }} />
                          </button>
                        </>
                      )}
                      {clientCoupons.length !== 0 && (
                        <span
                          onClick={handleOpenDialogue}
                          style={{ hover: 'cursor', paddingTop: '5px' }}
                        >
                          <button
                            style={{
                              ...buttonStyle,
                              backgroundColor:
                                state?.useMembership ||
                                  !!Number(state?.discount?.discount) ||
                                  state.usePackage
                                  ? 'gray'
                                  : buttonStyle.backgroundColor,
                            }}
                            variant="contained"
                            disabled={
                              state?.useMembership ||
                              !!Number(state?.discount?.discount) ||
                              state.usePackage
                            }
                          >
                            Coupons
                          </button>
                        </span>
                      )}
                    </td>
                  </tr>

                  {/* <tr>
                    <td>Advance received</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>&nbsp;&nbsp;{0}</span>
                    </td>
                  </tr> */}

                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Use Reward Point</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '20px',
                          justifyContent: 'center',
                        }}
                      >
                        {/* Switch Component */}
                        <Switch
                          checked={state.useRewardPoint}
                          onChange={handleToggleChange}
                          color="primary"
                          disabled={
                            !selectedClient?.rewardPointsEarned ||
                            !(selectedClient?.rewardPointsEarned > 0) ||
                            !branchData?.perRewardPointsPrice
                          }
                        />
                        {/* Conditional Reward Points Calculation */}
                        {state.useRewardPoint && (
                          <>
                            {selectedClient?.rewardPointsEarned &&
                              branchData?.perRewardPointsPrice ? (
                              <span>
                                <strong>
                                  Reward Points Value: ₹
                                  {Math.round(
                                    (selectedClient.rewardPointsEarned || 0) *
                                    (branchData.perRewardPointsPrice || 0),
                                  )}
                                </strong>
                              </span>
                            ) : (
                              <span>No Reward Points Available</span>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Net Amount</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;{state?.amountPayable}</span>
                    </td>
                  </tr>
                  <>
                    <tr>
                      <td>Amount paid</td>
                      <td className="col-md-4" colSpan="2">
                        {state?.amountPaid?.map((amountPaid, index) => {
                          // Calculate the sum of amounts before the current index
                          const previousAmount = state?.amountPaid
                            ?.slice(0, index)
                            .reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);

                          // Check if the current amount plus previous amounts exceed amountPayable
                          const exceedsPayable =
                            previousAmount + parseFloat(amountPaid.amount || 0) >
                            state?.amountPayable;

                          return (
                            <table key={index}>
                              <tbody>
                                <tr style={{ display: 'flex', alignItems: 'flex-start' }}>
                                  <td>
                                    <input
                                      type="number"
                                      step="1"
                                      className={`form-control ${amountPaid?.amount === '' && stateValidation
                                        ? 'border border-danger'
                                        : ''
                                        }`}
                                      min="0"
                                      name="amount"
                                      placeholder="0"
                                      value={amountPaid?.amount || ''}
                                      onChange={(e) => handleAmountPaidInputChange(e, index)}
                                    />
                                    {amountPaid?.amount === '' && stateValidation && (
                                      <div className="text-danger">Please enter amount</div>
                                    )}
                                    {exceedsPayable && (
                                      <div className="text-danger">
                                        You cannot give advance here. If you want to give advance,
                                        please recharge your wallet.
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      data-validation="required"
                                      name="paymentType"
                                      value={amountPaid?.paymentType}
                                      onChange={(e) => handleAmountPaidInputChange(e, index)}
                                    >
                                      <option value="" disabled>
                                        --Select--
                                      </option>
                                      <option value="Cash">Cash</option>
                                      <option value="Credit/Debit Card">Credit/Debit Card</option>
                                      <option value="Paytm">Paytm</option>
                                      <option value="PhonePe">PhonePe</option>
                                      <option value="Gpay">Gpay</option>
                                      <option value="Wallet">Wallet</option>
                                    </select>
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <span className="input-group-btn">
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          target="_blank"
                                          sx={{
                                            background: '#6174DD',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                          }}
                                          onClick={addAmountPaidRow}
                                        >
                                          <span className="bi bi-plus small"></span>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span className="input-group-btn">
                                        <Button
                                          variant="contained"
                                          color="error"
                                          target="_blank"
                                          sx={{
                                            background: '#bb2d3b',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                          }}
                                          onClick={() => deleteAmountPaidRow(index)}
                                        >
                                          <span className="bi bi-dash small"></span>
                                        </Button>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                      </td>
                    </tr>

                    {/* Snackbar for displaying messages */}
                    <Snackbar
                      open={snackbar.open}
                      onClose={handleCloseSnackbar}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      autoHideDuration={8000}
                      ContentProps={{
                        sx: {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                          borderRadius: '8px',
                          padding: '0.5rem',
                        },
                      }}
                    >
                      <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbar.severity}
                        sx={{
                          fontSize: '1.5rem',
                          fontFamily: 'Arial, sans-serif',
                          color: '#ff0033',
                          backgroundColor: '#ffffff',
                          fontWeight: 'bold',
                          padding: '1rem 2rem',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.75)',
                          display: 'flex',
                          alignItems: 'center',
                          lineHeight: '25px',
                        }}
                      >
                        {snackbar.message}
                      </Alert>
                    </Snackbar>
                  </>
                  <tr>
                    <td>Amount due</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span name="paidDues">
                        INR&nbsp;&nbsp;
                        {state?.amountPaid?.reduce(
                          (acc, payment) => acc + parseFloat(payment.amount || 0),
                          0,
                        ) > state?.amountPayable
                          ? 0
                          : state?.paidDues}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="7">
                      <textarea
                        className="form-control no-resize"
                        rows="2"
                        placeholder="Write notes about billing here..."
                        id="textArea"
                        name="notes"
                        value={state?.notes}
                        onChange={handleClientInputChange}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {showLoader ? (
              <ShowLoader />
            ) : (
              // <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              //   <Button
              //     variant="contained"
              //     color="primary"
              //     target="_blank"
              //     sx={{
              //       background: '#6174DD',
              //       fontSize: '14px',
              //       fontWeight: 'bold',
              //       width: '20%',
              //     }}
              //     onClick={submitHanlder}
              //   >
              //     Create Bill
              //   </Button>
              // </div>
              // <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      // width: '20%',
                      width: '200px',
                      height: '40px',
                      marginTop: '1rem',
                    }}
                    onClick={submitHanlder}
                  >
                    Create Bill
                  </Button>

                  <Button
                    onClick={submitDraftHandler}
                    color="primary"
                    variant="contained"
                    target="_blank"
                    sx={{
                      background: '#EBEBE0',
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '200px',
                      height: '40px',
                      '&:hover': {
                        background: '#D6D6C8',
                      },
                    }}
                    style={{ marginTop: '1rem' }}
                    disabled={showLoader} // Disable the button while the loader is active
                  >
                    {showLoader ? (
                      <CircularProgress size={24} style={{ color: '#03C4A9' }} /> // Loader spinner
                    ) : (
                      'Save To Draft' // Button text when not loading
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
          <CouponCard
            data={clientCoupons}
            state={state}
            dispatch={dispatch}
            onClose={handleCloseDialogue}
          />
        </Dialog>
        <Dialog open={openMembershipDialogue} onClose={handleCloseMembershipDialogue} maxWidth="md">
          <MembershipCard
            data={clientMembership}
            allServicesData={genderWiseService}
            state={state}
            dispatch={dispatch}
            onClose={handleCloseMembershipDialogue}
            handleUpdateServices={handleUpdateServices}
          />
        </Dialog>
        <Dialog open={openPackageDialogue} onClose={handleClosePackageDialogue} maxWidth="md">
          <PackageCard
            data={clientPackage}
            allServicesData={genderWiseService}
            state={state}
            dispatch={dispatch}
            onClose={handleClosePackageDialogue}
            handleUpdateServices={handlePackageService}
          />
        </Dialog>
        <Dialog
          open={openRemainingAmountDialogue}
          onClose={handleCloseRemainingAmountDialogue}
          maxWidth="md"
        >
          <DialogTitle>Remaining Amount Bills</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Remaining Amount Bills</DialogContentText>{' '} */}
            <RemainingAmountCard
              data={!!selectedClient?.remainingTotal ? selectedClient?.paidDuesBillDetails : []}
              clientNumber={state.clientNumber}
              setClientMembership={setClientMembership}
              setClientCoupons={setClientCoupons}
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              allCouponsData={allCouponsData}
            />
          </DialogContent>
          <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              sx={{
                width: '14%',
                background: '#6174DD',
              }}
              onClick={handleCloseRemainingAmountDialogue}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openBillDialogue}
          onClose={handleBillCloseDialogue}
          PaperProps={{
            style: {
              maxWidth: '1200px',
              width: '50%',
              minHeight: '40%',
            },
          }}
        >
          <DialogTitle>Bill Details</DialogTitle>
          <DialogContent>
            <DialogContentText>Here you can see Bill details.</DialogContentText>
            <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
              <table className="table-container mt-3">
                <thead className="table-thead">
                  <tr>
                    {['Date of Bill', 'Client Name', 'Contact', 'Total', 'Paid', 'Pending'].map(
                      (h, index) => {
                        return (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        );
                      },
                    )}
                  </tr>
                </thead>
                <tbody className="table-tbody">
                  <tr key={createdBill._id}>
                    <td style={{ width: '100%' }}>{createdBill?.dateOfBilling}</td>
                    <td style={{ width: '100%' }}>{createdBill?.clientName}</td>
                    <td style={{ width: '100%' }}>
                      {typeof branchData?.isMasked !== 'boolean' || !createdBill?.clientNumber
                        ? 'loading...'
                        : maskData(createdBill?.clientNumber, branchData?.isMasked)}
                    </td>
                    <td style={{ width: '100%' }}>{createdBill?.amountPayable}</td>
                    <td style={{ width: '100%' }}>
                      {createdBill?.amountPaid?.reduce(
                        (acc, payment) => acc + Number(payment.amount),
                        0,
                      )}
                    </td>
                    <td style={{ width: '100%' }}>{createdBill?.paidDues}</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </DialogContent>
          <DialogActions sx={{ gap: '20px' }}>
            <Button
              variant="contained"
              // fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleBillCloseDialogue}
            >
              Not Now
            </Button>

            <Button
              variant="contained"
              // fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={() => navigate(`/billing/${createdBill?._id}`)}
            >
              View Bill
            </Button>
          </DialogActions>
        </Dialog>
      </DashboardCard>
    </PageContainer>
  );
};

export default NewBilling;

function filterValidCoupons(coupons) {
  const currentDate = new Date();
  if (!coupons) {
    return [];
  }
  return coupons.filter((coupon) => {
    const couponValidTill = new Date(coupon.validTill);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const couponYear = couponValidTill.getFullYear();
    const couponMonth = couponValidTill.getMonth();
    const couponDay = couponValidTill.getDate();
    return (
      currentYear < couponYear ||
      (currentYear === couponYear && currentMonth < couponMonth) ||
      (currentYear === couponYear && currentMonth === couponMonth && currentDay <= couponDay)
    );
  });
}

function isValidMembershipDetails(membershipDetails) {
  // Check if membershipDetails is an object and has the required properties
  // console.log(membershipDetails);
  if (
    typeof membershipDetails === 'object' &&
    membershipDetails.startDate &&
    membershipDetails.endDate
  ) {
    const startDate = new Date(membershipDetails.startDate);
    const endDate = new Date(membershipDetails.endDate);
    const currentDate = new Date();

    // Check if startDate and endDate are valid Date objects and if the current date is within the range
    if (
      !isNaN(startDate.getTime()) &&
      !isNaN(endDate.getTime()) &&
      currentDate >= startDate &&
      currentDate <= endDate
    ) {
      return true;
    }
  }

  return false;
}

function isValidPackageDetails(packageDetails) {
  // Check if packageDetails is an object and has the required properties
  // console.log(packageDetails);
  if (typeof packageDetails === 'Array' && packageDetails[0].validity) {
    const startDate = new Date(packageDetails[0].validity);
    const currentDate = new Date();

    // Check if startDate and endDate are valid Date objects and if the current date is within the range
    // console.log(startDate, currentDate);
    if (!isNaN(startDate.getTime()) && currentDate <= startDate) {
      return true;
    }
  }
  return false;
}

function checkPackageValidation(PackageDetails) {
  if (isValidPackageDetails(PackageDetails)) {
    // Valid Package details, so you can use them
    return PackageDetails;
  } else {
    // Package details are not valid, return an empty object
    return {};
  }
}

function checkMembershipValidation(membershipDetails) {
  if (isValidMembershipDetails(membershipDetails)) {
    // Valid membership details, so you can use them
    return membershipDetails;
  } else {
    // Membership details are not valid, return an empty object
    return {};
  }
}

function filterClinetsCoupons(coupons, clientCoupons) {
  // Create an object to keep track of how many times each coupon has been used
  const couponUsage = clientCoupons.reduce((acc, couponId) => {
    acc[couponId] = (acc[couponId] || 0) + 1;
    return acc;
  }, {});

  // Filter coupons based on couponPerUser and usage
  return coupons.filter((coupon) => {
    const usedCount = couponUsage[coupon._id] || 0;
    return coupon.couponPerUser > usedCount;
  });
}
