import { baseUrl, headers, jwtFormDataHeaders, jwtHeaders} from '../common/data';

export const fetchAllStaffs = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/staff/getallstaffbybranch/${branchId}${queryParams ? queryParams : ''}`,
    {
      // headers,
      headers:jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const getAllStaff = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/staff/getall/${branchId}`, {
    // headers,
    headers:jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const createStaff = async (data) => {
  console.log(data,"datastafff")
  const response = await fetch(`${baseUrl}/api/staff/create`, {
    method: 'POST',
    body: data,
    headers:jwtFormDataHeaders()
  });
  const resData = await response?.json();

  return resData;
};

export const updateStaff = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/staff/editdetails/${id}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      // headers,
      headers:jwtHeaders(),
    },
  );
  const resData = await response.json();

  return resData;
};

export const deleteStaff = async (id, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/staff/deletestaff/${id}${branchId ? branchId : ''}`,
    {
      method: 'DELETE',
      // headers: headers,
      headers:jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const getStaffById = async (id) => {
  const response = await fetch(`${baseUrl}/api/staff/getastaff/${id}`, {headers:jwtHeaders()});
  const resData = await response?.json();

  return resData;
};

export const fetchAttendanceByStaffId = async (id, queryParmas) => {
  const response = await fetch(
    `${baseUrl}/api/staff/attendance/${id}${queryParmas ? queryParmas : ''}`,
    {
      method: 'GET',
      // headers,
      headers:jwtHeaders() 
    },
  );
  const resData = await response?.json();
  return resData;
};
export const fetchStaffOverviewData = async (selectedStaffId, queryParmas) => {
  const response = await fetch(
    `${baseUrl}/api/staff/staffoverview/${selectedStaffId}${queryParmas ? queryParmas : ''}`,
    {
      method: 'GET',
      // headers,
      headers:jwtHeaders() 
    },
  );
  const resData = await response?.json();
  return resData;
};

export const staffCollection = async (id, queryParmas) => {
  const response = await fetch(
    `${baseUrl}/api/billing/staffwisesales/${id}${queryParmas ? queryParmas : ''}`,
    {
      method: 'GET',
      // headers,
      headers:jwtHeaders() 
    },
  );
  const resData = await response?.json();
  return resData;
};

export const changeStaffAccountStatus = async (staffId, queryParams, data) => {
  const response = await fetch(
    `${baseUrl}/api/staff/activestatus/${staffId}${queryParams ? queryParams : ''}`,
    {
      method: 'PATCH',
      // headers,
      headers:jwtHeaders(),
      body: JSON.stringify(data),
      redirect: 'follow',
    },
  );
  const resData = await response.json();
  return resData;
};

export const staffStatusUpdate = async (selectedStaffId,queryParams) => {
  const response = await fetch(`${baseUrl}/api/staff/updateattendance/${selectedStaffId}${queryParams}`, {
    method: 'POST',
    headers:jwtFormDataHeaders()
  });
  const resData = await response?.json();

  return resData;
};

export const fetchStaffWiseWork = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/staff/staffwisework/${branchId}?${queryParams ? queryParams:''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
    }
  );
  const resData = await response?.json();
  console.log(resData,"resData")
  return resData;
};

export const staffWorkAnalysis = async (id, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/staff/staffworkanalysis/${id}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders()
    }
  );
  const resData = await response.json();
  return resData;
};

export const staffOverview = async (id, branchId, month) => {
  const response = await fetch(
    `${baseUrl}/api/staff/staffoverview/${id}?branchId=${branchId}&month=${month}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
    }
  );

  const resData = await response.json();
  return resData;
};
