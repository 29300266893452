import React, { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import PageContainer from 'src/components/container/PageContainer';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './ExpenseAnalytics.css';
import { useMain } from 'src/views/hooks/useMain';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { FaDownload } from "react-icons/fa";

import * as XLSX from 'xlsx';

ChartJS.register(ArcElement, Tooltip, Legend);

const initialData = {
  labels: [],
  datasets: [
    {
      label: '# of Votes',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

const filterOptions = [
  { label: 'Current Year', value: 'Current Year' },
  { label: 'Current Month', value: 'Current Month' },
  { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
  { label: 'Today', value: 'Today' },
  { label: 'Custom Date', value: 'customDate' },
];

function ExpenseAnalyticss() {
  const [filterBy, setFilterBy] = useState('Today');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState(initialData);
  const [cashData, setCashData] = useState([]);
  const tableRef = useRef();

  const { fetchExpenseAnalytics, activeBranchId } = useMain();

  const handleOnExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(tableData);
    XLSX.utils.book_append_sheet(wb, ws, 'ExpenseAnalyticsData');
    XLSX.writeFile(wb, 'ExpenseAnalyticsData.xlsx');
  };

  const getData = async () => {
    const queryParams = `?filterBy=${filterBy}${from ? `&startDate=${from}` : ''}${
      to ? `&endDate=${to}` : ''
    }`;
    try {
      setLoading(true);
      const res = await fetchExpenseAnalytics(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        const resData = res.data;
        setCashData((pre) => resData?.paymentTypeWiseExpenseData || []);
        setGraphData((pre) => ({
          ...pre,
          labels: resData?.expenseData?.labels || [],
          datasets: [
            {
              ...pre[0],
              data: resData?.expenseData?.data || [],
              backgroundColor: resData?.expenseData?.backgroundColor || [],
              borderColor: resData?.expenseData?.backgroundColor || [],
            },
          ],
        }));
        setTableData(resData?.expenseData?.expenses || []);
      } else {
        setCashData([]);
        setGraphData({ ...initialData, datasets: [{ ...initialData.datasets }] });
        setTableData([]);
      }
    } catch (error) {
      setCashData([]);
      setGraphData({ ...initialData, datasets: [{ ...initialData.datasets }] });
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleApply = () => {
    getData();
  };

  useEffect(() => {
    if (activeBranchId) getData();
    return () => {
      setGraphData({ ...initialData, datasets: [{ ...initialData.datasets }] });
    };
  }, [activeBranchId]);

  return (
    <>
      <PageContainer title="Expense Analytics" description="this is Expense Analytics">
        <DashboardCard2 title="Expense Analytics">
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div className="buttons-container">
                <div className="row">
                  <div className="col-3" style={{width: '15%'}}>
                    <FormControl fullWidth>
                      <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter</div>
                      <Select
                        name="sourceOfAppointment"
                        value={filterBy}
                        onChange={(e) => setFilterBy(e.target.value)}
                      >
                        {filterOptions?.map((sourceOption) => (
                          <MenuItem key={sourceOption.value} value={sourceOption.value}>
                            {sourceOption.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {filterBy === 'customDate' && (
                    <>
                      <div className="col-3">
                        {/* <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div> */}
                        <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Start Date</div>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          style={{ padding: '16px' }}
                          aria-describedby="emailHelp"
                          name="from"
                          onChange={(e) => setFrom(e.target.value)}
                          value={from}
                        />
                      </div>
                      <div className="col-3">
                        {/* <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div> */}
                        <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>End Date</div>
                        <input
                          type="date"
                          max={new Date().toISOString().split('T')[0]}
                          className="form-control"
                          id="exampleInputEmail1"
                          style={{ padding: '16px' }}
                          aria-describedby="emailHelp"
                          name="to"
                          onChange={(e) => setTo(e.target.value)}
                          value={to}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                      disabled={!!(filterBy === 'customDate' && (from === '' || to === ''))}
                      variant="contained"
                      // fullWidth
                      color="primary"
                      target="_blank"
                      sx={{
                        background: '#6174DD',
                        // width:'50%',
                        width:'35%',
                        height:'50px',
                        marginBottom:'0.1rem',
                      }}
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              {tableData.length !== 0 ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button  title="Download Excel File" className="view-btn" onClick={handleOnExport} style={{marginTop: '2rem',width: '3.8rem',height: '2.8rem',borderRadius: '0.3rem'}}>
                    {/* Download Excel File */}
                    <FaDownload style={{height:"1.8rem",fontSize:"1.5rem"}}/>
                    {/* <FaDownload /> */}
                  </button>
                </div>
              ) : (
                ''
              )}
              {graphData.labels.length !== 0 ? (
                <>
                  <div className="chart-container">
                    <div style={{ height: '430px', margin: '5px' }}>
                      <Pie data={graphData} />
                    </div>
                    <div className="w-100">
                      <h6 style={{ fontWeight: 700, fontSize: '18px', textAlign: 'center' }}>
                        Expense Report
                      </h6>
                      <table className="table-container mt-2">
                        <thead className="table-thead">
                          <tr>
                            {['Payment Mode', 'Amount'].map((h, index) => {
                              return (
                                <th key={index} style={{ width: '100%' }}>
                                  {h}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody className="table-tbody">
                          {cashData?.map((item, key) => (
                            <tr key={key}  style={{height:"50px"}}>
                              <td style={{ width: '100%'}}>{item.modeOfPayment}</td>
                              <td style={{ width: '100%'}}>{item.totalAmounts}</td>
                            </tr>
                          ))}
                          <tr style={{ background: '#ebebe0' }}>
                            <td style={{ width: '100%', fontWeight: 'bold' }}>Total</td>
                            <td style={{ width: '100%', fontWeight: 'bold' }}>
                              {cashData.reduce(
                                (total, item) => total + Number(item?.totalAmounts),
                                0,
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <p>There is no Expense for analytics</p>
              )}
              {tableData.length !== 0 ? (
                <table className="table-container" ref={tableRef}>
                  <thead className="table-thead">
                    <tr style={{marginTop:"20px"}}>
                      {[
                        'Date',
                        'Expenses Type',
                        'Amount Paid',
                        'Payment Mode',
                        'Receipt Name',
                        'Description',
                      ].map((h, index) => {
                        return (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="table-tbody">
                    {tableData.map((item) => {
                      const originalDate = item?.date;

                      // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
                      let formattedExpenseDate = '';
                      if (originalDate) {
                        const [year, month, day] = originalDate.split('-');
                        formattedExpenseDate = `${day}-${month}-${year}`;
                      }
                      return (
                        <tr key={item._id}>
                          <td style={{ width: '100%' }}>{formattedExpenseDate}</td>
                          <td style={{ width: '100%' }}>{item?.typeOfExpense}</td>
                          <td style={{ width: '100%' }}>{item?.amountPaid}</td>
                          <td style={{ width: '100%' }}>{item?.modeOfPayment}</td>
                          <td style={{ width: '100%' }}>{item?.recipientName}</td>
                          <td style={{ width: '100%' }}>{item?.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                ''
              )}
            </>
          )}
        </DashboardCard2>
      </PageContainer>
    </>
  );
}

export default ExpenseAnalyticss;