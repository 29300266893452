const MessageData = (billData, clientData, branchDetails, feedbackUrl, billUrl) => {
  console.log(billData, "billDataaaa");

  const totalPaidAmount = billData?.amountPaid?.reduce((acc, cur) => acc + cur?.amount || 0, 0);
  console.log(totalPaidAmount);

  const listOfServices = billData?.services
    .map((service) => `${service.serviceName} (${service.amount})`)
    .join(', ');

  const listOfProducts = billData?.products
    ? billData.products.map((product) => `${product.productName} (${product.amount})`).join(', ')
    : '';

  const couponApplied = billData?.coupon?.couponCode ? 'Yes' : 'No';
  const couponDiscount = couponApplied === 'Yes' ? billData?.coupon?.discountAmount : 0;
  const totalAmountAfterDiscount = billData?.amountPayable - couponDiscount;
  const receivedAmount = billData?.receivedAmount || 0;
  const dueBalance = billData?.paidDues;
  
  const branchName = encodeURIComponent(branchDetails?.branchName?.trim() || 'our salon');

  console.log(branchName);

  // let text = `Hi ${encodeURIComponent(billData?.clientName)}, Thank you for choosing ${branchName}. Kindly find your Invoice details. List of services: ${encodeURIComponent(listOfServices)}`;
  let text = `Hi ${encodeURIComponent(billData?.clientName)}, Thank you for choosing ${branchName}. Kindly find your Invoice details.`;

  // Add product list only if products are available in the bill
  if (listOfProducts !== '') {
    text += ` List of products: ${encodeURIComponent(listOfProducts)}`;
  }

  // Add membership details only if membership is applied
  if (billData?.useMembership) {
    text += `%0AMembership%20applied%20-%20Yes%0A`;

    const membership = clientData?.membershipDetails;
    if (membership?.membershipType === 'serviceType') {
      text += `Remaining%20Membership%20details:%20%0AValidity:%20${encodeURIComponent(membership?.endDate)}%0ARemaining%20Service%20and%20count:%20%0A${membership?.services
        .map((service) => `Service:%20${encodeURIComponent(service.serviceName)},%20Remaining%20count:%20${service.count}`)
        .join('%0A')}`;
    } else {
      text += `Remaining%20Membership%20details:%20%0AValidity:%20${encodeURIComponent(membership?.endDate)}%0ARemaining%20Count:%20${membership?.discount?.availableCount}%0ADiscount%20on%20Product:%20${membership?.discount?.discountOnProduct}%20${membership?.discount?.productDiscountType}%0ADiscount%20on%20Service:%20${membership?.discount?.discountOnService}%20${membership?.discount?.serviceDiscountType}%0ADiscount%20on%20Package:%20${membership?.discount?.discountOnPackages}%20${membership?.discount?.packageDiscountType}`;
    }
  }

  // Add coupon details only if coupon is applied
  if (couponApplied === 'Yes') {
    text += `%0ACoupon%20applied%20-%20Yes%0ACoupon%20Discount%20-%20${couponDiscount}%0A`;
  }

  // Add discount details only if a discount is applied
  if (billData?.discount && billData?.discount > 0) {
    text += `%0ADiscount%20applied:%20${billData?.discount}%0A`;
  }

  // text += `%0ASub%20Total:%20${billData?.subTotal}%0ATotal%20amount:%20${totalAmountAfterDiscount}%0AReceived%20Amount:%20${totalPaidAmount}%0ADue%20Balance:%20${dueBalance}%0A%0AYour%20satisfaction%20is%20important%20to%20us.%20We'd%20love%20to%20hear%20your%20feedback.%20Please%20click%20on%20the%20link%20below%20to%20share%20your%20thoughts:%0A%0A[Click Here to Give Feedback]%0A%0A${encodeURIComponent(feedbackUrl)}%0A%0AWe%20appreciate%20your%20effort%20and%20look%20forward%20to%20serving%20you%20again.%0A%0AView%20your%20detailed%20bill%20here:%20${encodeURIComponent(billUrl)}`;
  text += `%0A%0A*View your detailed bill here:*%20${encodeURIComponent(billUrl)}`;

  text += `%0A%0AYour satisfaction is important to us. We'd love to hear your feedback.%0APlease click on the link below to share your thoughts:%0A%0A*Click Below to Give Feedback*%0A%0A${encodeURIComponent(feedbackUrl)}%0A%0AWe appreciate your effort and look forward to serving you again.`;

  const countryCode = '91';
  const url = `https://api.whatsapp.com/send?phone=${countryCode}${billData?.clientNumber}&text=${text}`;
  console.log(url); 
  return url;
};

export { MessageData };