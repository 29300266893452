import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProfileImg from '../../../assets/images/profile/user-1.jpg';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from 'src/views/hooks/useMain';
import imageCompression from 'browser-image-compression';
import InputSelect from './CustomInputSelect';

const useStyles = makeStyles({
  container: {
    padding: '20px',
    backgroundColor: '#FAFAFA',
    borderRadius: '10px',
    border: '0.5px solid #CFCFCF',
  },
});

const initialUpdateData = {
  name: '',
  phone: '',
  address: '',
  gstNumber: '',
  confirmGstNumber: '',
  perRewardPointsPrice: 0
};

const OwnerProfile = ({ data, refreshData }) => {
  const { updateOwnerProfile, setShowMessage, updateOwnerProfileImage } = useMain();

  const [updateType, setUpdateType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [logo, setLogo] = useState('');
  const [updateFormData, setUpdateFormData] = useState(initialUpdateData);

  const classes = useStyles();
 

  const handleOpenDialogue = (type) => {
    setOpenDialogue(true);
    setUpdateType(type);

    if (data) {
      setUpdateFormData({
        name: data.name || '',
        phone: data.phone || '',
        address: data.address || '',
        gstNumber: data.gstNumber || '',
        confirmGstNumber: data.gstNumber || '',
        perRewardPointsPrice: data.perRewardPointsPrice || 0,
      });
    }
  };

  const handleCloseDialogue = () => {
    setUpdateType(null);
    setLogo('');
    setUpdateFormData(initialUpdateData);
    setOpenDialogue(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateData = async () => {
    setLoading(true);
    try {
       // Set GST number to empty string if it is not provided
    const formDataToUpdate = {
      ...updateFormData,
      gstNumber: updateFormData.gstNumber.trim() === '' ? '' : updateFormData.gstNumber,
      confirmGstNumber: updateFormData.confirmGstNumber.trim() === '' ? '' : updateFormData.confirmGstNumber,
    };

      const res = await updateOwnerProfile(data._id, formDataToUpdate);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully Updated Profile',
          messageType: 'success',
        });
        setUpdateFormData(initialUpdateData);
        refreshData(data._id);
        setUpdateType(null);
        setOpenDialogue(false);
      } else {
        setShowMessage({ message: res.message || 'Update Profile Failed', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleImage = async (e) => {
    const imageFile = e.target?.files[0];

    if (imageFile) {
      const maxSizeInBytes = 1024 * 1024; // 1MB
      const currentSizeInBytes = imageFile.size;

      if (currentSizeInBytes <= maxSizeInBytes) {
        setLogo(imageFile);
      } else {
        try {
          const options = {
            maxSizeMB: 1,
            useWebWorker: true,
          };
          const compressedImage = await imageCompression(imageFile, options);
          setLogo(compressedImage);
        } catch (error) {
          setShowMessage({
            message: 'Error compressing image. Please try again.',
            messageType: 'error',
          });
        }
      }
    }
  };

  const handleUpdateDisplayImg = async () => {
    const formDataUpdated = new FormData();
    formDataUpdated.append('logo', logo);

    try {
      setLoading(true);
      const res = await updateOwnerProfileImage(data._id, formDataUpdated);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully Updated Profile Image',
          messageType: 'success',
        });
        refreshData(data._id);
        setUpdateType(null);
        setLogo('');
        setOpenDialogue(false);
      } else {
        setShowMessage({
          message: res.message || 'Update Profile Image Failed',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Box display={'flex'} alignItems={'center'} gap={'20px'}>
        <Avatar
          src={data?.logo ? data?.logo?.url : ProfileImg}
          alt={data?.logo ? data?.logo?.url : ProfileImg}
          sx={{
            width: 45,
            height: 45,
            cursor: 'pointer',
          }}
          onClick={() => {
            handleOpenDialogue('UPDATEAVATAR');
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleOpenDialogue('UPDATEPROFILE');
          }}
        >
          Update Profile
        </Button>

        {/* Owner Details Dialog */}
        <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
          <DialogTitle>Owner Details</DialogTitle>
          <DialogContent>
            <DialogContentText>Here you can update Owner details.</DialogContentText>
            {updateType === 'UPDATEPROFILE' ? (
              <Box style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextInputs
                      title="Name"
                      name="name"
                      value={updateFormData.name}
                      handleChange={handleChange}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInputs
                      title="Contact Number"
                      name="phone"
                      value={updateFormData.phone}
                      handleChange={handleChange}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInputs
                      title="Address"
                      name="address"
                      value={updateFormData.address}
                      handleChange={handleChange}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInputs
                      title="Gst Number"
                      name="gstNumber"
                      value={updateFormData.gstNumber}
                      handleChange={handleChange}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInputs
                      title="Confirm Gst Number"
                      name="confirmGstNumber"
                      value={updateFormData.confirmGstNumber}
                      handleChange={handleChange}
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label style={{ padding: '2px', display: 'block', marginBottom: '0' }}>
                      Per Reward Point Value
                    </label>
                    <InputSelect
                      name="perRewardPointsPrice"
                      value={updateFormData.perRewardPointsPrice}
                      onChange={handleChange}
                      options={[
                        { value: 0.05, label: '0.05' },
                        { value: 0.10, label: '0.10' },
                        { value: 0.15, label: '0.15' },
                        { value: 0.20, label: '0.20' },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={4}>
                  <TextInputs
                    title={'Update Logo'}
                    name={'logo'}
                    handleChange={handleImage}
                    type={'file'}
                  />
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {loading ? (
              <ShowLoader />
            ) : (
              <>
                <Button onClick={handleCloseDialogue}>Cancel</Button>
                {updateType === 'UPDATEAVATAR' ? (
                  <Button onClick={handleUpdateDisplayImg}>Update</Button>
                ) : (
                  <Button onClick={handleUpdateData} style={{ backgroundColor: '#6174DD', color: 'white' }}>Update</Button>
                )}
              </>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default OwnerProfile;
