import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import './BranchDetails.css';
import { Grid } from '@mui/material';
import ProfileImg from '../../../src/assets/images/profile/user-1.jpg';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const BranchDetails = () => {
  const { getBranch, setShowMessage } = useMain();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const getData = async (id) => {
    try {
      setLoading(true);
      const res = await getBranch(id);
      if (res.statusCode === 200) setData(res.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
    return () => {
      setData({});
    };
  }, [id]);

  return (
    <PageContainer title="Branch Details" description="This is Branch Details Page">
      <DashboardCard2 title="Branch Details">
        {loading ? (
          <ShowLoader />
        ) : Object.keys(data).length === 0 ? (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              color: 'red',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            No Data Found
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  float: 'right',
                }}
              >
                <button
                  style={{
                    backgroundColor: '#6174DD',
                    padding: '10px 15px',
                    color: '#fff',
                    borderRadius: '5px',
                    fontSize: '14px',
                    border: 'none',
                  }}
                >
                  Update Account
                </button>
              </div> */}
              <div className="owner-container">
                <div className="owner-header">
                  <div className="profile-img">
                    <img
                      src={data?.logo ? data?.logo?.url : ProfileImg}
                      alt={data?.logo ? data?.logo?.url : ProfileImg}
                      style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '50%', // This property creates a circular border
                        objectFit: 'cover', // Ensures the image covers the entire container while maintaining its aspect ratio
                      }}
                    />
                  </div>
                  <div>
                    <p className="owner-text">Full Name : </p>
                    <h6 className="owner-bold-title">{data?.branchName
                    }</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="owner-body">
                      <p className="owner-text">Email : </p>
                      <h6 className="owner-title">{data?.branchEmail}</h6>
                    </div>
                    <div className="owner-body">
                      <p className="owner-text">Created Date : </p>
                      <h6 className="owner-title">{convertDateFormat(data?.createdAt)}</h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="owner-body">
                      <p className="owner-text">Mobile : </p>
                      <h6 className="owner-title">+91 {data?.BranchPhone}</h6>
                    </div>
                    <div className="owner-body">
                      <p className="owner-text">Address : </p>
                      <h6 className="owner-title">
                        {data?.ownerAddress?.address} {data?.address}
                      </h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="owner-body">
                      <p className="owner-text">Subscription Details : </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="owner-body">
                      <p className="owner-text">GST Number : </p>
                      <h6 className="owner-title">
                        {data?.gstNumber}
                      </h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="owner-body">
                      <p className="owner-text">Start At : </p>
                      <h6 className="owner-title">
                        {data?.subscriptionDetails?.duration?.startDate}
                      </h6>
                    </div>
                    <div className="owner-body">
                      <p className="owner-text">End At : </p>
                      <h6 className="owner-title">
                        {data?.subscriptionDetails?.duration?.endDate}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default BranchDetails;

function convertDateFormat(createdAt) {
  // Create a Date object from the provided string
  const dateObject = new Date(createdAt);

  // Get day, month, and year
  const day = String(dateObject.getUTCDate()).padStart(2, '0');
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
  const year = dateObject.getUTCFullYear();

  // Combine into the desired format
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}
