import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllMemberships = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/membership/getmembership/${branchId}${queryParams ? queryParams : ''}`,
    {
      headers: jwtHeaders()
    }
  );
  const resData = await response?.json();

  return resData;
};

export const fetchMembershipDetail = async (id, branchId) => {
  const response = await fetch(`${baseUrl}/api/membership/getamembership/${id}${branchId ? branchId : ''}`, {
    method: 'GET',
    headers: jwtHeaders()
  })
  const resData = await response?.json();
  return resData;
}

export const createMembership = async (data) => {
  const response = await fetch(`${baseUrl}/api/membership/create`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const updateMembership = async (id, data, branchId) => {
  const response = await fetch(`${baseUrl}/api/membership/updatemembership/${id}${branchId ? branchId : ''}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

// export const deleteMembership = async (id,branchId) => {
//   const response = await fetch(`${baseUrl}/api/membership/deletemembership/${id}${branchId ? branchId : ''}`, {
//     method: 'DELETE',
//     headers: jwtHeaders(),
//   });
//   const resData = await response?.json();

//   return resData;
// };

export const deleteMembership = async (id, branchId) => {
  const response = await fetch(`${baseUrl}/api/membership/deletemembership/${branchId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...jwtHeaders(),
    },
    body: JSON.stringify({
      ids: [id],
      status: false,
    })
  });
  const resData = await response?.json();

  return resData;
};

//Packages Api
export const createPackages = async (data) => {
  const response = await fetch(`${baseUrl}/api/service/servicespackage`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const fetchAllPackages = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/service/allpackage?branchId=${branchId}`, {
    method: 'GET',
    headers: jwtHeaders()
  })
  const resData = await response?.json();
  return resData;
}

export const fetchSinglePackageDetail = async (packageId, branchId) => {
  const response = await fetch(`${baseUrl}/api/service/singlepackage/${packageId}?branchId=${branchId}`, {
    method: 'GET',
    headers: jwtHeaders()
  })
  const resData = await response?.json();
  return resData;
}

export const updatePackage = async (packageId, data, branchId) => {
  const response = await fetch(`${baseUrl}/api/service/updatepackage/${packageId}${branchId ? branchId : ''}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const buyPackage = async (branchId, data) => {
  const response = await fetch(`${baseUrl}/api/client/buypackage?branchId=${branchId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};