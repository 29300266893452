// import React from 'react';
// import { HANDLE_MEMBERSHIP_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

// const MembershipCard = ({ data, state, dispatch, onClose }) => {
//   const container = {
//     display: 'flex',
//     flexDirection: 'column',
//     width: '50vw',
//     minHeight: '90vh',
//     padding: '30px',
//     gap: '10px',
//   };

//   const button = {
//     background: '#6174DD',
//     color: 'white',
//     border: 'none',
//     padding: '10px 30px',
//     cursor: 'pointer',
//     borderRadius: '5px',
//   };

//   const applyHandler = (data) => {
//     dispatch({
//       type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
//       payload: { useMembership: true, item: data },
//     });
//     onClose();
//   };
//   const removeHandler = (data) => {
//     dispatch({
//       type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
//       payload: { useMembership: false, item: data },
//     });
//     onClose();
//   };

//   return (
//     <div style={{ ...container, overflow: 'auto' }}>
//       {data.membershipType === 'discountType' ? (
//         <div style={{ height: '100vh' }}>
//           <table className="table-container">
//             <thead className="table-thead">
//               <tr>
//                 {[
//                   'Membership Type',
//                   'Start Date',
//                   'End Date',
//                   'Remaining Count',
//                   'Minimum Bill Amount',
//                 ].map((h, index) => {
//                   return (
//                     <th key={index} style={{ width: '100%' }}>
//                       {h}
//                     </th>
//                   );
//                 })}
//               </tr>
//             </thead>

//             <tbody className="table-tbody">
//               <tr>
//                 <td style={{ width: '100%' }}>{data?.membershipType}</td>
//                 <td style={{ width: '100%' }}>{data?.startDate}</td>
//                 <td style={{ width: '100%' }}>{data?.endDate}</td>
//                 <td style={{ width: '100%' }}>{data?.discount.availableCount}</td>
//                 <td style={{ width: '100%' }}>{data?.discount.minBillAmount}</td>
//               </tr>
//             </tbody>
//           </table>
//           <table className="table-container">
//             <thead className="table-thead">
//               <tr>
//                 {['Discount On Service', 'Discount On Product'].map(
//                   (h, index) => {
//                     return (
//                       <th key={index} style={{ width: '100%' }}>
//                         {h}
//                       </th>
//                     );
//                   },
//                 )}
//               </tr>
//             </thead>
//             <tbody className="table-tbody">
//               <tr>
//                 <td
//                   style={{ width: '100%' }}
//                 >{`${data?.discount?.discountOnService}   ${data?.discount?.serviceDiscountType}`}</td>
//                 <td
//                   style={{ width: '100%' }}
//                 >{`${data?.discount?.discountOnProduct}   ${data?.discount?.productDiscountType}`}</td>
//                 {/* <td
//                   style={{ width: '100%' }}
//                 >{`${data?.discount?.discountOnPackages}   ${data?.discount?.packageDiscountType}`}</td> */}
//               </tr>
//             </tbody>
//           </table>
//           {data?.discount.availableCount < 1 ? (
//             <span style={{ color: 'red' }}>Your Available Count is not valid</span>
//           ) : calculatePriceSubTotal([...state.services, ...state.products]) <
//             data?.discount.minBillAmount ? (
//             <span style={{ color: 'red' }}>
//               Add items worth ₹
//               {`${
//                 data?.discount.minBillAmount -
//                 calculatePriceSubTotal([...state.services, ...state.products])
//               }`}{' '}
//               more to unlock
//             </span>
//           ) : (
//             ''
//           )}
//           {calculatePriceSubTotal([...state.services, ...state.products]) >=
//             data?.discount.minBillAmount &&
//           data?.discount.availableCount > 0 &&
//           state.useMembership ? (
//             <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
//               <button style={{ ...button }} onClick={() => removeHandler(data)}>
//                 Remove
//               </button>
//             </div>
//           ) : (
//             <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
//               <button style={{ ...button }} onClick={() => applyHandler(data)}>
//                 apply
//               </button>
//             </div>
//           )}
//         </div>
//       ) : (
//         <div style={{ height: '100vh' }}>
//           <table className="table-container">
//             <thead className="table-thead">
//               <tr>
//                 {['Membership Type', 'Start Date', 'End Date'].map((h, index) => {
//                   return (
//                     <th key={index} style={{ width: '100%' }}>
//                       {h}
//                     </th>
//                   );
//                 })}
//               </tr>
//             </thead>

//             <tbody className="table-tbody">
//               <tr>
//                 <td style={{ width: '100%' }}>{data?.membershipType}</td>
//                 <td style={{ width: '100%' }}>{data?.startDate}</td>
//                 <td style={{ width: '100%' }}>{data?.endDate}</td>
//               </tr>
//             </tbody>
//           </table>
//           <table className="table-container">
//             <thead className="table-thead">
//               <tr>
//                 {['Service Name', 'Category', 'Remaining Count'].map((h, index) => {
//                   return (
//                     <th key={index} style={{ width: '100%' }}>
//                       {h}
//                     </th>
//                   );
//                 })}
//               </tr>
//             </thead>
//             <tbody className="table-tbody">
//               {data?.services.map((item) => {
//                 return (
//                   <tr key={item.id}>
//                     <td style={{ width: '100%' }}>{item?.serviceName}</td>
//                     <td style={{ width: '100%' }}>{item?.category}</td>
//                     <td style={{ width: '100%' }}>{item?.count}</td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//           {hasMatchingServiceId(state.services, data.services) && hasNonZeroCount(data.services) ? (
//             state.useMembership ? (
//               <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
//                 <button style={{ ...button }} onClick={() => removeHandler(data)}>
//                   Remove
//                 </button>
//               </div>
//             ) : (
//               <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
//                 <button style={{ ...button }} onClick={() => applyHandler(data)}>
//                   apply
//                 </button>
//               </div>
//             )
//           ) : (
//             <span style={{ color: 'red' }}>
//               Your Services count zero or please select a Matched services
//             </span>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default MembershipCard;

// function hasMatchingServiceId(array1, array2) {
//   const serviceIdsSet = new Set(array1?.map((item) => item.service));
//   for (const item of array2) {
//     if (serviceIdsSet.has(item.id)) {
//       return true;
//     }
//   }
//   return false;
// }

// function hasNonZeroCount(services) {
//   for (const service of services) {
//     if (service.count !== 0) {
//       return true; // Found a service with a non-zero count
//     }
//   }
//   return false; // All counts are zero
// }

// function calculatePriceSubTotal(items) {
//   return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
// }


import React, { useEffect, useState } from 'react';
import { HANDLE_MEMBERSHIP_INPUT_CHANGE } from 'src/reducers/billReducer/billConstent';

const MembershipCard = ({ data, state, dispatch, onClose, handleUpdateServices }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    // Sync selectedServices only when data changes, not based on selectedServices state
    if (data?.services) {
      const initialSelectedServices = data?.services.filter(service =>
        state.services.some(selected => selected.id === service.id)
      );
      setSelectedServices(initialSelectedServices);
    }
  }, [data, state.services]);

  const handleCheckboxChange = (service) => {
    const isSelected = selectedServices.some((item) => item.id === service.id);
    const newSelectedServices = isSelected
      ? selectedServices.filter((item) => item.id !== service.id)
      : [...selectedServices, service];

    setSelectedServices(newSelectedServices);
  };

  const applyHandler = () => {
    const updatedServices = selectedServices.map((service) => ({
      ...service,
      serviceProvider: "",
      service: service.id,
      quantity: 1,
      discount: 100,
      discountType: '%',
      amount: 0,
    }));

    dispatch({
      type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
      payload: { useMembership: true, item: data, selectedServices: updatedServices },
    });
    handleUpdateServices(updatedServices);
    onClose();
  };

  const removeHandler = () => {
    dispatch({
      type: HANDLE_MEMBERSHIP_INPUT_CHANGE,
      payload: { useMembership: false, item: data, selectedServices: [] },
    });
    onClose();
  };

  const container = {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    minHeight: '70vh',
    padding: '30px',
    gap: '10px',
  };

  const button = {
    background: '#6174DD',
    color: 'white',
    border: 'none',
    padding: '10px 30px',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  const isServiceDisabled = (service) => {
    return service.count === 0;
  };

  const canApplyDiscount = calculatePriceSubTotal([...state.services, ...state.products]) >= (data?.discount?.minBillAmount || 0);

  return (
    <div style={{
      ...container,
      overflow: 'hidden', // Prevent outer scroll bars, use inner scroll bars
      maxHeight: '60vh', // Set max height for the dialog
      overflowY: 'auto', // Add vertical scroll if content exceeds 60vh
      padding: '20px', // Optional padding for better spacing
      boxSizing: 'border-box', // Ensure padding is included in height calculation
    }}>


       <div style={{
        // backgroundColor: '#6174DD', // Background color for the heading
        color: 'black', // Text color
        padding: '15px 20px', // Padding for spacing
        borderRadius: '10px 10px 0 0', // Rounded top corners
        fontSize: '1.5rem', // Font size for better visibility
        fontWeight: 'bold', // Bold font for emphasis
        textAlign: 'center', // Center-align text
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        marginBottom: '20px', // Space between heading and content
      }}>
        Membership Card
      </div>

      {data?.membershipType === 'discountType' ? (
        <div style={{ height: '100vh' }}>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {[
                  'Membership Type',
                  'Start Date',
                  'End Date',
                  'Remaining Count',
                  'Minimum Bill Amount',
                ].map((h, index) => (
                  <th key={index} style={{ width: '100%' }}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '100%' }}>{data?.membershipType}</td>
                <td style={{ width: '100%' }}>{data?.startDate}</td>
                <td style={{ width: '100%' }}>{data?.endDate}</td>
                <td style={{ width: '100%' }}>{data?.discount?.availableCount || 0}</td>
                <td style={{ width: '100%' }}>{data?.discount?.minBillAmount || 0}</td>
              </tr>
            </tbody>
          </table>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {['Discount On Service', 'Discount On Product'].map((h, index) => (
                  <th key={index} style={{ width: '100%' }}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '100%' }}>
                  {`${data?.discount?.discountOnService || 0} ${data?.discount?.serviceDiscountType || ''}`}
                </td>
                <td style={{ width: '100%' }}>
                  {`${data?.discount?.discountOnProduct || 0} ${data?.discount?.productDiscountType || ''}`}
                </td>
              </tr>
            </tbody>
          </table>
          {canApplyDiscount && (data?.discount?.availableCount || 0) > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
              <button style={{ ...button }} onClick={applyHandler}>Apply</button>
            </div>
          ) : (
            <span style={{ color: 'red' }}>
              {data?.discount?.minBillAmount -
                calculatePriceSubTotal([...state.services, ...state.products]) > 0
                ? `Add ₹${data?.discount?.minBillAmount -
                calculatePriceSubTotal([...state.services, ...state.products])} more to unlock`
                : 'Your available count is zero or the minimum bill amount does not match'}
            </span>
          )}
        </div>
      ) : (
        <div style={{ height: '50vh' }}>
          <table className="table-container">
            <thead className="table-thead">
              <tr>
                {['Select', 'Service Name', 'Category', 'Remaining Count'].map((h, index) => (
                  <th key={index} style={{ width: '100%', padding: '7px' }}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody className="table-tbody">
              {data?.services.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: item.count === 0
                      ? '#F3F2F2'
                      : selectedServices.some((selected) => selected.id === item.id)
                        ? '#F4F0F0'
                        : '#f9f9f9',
                    color: selectedServices.some((selected) => selected.id === item.id) ? 'black' : 'black',
                    cursor: item.count === 0 ? 'not-allowed' : 'pointer',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)', // Add a subtle box shadow
                    borderRadius: '5px', // Optional, for slightly rounded edges
                    marginBottom: '10px',
                  }}
                >
                  <td style={{ width: '100%', padding: '7px' }}>
                    <input
                      type="checkbox"
                      checked={selectedServices.some((service) => service.id === item.id)}
                      onChange={() => handleCheckboxChange(item)}
                      disabled={isServiceDisabled(item)}
                      style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    />
                  </td>
                  <td style={{ width: '100%', padding: '7px' }}>{item?.serviceName}</td>
                  <td style={{ width: '100%', padding: '7px' }}>{item?.category}</td>
                  <td style={{ width: '100%', padding: '7px' }}>{item?.count}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {hasMatchingServiceId(selectedServices, data?.services) && hasNonZeroCount(data?.services) ? (
            state.useMembership ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button style={{ ...button }} onClick={removeHandler}>Remove</button>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <button
                  style={{ ...button }}
                  onClick={applyHandler}
                  disabled={selectedServices.length === 0}
                >
                  Apply
                </button>
              </div>
            )
          ) : (
            <span style={{ color: 'red' }}>
              Your Services count is zero or please select a matching service
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default MembershipCard;

function hasMatchingServiceId(array1, array2) {
  const serviceIdsSet = new Set(array1?.map((item) => item.id));
  for (const item of array2) {
    if (serviceIdsSet.has(item.id)) {
      return true;
    }
  }
  return false;
}

function hasNonZeroCount(services) {
  return services.some(service => service.count > 0);
}

function calculatePriceSubTotal(items) {
  return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
}





