// import React, { useEffect, useState } from 'react';
// import {
//   Autocomplete,
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Grid,
//   TextField,
//   Typography,
// } from '@mui/material';

// import TableCells from 'src/components/Tables/TableCells';

// import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
// import { useMain } from '../hooks/useMain';
// import TextInputs from 'src/components/inputs/TextInputs';
// import RadioInputs from 'src/components/inputs/RadioInputs';
// import { ReqField } from 'src/components/CustomComponents/CustomLoader';
// import SelectInputs from 'src/components/inputs/SelectInputs';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';

// const enquiryType = [
//   { label: 'Hot', value: 'Hot' },
//   { label: 'Cold', value: 'Cold' },
//   { label: 'Warm', value: 'Warm' },
// ];

// const sourceOfEnquiry = [
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Walking', value: 'Walking' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'Website', value: 'Website' },
// ];

// const leadStatus = [
//   { label: 'Pending', value: 'Pending' },
//   { label: 'Converted', value: 'Converted' },
//   { label: 'Closed', value: 'Closed' },
// ];

// const FieldData = ({ data, setData, getData, checkedClientEnquiry, setCheckedClientEnquiry }) => {
//   const { activeBranchId, getBranch, updateClientEnquiry, deleteClientEnquiry, setShowMessage } =
//     useMain();

//   const [formData, setFormData] = useState({});
//   const [selectedItemId, setSelectedItemId] = useState(null);
//   const [enquiryFor, setEnquiryFor] = useState([]);
//   const [isAllChecked, setIsAllChecked] = useState(false);
//   const [openDialogue, setOpenDialogue] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const handleCloseDialogue = () => {
//     setOpenDialogue(false);
//   };

//   const handleUpdate = (item) => {
//     const { branchDetails, createdAt, sendSmsAs, updatedAt, __v, _id, ...rest } = item;
//     setFormData({ ...rest, dateOfFollow: convertIsoDateToYYYYMMDD(rest?.dateOfFollow) });
//     setSelectedItemId(item._id);
//     setOpenDialogue(true);
//   };

//   // const handleupdateEnquiry = async () => {
//   //   setLoading(true);
//   //   try {
//   //     // Exclude the "dateOfFollow" field from the formData object
//   //     const { dateOfFollow, ...formDataWithoutDate } = formData;
//   //     const { availability, ...formDataWithoutavailabilty } = formData;

//   //     const res = await updateClientEnquiry(
//   //       selectedItemId,
//   //       formDataWithoutDate, // Use formDataWithoutDate instead of formData
//   //       formDataWithoutavailabilty,
//   //       `?branchId=${activeBranchId}`,
//   //     );
//   //     if (res?.statusCode === 200) {
//   //       setShowMessage({ message: res.message || 'Enquiry Updated', messageType: 'success' });
//   //       setData([]);
//   //       getData();
//   //     } else {
//   //       setShowMessage({ message: res.message || 'Enquiry not updated', messageType: 'error' });
//   //     }
//   //   } catch (error) {
//   //     setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
//   //   } finally {
//   //     setLoading(false);
//   //     setOpenDialogue(false); // Close the dialog
//   //   }
//   // };

//   const handleUpdateEnquiry = async () => {
//     setLoading(true);
//     try {
//       // Exclude both "dateOfFollow" and "availability" fields from the formData object
//       const { dateOfFollow, availability, ...formDataWithoutDateAndAvailability } = formData;

//       const res = await updateClientEnquiry(
//         selectedItemId,
//         formDataWithoutDateAndAvailability, // Use formDataWithoutDateAndAvailability instead of formData
//         `?branchId=${activeBranchId}`,
//       );
//       if (res?.statusCode === 200) {
//         setShowMessage({ message: res.message || 'Enquiry Updated', messageType: 'success' });
//         setData([]);
//         getData();
//       } else {
//         setShowMessage({ message: res.message || 'Enquiry not updated', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
//     } finally {
//       setLoading(false);
//       setOpenDialogue(false); // Close the dialog
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((pre) => ({ ...pre, [name]: value }));
//   };

//   const handleDeleteField = async (id) => {
//     try {
//       const res = await deleteClientEnquiry(id, `?branchId=${activeBranchId}`);
//       if (res?.statusCode === 200) {
//         setShowMessage({
//           message: res.message || 'Enquiry has been deleted',
//           messageType: 'success',
//         });
//         setData(data?.filter((data) => data?._id !== id));
//       } else {
//         setShowMessage({
//           message: res.message || 'Enquiry has not been deleted',
//           messageType: 'error',
//         });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
//     } finally {
//     }
//   };

//   const handleAllCheckboxChange = () => {
//     if (isAllChecked) {
//       setCheckedClientEnquiry([]);
//     } else {
//       setCheckedClientEnquiry(data);
//     }
//     setIsAllChecked(!isAllChecked);
//   };

//   const handleSignleCheckboxChange = (clientEnquiryId) => {
//     if (
//       checkedClientEnquiry.length &&
//       checkedClientEnquiry.some((check) => check._id === clientEnquiryId)
//     ) {
//       setCheckedClientEnquiry((prev) => prev.filter((check) => check._id !== clientEnquiryId));
//     } else {
//       const checked = data.filter((item) => item._id === clientEnquiryId);
//       setCheckedClientEnquiry((prev) => [...prev, ...checked]); // Create a new array
//     }
//   };

//   useEffect(() => {
//     setIsAllChecked((data?.length && checkedClientEnquiry?.length === data?.length) || false);
//   }, [checkedClientEnquiry?.length, data?.length]);

//   const getAllData = async () => {
//     try {
//       const res = await getBranch(activeBranchId);

//       const products = res?.data?.products || [];
//       const services = res?.data?.services || [];

//       const productOptions = products.map((product) => ({
//         label: product.productName,
//         value: product.productName,
//       }));

//       const serviceOptions = services.map((service) => ({
//         label: service.serviceName,
//         value: service.serviceName,
//       }));

//       // Combine product and service options into a single array
//       setEnquiryFor([...productOptions, ...serviceOptions]);
//     } catch (error) { }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getAllData();
//     }
//   }, [activeBranchId]);

//   return (
//     <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
//       <table className="table-container mt-3">
//         <thead className="table-thead">
//           <tr>
//             <th style={{ width: '20%' }}>
//               <CheckboxInputs
//                 type={'Heading'}
//                 isAllChecked={isAllChecked}
//                 handleAllCheckboxChange={handleAllCheckboxChange}
//               />
//             </th>
//             {[
//               'Name',
//               'Phone',
//               'Gender',
//               'Date to follow',
//               'Enquiry Type',
//               'Lead Status',
//               'Enquiry for',
//               'Source Of Enquiry',
//               'Actions',
//             ].map((h, index) => {
//               return (
//                 <th key={index} style={{ width: '100%' }}>
//                   {h}
//                 </th>
//               );
//             })}
//           </tr>
//         </thead>
//         <tbody className="table-tbody">
//           {data.map((item) => {
//             return (
//               <tr key={item._id}>
//                 <td style={{ width: '20%' }}>
//                   <CheckboxInputs
//                     type={'content'}
//                     handleSignleCheckboxChange={handleSignleCheckboxChange}
//                     checkedItems={checkedClientEnquiry}
//                     fieldId={item?._id}
//                   />
//                 </td>
//                 <td style={{ width: '100%' }}>{item?.clientName}</td>
//                 <td style={{ width: '100%' }}>{item?.clientNumber}</td>
//                 <td style={{ width: '100%' }}>{item?.gender}</td>
//                 <td style={{ width: '100%' }}>{convertIsoDateToYYYYMMDD(item?.dateOfFollow)}</td>
//                 <td style={{ width: '100%' }}>{item?.enquiryType}</td>
//                 <td style={{ width: '100%' }}>{item?.leadStatus}</td>
//                 <td style={{ width: '100%' }}>{item?.enquiryFor}</td>
//                 <td style={{ width: '100%' }}>{item?.sourceOfEnquiry}</td>
//                 <td style={{ width: '100%' }}>
//                   <TableCells
//                     type="button"
//                     handleDeleteField={handleDeleteField}
//                     handleUpdate={() => handleUpdate(item)}
//                     // handleDetails={()=>{}}
//                     field={item?._id}
//                   />
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//       <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
//         <DialogTitle>Expense Details</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Here you can update expense details.</DialogContentText>
//           <Grid container style={{ gap: '20px', marginTop: '20px' }}>
//             <Grid container spacing={2}>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="clientNumber"
//                   title="Contact number"
//                   placeholder="Contact Number"
//                   type="number"
//                   value={formData?.clientNumber}
//                   disabled={true}
//                   handleChange={handleInputChange}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="clientName"
//                   title="Client name"
//                   placeholder="Client name"
//                   type="text"
//                   value={formData?.clientName}
//                   handleChange={handleInputChange}
//                   disabled={true}
//                 />
//               </Grid>
//               <Grid
//                 item
//                 xs={3}
//                 sx={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <RadioInputs
//                   title={'Gender'}
//                   name={'gender'}
//                   value={formData?.gender}
//                   handleChange={handleInputChange}
//                   options={[
//                     { title: 'M', value: 'Male' },
//                     { title: 'F', value: 'Female' },
//                   ]}
//                   disabled={true}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="address"
//                   title="Address"
//                   placeholder="Address"
//                   type="text"
//                   required={false}
//                   value={formData?.address}
//                   handleChange={handleInputChange}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container spacing={2}>
//               <Grid item xs={3}>
//                 <Typography
//                   variant="subtitle1"
//                   fontWeight={550}
//                   component="label"
//                   htmlFor="enquiryFor"
//                   mb="4px"
//                 >
//                   Enquiry for <ReqField />
//                 </Typography>
//                 <Autocomplete
//                   id="enquiryFor"
//                   name="enquiryFor"
//                   options={enquiryFor}
//                   value={
//                     formData && formData.enquiryFor
//                       ? enquiryFor.find((option) => option.value === formData.enquiryFor)
//                       : null
//                   }
//                   onChange={(event, result) =>
//                     handleInputChange({ target: { name: 'enquiryFor', value: result?.value } })
//                   }
//                   isOptionEqualToValue={(option, value) => option.value === value.value}
//                   getOptionLabel={(option) => option.label}
//                   // renderOption={(props, option) => (
//                   //   <Box component="li" {...props}>
//                   //     {option.label}
//                   //   </Box>
//                   // )}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       type="text"
//                       variant="outlined"
//                       placeholder="Servies / Products / Packages"
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <SelectInputs
//                   title="Enquiry type"
//                   name="enquiryType"
//                   value={formData?.enquiryType}
//                   handleChange={handleInputChange}
//                   options={enquiryType}
//                   optionValue="value"
//                   optionTitle="label"
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="clientResponse"
//                   title="Response"
//                   placeholder="Response"
//                   type="text"
//                   required={false}
//                   value={formData?.clientResponse}
//                   handleChange={handleInputChange}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="dateOfFollow"
//                   title="Date to follow"
//                   placeholder="Response"
//                   type="Date"
//                   value={formData?.dateOfFollow}
//                   handleChange={handleInputChange}
//                   disabled={true}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container spacing={2}>
//               <Grid item xs={3}>
//                 <SelectInputs
//                   title="Source of enquiry"
//                   name="sourceOfEnquiry"
//                   value={formData?.sourceOfEnquiry}
//                   handleChange={handleInputChange}
//                   options={sourceOfEnquiry}
//                   optionValue="value"
//                   optionTitle="label"
//                   disabled={true}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextInputs
//                   name="leadRepresentative"
//                   title="Lead representative"
//                   type="text"
//                   placeholder="representative"
//                   required={false}
//                   value={formData?.leadRepresentative}
//                   handleChange={handleInputChange}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <SelectInputs
//                   title="Lead status"
//                   name="leadStatus"
//                   value={formData?.leadStatus}
//                   handleChange={handleInputChange}
//                   options={leadStatus}
//                   optionValue="value"
//                   optionTitle="label"
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <>
//               <div style={{ width: '200px', margin: '2rem auto' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={handleCloseDialogue}
//                 >
//                   Cancel
//                 </Button>
//               </div>
//               <div style={{ width: '200px', margin: '3rem auto' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={handleUpdateEnquiry}
//                 >
//                   update
//                 </Button>
//               </div>
//             </>
//           )}
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default FieldData;

// function convertIsoDateToYYYYMMDD(isoDate) {
//   const dateObject = new Date(isoDate);
//   const year = dateObject.getFullYear();
//   const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
//   const day = dateObject.getDate().toString().padStart(2, '0');

//   return `${year}-${month}-${day}`;
// }
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import TableCells from 'src/components/Tables/TableCells';

import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import SelectInputs from 'src/components/inputs/SelectInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const enquiryType = [
  { label: 'Hot', value: 'Hot' },
  { label: 'Cold', value: 'Cold' },
  { label: 'Warm', value: 'Warm' },
];

const sourceOfEnquiry = [
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Walking', value: 'Walking' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Website', value: 'Website' },
];

const leadStatus = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Converted', value: 'Converted' },
  { label: 'Closed', value: 'Closed' },
];

const FieldData = ({ data, setData, getData, checkedClientEnquiry, setCheckedClientEnquiry }) => {
  const { activeBranchId, getBranch, updateClientEnquiry, deleteClientEnquiry, setShowMessage } =
    useMain();

  const [formData, setFormData] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [enquiryFor, setEnquiryFor] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleUpdate = (item) => {
    const { branchDetails, createdAt, sendSmsAs, updatedAt, __v, _id, ...rest } = item;
    setFormData({ ...rest, dateOfFollow: convertIsoDateToYYYYMMDD(rest?.dateOfFollow) });
    setSelectedItemId(item._id);
    setOpenDialogue(true);
  };

  // const handleupdateEnquiry = async () => {
  //   setLoading(true);
  //   try {
  //     // Exclude the "dateOfFollow" field from the formData object
  //     const { dateOfFollow, ...formDataWithoutDate } = formData;
  //     const { availability, ...formDataWithoutavailabilty } = formData;

  //     const res = await updateClientEnquiry(
  //       selectedItemId,
  //       formDataWithoutDate, // Use formDataWithoutDate instead of formData
  //       formDataWithoutavailabilty,
  //       `?branchId=${activeBranchId}`,
  //     );
  //     if (res?.statusCode === 200) {
  //       setShowMessage({ message: res.message || 'Enquiry Updated', messageType: 'success' });
  //       setData([]);
  //       getData();
  //     } else {
  //       setShowMessage({ message: res.message || 'Enquiry not updated', messageType: 'error' });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
  //   } finally {
  //     setLoading(false);
  //     setOpenDialogue(false); // Close the dialog
  //   }
  // };

  const handleUpdateEnquiry = async () => {
    setLoading(true);
    try {
      // Exclude both "dateOfFollow" and "availability" fields from the formData object
      const { dateOfFollow, availability, ...formDataWithoutDateAndAvailability } = formData;

      const res = await updateClientEnquiry(
        selectedItemId,
        formDataWithoutDateAndAvailability, // Use formDataWithoutDateAndAvailability instead of formData
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({ message: res.message || 'Enquiry Updated', messageType: 'success' });
        setData([]);
        getData();
      } else {
        setShowMessage({ message: res.message || 'Enquiry not updated', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false); // Close the dialog
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteClientEnquiry(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setShowMessage({
  //         message: res.message || 'Enquiry has been deleted',
  //         messageType: 'success',
  //       });
  //       setData(data?.filter((data) => data?._id !== id));
  //     } else {
  //       setShowMessage({
  //         message: res.message || 'Enquiry has not been deleted',
  //         messageType: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   } finally {
  //   }
  // };

  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  };

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deleteClientEnquiry(deleteId, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Enquiry deleted successfully',
          messageType: 'success',
        });
        // Filter out the deleted enquiry from the data
        setData(prevData => prevData.filter((d) => d._id !== deleteId));
        await getData(); // Refresh the data after deletion, if necessary
      } else {
        setShowMessage({
          message: res.message || 'Enquiry not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };


  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedClientEnquiry([]);
    } else {
      setCheckedClientEnquiry(data);
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleSignleCheckboxChange = (clientEnquiryId) => {
    if (
      checkedClientEnquiry.length &&
      checkedClientEnquiry.some((check) => check._id === clientEnquiryId)
    ) {
      setCheckedClientEnquiry((prev) => prev.filter((check) => check._id !== clientEnquiryId));
    } else {
      const checked = data.filter((item) => item._id === clientEnquiryId);
      setCheckedClientEnquiry((prev) => [...prev, ...checked]); // Create a new array
    }
  };

  useEffect(() => {
    setIsAllChecked((data?.length && checkedClientEnquiry?.length === data?.length) || false);
  }, [checkedClientEnquiry?.length, data?.length]);

  const getAllData = async () => {
    try {
      const res = await getBranch(activeBranchId);

      const products = res?.data?.products || [];
      const services = res?.data?.services || [];

      const productOptions = products.map((product) => ({
        label: product.productName,
        value: product.productName,
      }));

      const serviceOptions = services.map((service) => ({
        label: service.serviceName,
        value: service.serviceName,
      }));

      // Combine product and service options into a single array
      setEnquiryFor([...productOptions, ...serviceOptions]);
    } catch (error) { }
  };

  useEffect(() => {
    if (activeBranchId) {
      getAllData();
    }
  }, [activeBranchId]);

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            <th style={{ width: '20%' }}>
              <CheckboxInputs
                type={'Heading'}
                isAllChecked={isAllChecked}
                handleAllCheckboxChange={handleAllCheckboxChange}
              />
            </th>
            {[
              'Name',
              'Phone',
              'Gender',
              'Date to follow',
              'Enquiry Type',
              'Lead Status',
              'Enquiry for',
              'Source Of Enquiry',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            const originalDate = convertIsoDateToYYYYMMDD(item?.dateOfFollow);

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedFollowDate = '';
            if (originalDate) {
              const [year, month, day] = originalDate.split('-');
              formattedFollowDate = `${day}-${month}-${year}`;
            }
            return (
              <tr key={item._id}>
                <td style={{ width: '20%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedClientEnquiry}
                    fieldId={item?._id}
                  />
                </td>
                <td style={{ width: '100%' }}>{item?.clientName}</td>
                <td style={{ width: '100%' }}>{item?.clientNumber}</td>
                <td style={{ width: '100%' }}>{item?.gender}</td>
                <td style={{ width: '100%' }}>{formattedFollowDate}</td>
                <td style={{ width: '100%' }}>{item?.enquiryType}</td>
                <td style={{ width: '100%' }}>{item?.leadStatus}</td>
                <td style={{ width: '100%' }}>{item?.enquiryFor}</td>
                <td style={{ width: '100%' }}>{item?.sourceOfEnquiry}</td>
                {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    // handleDetails={()=>{}}
                    field={item?._id}
                  />
                </td> */}
                <td style={{
                  width: '100%',
                }}>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item?._id)} color="error" title="Delete" style={{ marginLeft: '-33px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this Enquiry?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Expense Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update expense details.</DialogContentText>
          <Grid container style={{ gap: '20px', marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextInputs
                  name="clientNumber"
                  title="Contact number"
                  placeholder="Contact Number"
                  type="number"
                  value={formData?.clientNumber}
                  disabled={true}
                  handleChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInputs
                  name="clientName"
                  title="Client name"
                  placeholder="Client name"
                  type="text"
                  value={formData?.clientName}
                  handleChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RadioInputs
                  title={'Gender'}
                  name={'gender'}
                  value={formData?.gender}
                  handleChange={handleInputChange}
                  options={[
                    { title: 'M', value: 'Male' },
                    { title: 'F', value: 'Female' },
                  ]}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInputs
                  name="address"
                  title="Address"
                  placeholder="Address"
                  type="text"
                  required={false}
                  value={formData?.address}
                  handleChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  fontWeight={550}
                  component="label"
                  htmlFor="enquiryFor"
                  mb="4px"
                >
                  Enquiry for <ReqField />
                </Typography>
                <Autocomplete
                  id="enquiryFor"
                  name="enquiryFor"
                  options={enquiryFor}
                  value={
                    formData && formData.enquiryFor
                      ? enquiryFor.find((option) => option.value === formData.enquiryFor)
                      : null
                  }
                  onChange={(event, result) =>
                    handleInputChange({ target: { name: 'enquiryFor', value: result?.value } })
                  }
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.label}
                  // renderOption={(props, option) => (
                  //   <Box component="li" {...props}>
                  //     {option.label}
                  //   </Box>
                  // )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      variant="outlined"
                      placeholder="Servies / Products / Packages"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectInputs
                  title="Enquiry type"
                  name="enquiryType"
                  value={formData?.enquiryType}
                  handleChange={handleInputChange}
                  options={enquiryType}
                  optionValue="value"
                  optionTitle="label"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInputs
                  name="clientResponse"
                  title="Response"
                  placeholder="Response"
                  type="text"
                  required={false}
                  value={formData?.clientResponse}
                  handleChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInputs
                  name="dateOfFollow"
                  title="Date to follow"
                  placeholder="Response"
                  type="Date"
                  value={formData?.dateOfFollow}
                  handleChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SelectInputs
                  title="Source of enquiry"
                  name="sourceOfEnquiry"
                  value={formData?.sourceOfEnquiry}
                  handleChange={handleInputChange}
                  options={sourceOfEnquiry}
                  optionValue="value"
                  optionTitle="label"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInputs
                  name="leadRepresentative"
                  title="Lead representative"
                  type="text"
                  placeholder="representative"
                  required={false}
                  value={formData?.leadRepresentative}
                  handleChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectInputs
                  title="Lead status"
                  name="leadStatus"
                  value={formData?.leadStatus}
                  handleChange={handleInputChange}
                  options={leadStatus}
                  optionValue="value"
                  optionTitle="label"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '2rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateEnquiry}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;

function convertIsoDateToYYYYMMDD(isoDate) {
  const dateObject = new Date(isoDate);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = dateObject.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}