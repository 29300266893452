// //multiple select code
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select,IconButton } from '@mui/material';
// import { useNavigate } from 'react-router';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import FieldData from './FieldData';
// import BigFilter from 'src/components/inputs/BigFilter';
// // import EnqFil from './EnqFil';
// import { MdClear } from "react-icons/md";

// const filterSourceOfEnquiry = [
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Walking', value: 'Walking' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'Website', value: 'Website' },
// ];

// const filterLeadStatus = [
//   { label: 'Pending', value: 'Pending' },
//   { label: 'Converted', value: 'Converted' },
//   { label: 'Closed', value: 'Closed' },
// ];

// const filterEnquiryType = [
//   { label: 'Hot', value: 'Hot' },
//   { label: 'Cold', value: 'Cold' },
//   { label: 'Warm', value: 'Warm' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllEnquiry = () => {
//   const { fetchAllEquiry, activeBranchId } = useMain();

//   const [data, setData] = useState([]);
//   const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState([]);
//   const [sourceOfEnquiry, setSourceOfEnquiry] = useState([]);
//   const [leadStatus, setLeadStatus] = useState([]);
//   const [enquiryType, setEnquiryType] = useState([]);
//   const [clear, setClear] = useState(false);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const [filterData, setFilterData] = useState(null);
//   console.log(filterData);

//   const navigate = useNavigate()


//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSourceOfEnquiry([]);
//     setLeadStatus([]);
//     setEnquiryType([]);
//     setGenderBy([]);
//     getData();
//     // if (clear) {
//     //   getData('clear');
//     //   setClear(false);
//     // }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     try {
//       setShowLoader(true);
//       const res = await fetchAllEquiry(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       // setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = genderBy.length === 0 || genderBy.includes(item.gender);
//       let isSourceMatch = sourceOfEnquiry.length === 0 || sourceOfEnquiry.includes(item.sourceOfEnquiry);
//       let isTypeMatch = enquiryType.length === 0 || enquiryType.includes(item.enquiryType);
//       let isStatusMatch = leadStatus.length === 0 || leadStatus.includes(item.leadStatus);
//       let isNameMatch = !search || item.clientName.toLowerCase().includes(search.toLowerCase()) || item.clientNumber.includes(search);

//       return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
//     });

//     setFilterData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const totalPages = Math.ceil(data.length / limit);

//   return (
//     <PageContainer title="All Enquiries" description="This contains all Enquiries">
//       <DashboardCard2 title="All Enquiries">
//         <div style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           flexWrap: 'wrap',
//           marginBottom: '1.7rem',
//           marginRight: '-0.5rem'
//         }}>
//           <div style={{ display: 'flex', flexDirection: 'column', marginRight: '0rem' }}>
//             <InputLabel>Show per page</InputLabel>
//             <Select
//               style={{
//                 borderRadius: '7px',
//                 border: '0.5px solid #6174DD',
//                 padding: '4px 12px',
//                 background: '#FFF',
//                 height: '40px',
//               }}
//               value={limit}
//               onChange={handleLimitChange}
//             >
//               <MenuItem value={10}>10</MenuItem>
//               <MenuItem value={20}>20</MenuItem>
//               <MenuItem value={50}>50</MenuItem>
//             </Select>
//           </div>

//           <div style={{ display: 'flex', alignItems: 'center', width: '25%',}}>
//             <BigFilter
//               options={[
//                 { title: 'Gender', items: filterGenderWise },
//                 { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
//                 { title: 'Lead Status', items: filterLeadStatus },
//                 { title: 'Enquiry Type', items: filterEnquiryType },
//               ]}
//               value={[...genderBy, ...sourceOfEnquiry, ...leadStatus, ...enquiryType]}
//               onChange={(selectedValues) => {
//                 setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
//                 setSourceOfEnquiry(selectedValues.filter(val => filterSourceOfEnquiry.some(opt => opt.value === val)));
//                 setLeadStatus(selectedValues.filter(val => filterLeadStatus.some(opt => opt.value === val)));
//                 setEnquiryType(selectedValues.filter(val => filterEnquiryType.some(opt => opt.value === val)));
//               }}
//               placeholder="Filters"
//             />
//           </div>

//           <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: '27rem'}} onClick={() => document.getElementById('search-input').focus()}>
//             {/* <div style={{ marginRight: '1rem' }} onClick={() => document.getElementById('search-input').focus()}> */}
//             <InputLabel style={{ marginLeft: "-5.5rem" }}>Search</InputLabel>
//             <div className="search-input" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '60%' }}>
//               <img src={SearchImg} alt="" />
//               <input
//                 id="search-input"
//                 type="text"
//                 placeholder=""
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//                 style={{ marginLeft: '0.5rem'}} // Makes input take available space
//               />
//               {search && (
//                 <IconButton
//                   onClick={() => {
//                     setSearch('');
//                     // setClear(false);
//                   }}
//                   style={{ padding: 1 }}
//                 >
//                   <MdClear style={{
//                     // color: '#ff4d4d',
//                     color: '#000',
//                     // color: '#A2A3AE',
//                     marginRight: '-0.2rem', marginTop: '0.1rem',
//                     fontSize: '1.1rem'
//                   }} />
//                 </IconButton>
//               )}
//             </div>
//           </div>

//           <div style={{
//             display: 'flex', alignItems: 'center', marginTop: '1.8rem',
//             // gap: '1rem' 
//             gap: '0.6rem'
//           }}>
//             <Button
//               onClick={() => { navigate('/clientReview') }}
//               variant="contained"
//               color="primary"
//               sx={{
//                 background: '#6174DD',
//                 padding: '0.5rem',
//                 width: '100%', // Make button full width on smaller screens
//                 marginRight: '0.5rem' // Minimal gap between buttons
//               }}
//             >
//               Client Reviews
//             </Button>

//             {/* <Button
//             variant="contained"
//             color="primary"
//             sx={{
//               height: '40px',
//               width: '120px', // Set a fixed width for the Clear button
//               background: '#ff4d4d',
//               '&:hover': {
//                 background: '#ff6666',
//               },
//             }}
//             onClick={clearDataHandler}
//           >
//             Clear
//           </Button> */}
//           </div>
//         </div>


//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <FieldData
//                   // data={filterData}
//                   data={filterData.slice((page - 1) * limit, page * limit)}
//                   setData={setData}
//                   getData={getData}
//                   checkedClientEnquiry={checkedClientEnquiry}
//                   setCheckedClientEnquiry={setCheckedClientEnquiry}
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={totalPages}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer >
//   );
// };

// export default AllEnquiry;
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useNavigate } from 'react-router';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import FieldData from './FieldData';
import BigFilter from 'src/components/inputs/BigFilter';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const filterSourceOfEnquiry = [
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Walking', value: 'Walking' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Website', value: 'Website' },
];

const filterLeadStatus = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Converted', value: 'Converted' },
  { label: 'Closed', value: 'Closed' },
];

const filterEnquiryType = [
  { label: 'Hot', value: 'Hot' },
  { label: 'Cold', value: 'Cold' },
  { label: 'Warm', value: 'Warm' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllEnquiry = () => {
  const { fetchAllEquiry, activeBranchId } = useMain();
  const [data, setData] = useState([]);
  const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState([]);
  const [sourceOfEnquiry, setSourceOfEnquiry] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [enquiryType, setEnquiryType] = useState([]);
  const [clear, setClear] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setSourceOfEnquiry([]);
    setLeadStatus([]);
    setEnquiryType([]);
    setGenderBy([]);
    getData();
    // if (clear) {
    //   getData('clear');
    //   setClear(false);
    // }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    try {
      setShowLoader(true);
      const res = await fetchAllEquiry(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        // Handle errors here
      }
    } catch (error) {
      // Handle errors here
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      // setClear(true);
      getData();
    }
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

  // const applyFilters = () => {
  //   let filteredData = data.filter((item) => {
  //     let isGenderMatch = genderBy.length === 0 || genderBy.includes(item.gender);
  //     let isSourceMatch = sourceOfEnquiry.length === 0 || sourceOfEnquiry.includes(item.sourceOfEnquiry);
  //     let isTypeMatch = enquiryType.length === 0 || enquiryType.includes(item.enquiryType);
  //     let isStatusMatch = leadStatus.length === 0 || leadStatus.includes(item.leadStatus);
  //     let isNameMatch = !search || item.clientName.toLowerCase().includes(search.toLowerCase()) || item.clientNumber.includes(search);

  //     return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilterData(filteredData);
  // };
  const applyFilters = () => {
    let filteredData = data.filter((item) => {
      let isGenderMatch = genderBy.length === 0 || genderBy.includes(item.gender);
      let isSourceMatch = sourceOfEnquiry.length === 0 || sourceOfEnquiry.includes(item.sourceOfEnquiry);
      let isTypeMatch = enquiryType.length === 0 || enquiryType.includes(item.enquiryType);
      let isStatusMatch = leadStatus.length === 0 || leadStatus.includes(item.leadStatus);
      let isNameMatch = !search || item.clientName.toLowerCase().includes(search.toLowerCase()) || item.clientNumber.includes(search);

      return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
    });

    setFilterData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const totalPages = Math.ceil(filterData.length / limit);

  return (
    <PageContainer title="All Enquiries" description="This contains all Enquiries">
      <DashboardCard2 title="All Enquiries">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginBottom: '1.7rem',
          marginRight: '-0.5rem'
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '0rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20rem', width: '19%' }}>
            <BigFilter
              options={[
                { title: 'Gender', items: filterGenderWise },
                { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
                { title: 'Lead Status', items: filterLeadStatus },
                { title: 'Enquiry Type', items: filterEnquiryType },
              ]}
              value={[...genderBy, ...sourceOfEnquiry, ...leadStatus, ...enquiryType]}
              onChange={(selectedValues) => {
                setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                setSourceOfEnquiry(selectedValues.filter(val => filterSourceOfEnquiry.some(opt => opt.value === val)));
                setLeadStatus(selectedValues.filter(val => filterLeadStatus.some(opt => opt.value === val)));
                setEnquiryType(selectedValues.filter(val => filterEnquiryType.some(opt => opt.value === val)));
              }}
              placeholder="Filters"
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column',marginLeft: '-3rem'}} onClick={() => document.getElementById('search-input').focus()}>
          {/* <div style={{ marginRight: '1rem' }} onClick={() => document.getElementById('search-input').focus()}> */}
            <InputLabel style={{ marginLeft: "-6.5rem" }}>Search</InputLabel>
            <div className="search-input" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <img src={SearchImg} alt="" />
              <input
                id="search-input"
                type="text"
                placeholder=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginLeft: '0.5rem', flex: 1 }} // Makes input take available space
              />
              {search && (
                <IconButton
                  onClick={() => {
                    setSearch('');
                    // setClear(false);
                  }}
                  style={{ padding: 1 }}
                >
                  <ClearIcon style={{
                    // color: '#ff4d4d',
                    color: '#000',
                    // color: '#A2A3AE',
                    marginRight: '-0.2rem', marginTop: '0.1rem',
                    fontSize: '1.1rem'
                  }} />
                </IconButton>
              )}
            </div>
          </div>

          <div style={{
            display: 'flex', alignItems: 'center', marginTop: '1.8rem',
            // gap: '1rem' 
            gap: '0.6rem' 
          }}>
            {/* <Button
              onClick={() => { navigate('/clientReview') }}
              variant="contained"
              color="primary"
              sx={{
                background: '#6174DD',
                padding: '0.5rem',
                width: '100%', // Make button full width on smaller screens
                marginRight: '0.5rem' // Minimal gap between buttons
              }}
            >
              Client Reviews
            </Button> */}

            {/* <Button
              variant="contained"
              color="primary"
              sx={{
                height: '40px',
                width: '120px', // Set a fixed width for the Clear button
                background: '#ff4d4d',
                '&:hover': {
                  background: '#ff6666',
                },
              }}
              onClick={clearDataHandler}
            >
              Clear
            </Button> */}
          </div>
        </div>


        {showLoader ? (
          <ShowLoader />
        ) : filterData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData
                  // data={filterData}
                  data={filterData.slice((page - 1) * limit, page * limit)}
                  setData={setData}
                  getData={getData}
                  checkedClientEnquiry={checkedClientEnquiry}
                  setCheckedClientEnquiry={setCheckedClientEnquiry}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={totalPages}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllEnquiry;