import { Grid, TextField, Autocomplete, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import PageContainer from 'src/components/container/PageContainer';
import SelectInputs from 'src/components/inputs/SelectInputs';
import TextInputs from 'src/components/inputs/TextInputs';
import DashboardCard from 'src/components/shared/DashboardCard';
import { useMain } from '../hooks/useMain';
import ClinetSuggestion from '../Appointments/ClinetSuggestion';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import RadioInputs from 'src/components/inputs/RadioInputs';

const initialFormData = {
  clientNumber: '',
  clientName: '',
  gender: '',
  address: '',
  email: '',
  enquiryFor: '',
  enquiryType: 'Hot',
  clientResponse: '',
  sourceOfEnquiry: 'Walking',
  leadRepresentative: '',
  leadStatus: 'Pending',
  dateOfFollow: getCurrentDateInFormate(),
};

const initialFormValidation = {
  nameValidation: false,
  numberValidation: false,
  genderValidation: false,
  enquiryForValidation: false,
  dateOfFollowValidation: false,
};

const enquiryType = [
  { label: 'Hot', value: 'Hot' },
  { label: 'Cold', value: 'Cold' },
  { label: 'Warm', value: 'Warm' },
];

const sourceOfEnquiry = [
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Walking', value: 'Walking' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Website', value: 'Website' },
];

const leadStatus = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Converted', value: 'Converted' },
  { label: 'Closed', value: 'Closed' },
];

const AddEnquiry = () => {
  const { activeBranchId, getBranch, setShowMessage, createClientEnquiry } = useMain();

  const [formData, setFormData] = useState(initialFormData);
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [client, setClient] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [activeInputField, setActiveInputField] = useState('');
  const [enquiryFor, setEnquiryFor] = useState([]);
  const [loading, setLoading] = useState(false);

  const nameValidation = !formData.clientName.trim();
  const numberValidation = !formData.clientNumber || formData.clientNumber.length !== 10;
  const genderValidation = !formData.gender;
  const enquiryForValidation = !formData.enquiryFor;
  const dateOfFollowValidation = !formData.dateOfFollow;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
    if (name === 'clientNumber' || name === 'clientName') {
      updateSuggested(name, value);
    }
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (inputValue === '') {
      // setSuggested([]);
    } else {
      const suggestions = client?.filter((client) =>
        client[fieldName]?.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setSuggested(suggestions);
    }
  };

  const handleSuggestedClick = (client) => {
    const { clientNumber, clientName, gender } = client;
    setFormData((pre) => ({ ...pre, clientNumber, clientName, gender }));
    setSuggested([]);
  };

  const handleSubmit = async () => {
    const { clientNumber, clientName, enquiryFor, gender, dateOfFollow } = formData;

    const validation = {
      nameValidation: !clientName.trim(),
      numberValidation: !clientNumber || clientNumber.length !== 10,
      genderValidation: !gender,
      enquiryForValidation: !enquiryFor,
      dateOfFollowValidation: !dateOfFollow,
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    setLoading(true);
    try {
      const res = await createClientEnquiry({
        ...formData,
        dateOfFollow: new Date(getCurrentDateInFormate()).toISOString(),
        branchDetails: activeBranchId,
      });
      if (res.statusCode === 200) {
        setFormData({
          ...initialFormData,
          dateOfFollow: getCurrentDateInFormate(),
        });
        setFormValidation(pre=>({...initialFormValidation}))
        setShowMessage({ message: res.message || 'New Enquiry Added', messageType: 'success' });
      } else {
        setShowMessage({ message: res.message || 'Enquiry not Added', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: 'Some Error Occured', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputFocus = () => {
    setSuggested([]);
    const activeElement = document.activeElement;
    if (activeElement && activeElement.getAttribute('name')) {
      setActiveInputField(activeElement.getAttribute('name'));
    } else {
      setActiveInputField('');
    }
  };

  useEffect(() => {
    document.addEventListener('focus', handleInputFocus, true);
    return () => {
      document.removeEventListener('focus', handleInputFocus, true);
    };
  }, [activeInputField, Object.keys(client).length]);

  const getAllData = async () => {
    try {
      const res = await getBranch(activeBranchId);

      setClient(res?.data?.client || []);
      const products = res?.data?.products || [];
      const services = res?.data?.services || [];

      const productOptions = products.map((product) => ({
        label: product.productName,
        value: product.productName,
      }));

      const serviceOptions = services.map((service) => ({
        label: service.serviceName,
        value: service.serviceName,
      }));

      // Combine product and service options into a single array
      setEnquiryFor([...productOptions, ...serviceOptions]);
    } catch (error) {}
  };

  useEffect(() => {
    if (activeBranchId) {
      getAllData();
    }
  }, [activeBranchId]);

  return (
    <PageContainer title="Add New Enquiry" description="This is add new equiry page">
      <DashboardCard title="Add New Enquiry">
        <Grid container style={{ gap: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInputs
                name="clientNumber"
                title="Contact Number"
                placeholder="Contact Number"
                type="number"
                value={formData?.clientNumber}
                handleChange={handleInputChange}
                fieldValidation={numberValidation && formValidation.numberValidation}
              />
              {suggested.length > 0 && activeInputField === 'clientNumber' && (
                <ClinetSuggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid>
            <Grid item xs={3}>
              <TextInputs
                name="clientName"
                title="Client Name"
                placeholder="Client Name"
                type="text"
                value={formData?.clientName}
                handleChange={handleInputChange}
                fieldValidation={nameValidation && formValidation.nameValidation}
              />
              {suggested.length > 0 && activeInputField === 'clientName' && (
                <ClinetSuggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RadioInputs
                title={'Gender'}
                name={'gender'}
                value={formData?.gender}
                handleChange={handleInputChange}
                options={[
                  { title: 'M', value: 'Male' },
                  { title: 'F', value: 'Female' },
                ]}
                fieldValidation={genderValidation && formValidation.genderValidation}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputs
                name="address"
                title="Address"
                placeholder="Address"
                type="text"
                required={false}
                value={formData?.address}
                handleChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                fontWeight={550}
                component="label"
                htmlFor="enquiryFor"
                mb="4px"
              >
                Enquiry For <ReqField />
              </Typography>
              <Autocomplete
                id="enquiryFor"
                name="enquiryFor"
                options={enquiryFor}
                value={
                  formData && formData.enquiryFor
                    ? enquiryFor.find((option) => option.value === formData.enquiryFor)
                    : null
                }
                onChange={(event, result) =>
                  handleInputChange({ target: { name: 'enquiryFor', value: result?.value } })
                }
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.label}
                // renderOption={(props, option) => (
                //   <Box component="li" {...props}>
                //     {option.label}
                //   </Box>
                // )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    variant="outlined"
                    placeholder="Servies / Products / Packages"
                  />
                )}
              />
              {enquiryForValidation && formValidation.enquiryForValidation && (
                <Typography color="error" fontSize="12px" fontWeight={100} ml="15px" mt="2px">
                  Enquiry for is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <SelectInputs
                title="Enquiry Type"
                name="enquiryType"
                value={formData?.enquiryType}
                handleChange={handleInputChange}
                options={enquiryType}
                optionValue="value"
                optionTitle="label"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputs
                name="clientResponse"
                title="Response"
                placeholder="Response"
                type="text"
                required={false}
                value={formData?.clientResponse}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputs
                name="dateOfFollow"
                title="Date to Follow"
                placeholder="Response"
                type="Date"
                value={formData?.dateOfFollow}
                handleChange={handleInputChange}
                fieldValidation={dateOfFollowValidation && formValidation.dateOfFollowValidation}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectInputs
                title="Source of Enquiry"
                name="sourceOfEnquiry"
                value={formData?.sourceOfEnquiry}
                handleChange={handleInputChange}
                options={sourceOfEnquiry}
                optionValue="value"
                optionTitle="label"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputs
                name="leadRepresentative"
                title="Lead Representative"
                type="text"
                placeholder="representative"
                required={false}
                value={formData?.leadRepresentative}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInputs
                title="Lead Status"
                name="leadStatus"
                value={formData?.leadStatus}
                handleChange={handleInputChange}
                options={leadStatus}
                optionValue="value"
                optionTitle="label"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '200px', margin: '1rem auto'}}>
              {loading ? (
                <ShowLoader />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleSubmit}
                >
                  Add Enquiry
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddEnquiry;

// Function to get the current date in a specific format
function getCurrentDateInFormate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
