import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  IconButton,
} from '@mui/material';
import './PackagePage.css';
import { useMain } from '../../hooks/useMain';

import TextInputs from 'src/components/inputs/TextInputs';
import { GrStar } from 'react-icons/gr';
import { ButtonToggle } from 'reactstrap';
import SearchImg from '../../../assets/search.svg';
import { Grid } from 'rsuite';
import { Box, display } from '@mui/system';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { MdClear } from "react-icons/md";

const PackagePage = () => {
  const { activeBranchId, getAllService, setShowMessage, createPackages } = useMain();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [showPackageCard, setShowPackageCard] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [packageDetails, setPackageDetails] = useState({
    packageName: '',
    validTill: '',
    totalAmount: 0,
  });
  const [loading, setLoader] = useState(false);

  useEffect(() => {
    applyFilters(services, limit, page, search);
  }, [services, limit, page, search]);

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    if (clear) {
      getAllServicefData('clear');
      setServices('clear');
      setClear(false);
    }
  };

  const getAllServicefData = async () => {
    try {
      const res = await getAllService(activeBranchId);
      setServices(res?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getAllServicefData();
    }
    return () => {
      setServices([]);
    };
  }, [activeBranchId]);

  const handleCheckboxChange = (service) => {
    setSelectedServices((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.service._id === service._id);
      if (isSelected) {
        return prevSelected.filter((item) => item.service._id !== service._id);
      } else {
        return [...prevSelected, { service }];
      }
    });
  };

  const handlePackageDetailsChange = (e) => {
    const { name, value } = e.target;
    setPackageDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const recalculateTotalAmount = (updatedServices) => {
    const totalAmount = updatedServices.reduce((total, item) => total + item.service.price || 0, 0);
    setPackageDetails((prevDetails) => ({
      ...prevDetails,
      totalAmount,
    }));
  };

  const calculateTotalSelectedPrice = () => {
    return selectedServices.reduce((total, item) => total + (item.service.price || 0), 0);
  };

  const handleOpenDialog = () => {
    setShowPackageCard(true);
    setOpenDialog(true);
    recalculateTotalAmount(selectedServices);
    setPackages([selectedServices]);
    setSelectedServices([]);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPackages([]);
    setPackageDetails({
      packageName: '',
      validTill: '',
      totalAmount: 0,
    });
  };

  const handleSubmitPackage = async () => {
    const packageData = {
      packageName: packageDetails.packageName,
      price: packageDetails.totalAmount,
      services: packages[0].map((item) => item.service._id),
      validTill: packageDetails.validTill,
      branchDetails: activeBranchId,
    };
    //console.log(packageData, "packageDataaaaaaaaaaa");

    try {
      setLoader(true);
      const res = await createPackages(
        JSON.stringify({
          ...packageData,
        }),
      );

      handleCloseDialog();
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Package created successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Package not created', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Error creating Packages',
        messageType: 'error',
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getAllServicefData();
    }
    return () => {
      setServices([]);
    };
  }, [activeBranchId, limit, page]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // const applyFilters = (services, limit, page, searchName) => {
  //   let filteredData = services.filter((item) => {
  //     let isNameMatch = true;
  //     if (searchName) {
  //       isNameMatch =
  //         item.serviceName.toLowerCase().includes(searchName.toLowerCase()) ||
  //         item.serviceName.includes(searchName);
  //     }
  //     return isNameMatch;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilterData(filteredData);
  // };


  const applyFilters = (services, limit, page, searchName) => {
    let filteredData = services.filter((item) => {
      return item.serviceName.toLowerCase().includes(searchName.toLowerCase());
    });

    // Set the filtered data for rendering
    setFilterData(filteredData);
    // Pagination logic
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);

    setFilterData(filteredData);
  };

  // Reset page when search input changes
  useEffect(() => {
    setPage(1);
  }, [search]);

  // Adjust pagination count based on filtered data
  const paginationCount = Math.ceil(services.filter((item) =>
    item.serviceName.toLowerCase().includes(search.toLowerCase())
  ).length / limit);


  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleClearSelectedServices = () => {
    setSearch('');
    setLimit(10); // Reset limit to default
    setPage(1);
    setSelectedServices([]);
  };

  return (
    <PageContainer title="Package" description="this contains all packages">
      <DashboardCard2 title="Packages">
        {/* <DashboardCard2 > */}
        <div className="package-container" style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: 3 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem',
                marginTop: '-3rem',
                // marginLeft: '-20rem',
                marginLeft: '-35rem'
              }}
            >
              <div>
                {/* <h2>Packages</h2> */}
              </div>
              <div>
                <Grid item xs={2} style={{ marginLeft: '-1rem' }} >
                  <InputLabel>Show per page</InputLabel>
                  <Select
                    style={{
                      borderRadius: '7px',
                      border: '0.5px solid #6174DD',
                      padding: '4px 12px',
                      background: '#FFF',
                      height: '40px',
                    }}
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Grid>
              </div>
              <div className="search-input" style={{ width: '10rem', marginTop: '1.6rem', marginRight: '-1rem' }}>
                <img src={SearchImg} alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      // Additional clear logic if necessary
                    }}
                    style={{ padding: 1 }}
                  >
                    <MdClear style={{
                      color: '#000',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  // width: '100%',
                  background: '#ff4d4d',
                  marginBottom: '0.3rem',
                  marginTop: '1.8rem',
                  '&:hover': { background: '#ff6666' },
                }}
                onClick={handleClearSelectedServices}
              >
                Clear
              </Button> */}
            </div>
            <table className="table-container mt-3">
              <thead className="table-thead">
                <tr>
                  {['S.no', 'Category', 'Service name', 'Service For', 'Price', 'Select'].map(
                    (h, index) => (
                      <th key={index} style={{ width: '100%' }}>
                        {h}
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody className="table-tbody">
                {filterData.length > 0 ? (
                  filterData.map((item, index) => {
                    const isSelected = selectedServices.some(
                      (service) => service.service._id === item._id,
                    );
                    return (
                      <tr key={item._id} className={isSelected ? 'selected' : ''} style={{ backgroundColor: isSelected ? '#f0f8ff' : '#fff' }}>
                        <td style={{ width: '100%' }}>{index + 1 + (page - 1) * limit}</td>
                        <td style={{ width: '100%' }}>{item?.category}</td>
                        <td style={{ width: '100%' }}>{item?.serviceName}</td>
                        <td style={{ width: '100%' }}>{item?.serviceFor}</td>
                        <td style={{ width: '100%' }}>₹ {item?.price}</td>
                        <td style={{ width: '100%' }}>
                          <input
                            type="checkbox"
                            checked={isSelected}
                            style={{ cursor: 'pointer', width: '15px', height: '15px', marginLeft: '0.2rem' }}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center', padding: '20px' }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <Pagination
              style={{ display: 'flex', alignItems: 'end', justifyContent: "flex-end" }}
              // count={Math.ceil(services.length / limit)}
              count={paginationCount}
              page={page}
              onChange={(e, value) => setPage(value)}
            /> */}
            {filterData.length > 0 && (
              <Pagination
                style={{ display: 'flex', alignItems: 'end', justifyContent: "flex-end" }}
                count={paginationCount}
                page={page}
                onChange={(e, value) => setPage(value)}
              />
            )}
          </div>

          <div style={{ flex: 0.9, marginTop: '70px', marginLeft: '25px' }}>
            <h3>Selected Services</h3>
            {selectedServices.length > 0 ? (
              <ul>
                {selectedServices.map((item, index) => (
                  <li key={index}>
                    {item.service.serviceName} - ₹{item.service.price}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No services selected</p>
            )}
            {selectedServices.length > 0 && (
              <div>
                <h5>Total Price: ₹{calculateTotalSelectedPrice()}</h5>
              </div>
            )}
            <div>
              <button className="create-btn" onClick={handleOpenDialog}>
                Create Package
              </button>
            </div>
          </div>

          {showPackageCard && packages.length > 0 && (
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              // fullWidtzh='md'
              // fullWidth='cm'
              PaperProps={{
                style: {
                  width: '500px', // set your custom width here
                },
              }}
            >
              <DialogTitle>Package Details</DialogTitle>
              {packages.map((packageItems, packageIndex) => (
                <DialogContent key={packageIndex} className="dialog-content">
                  <DialogContentText>Here you can add Package</DialogContentText>
                  <div className="packagebox" style={{ display: 'flex', gap: '16px' }}>
                    <div style={{ flex: 1 }}>
                      <label>
                        Package Name
                        <GrStar
                          style={{
                            color: '#FF0000',
                            fontSize: '7.7px',
                            marginBottom: '10px',
                            marginLeft: '2px',
                            fontWeight: '900',
                          }}
                        />
                      </label>
                      <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="packageName"
                        placeholder={'Package Name'}
                        value={packageDetails.packageName}
                        onChange={handlePackageDetailsChange}
                      />
                    </div>
                  </div>

                  <div style={{ width: '100%' }}>
                    {/* <div className='heading-h6' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h6>Services</h6>
                                    <h6>Price</h6>
                                </div> */}

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: '1px solid #D3D3D3',
                              padding: '8px',
                              fontWeight: 'bolder',
                            }}
                          >
                            Service
                          </th>
                          <th
                            style={{
                              border: '1px solid #D3D3D3',
                              padding: '8px',
                              fontWeight: 'bolder',
                            }}
                          >
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageItems.map((item, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #D3D3D3', padding: '8px' }}>
                              {item.service.serviceName}
                            </td>
                            <td style={{ border: '1px solid #D3D3D3', padding: '8px' }}>
                              {item.service.price}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <div style={{ width: '100%' }}>
                      <TextInputs
                        title={'Valid Till'}
                        name={'validTill'}
                        value={packageDetails?.validTill}
                        handleChange={handlePackageDetailsChange}
                        type={'date'}
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </div>
                  </div>

                  <div style={{ width: '100%', marginTop: '20px' }}>
                    <label>
                      Package amount
                      <GrStar
                        style={{
                          color: '#FF0000',
                          fontSize: '7.7px',
                          marginBottom: '10px',
                          marginLeft: '2px',
                          fontWeight: '900',
                        }}
                      />
                    </label>
                    <Input
                      margin="dense"
                      type="number"
                      fullWidth
                      variant="outlined"
                      name="totalAmount"
                      value={packageDetails.totalAmount}
                      onChange={handlePackageDetailsChange}
                    />
                    <DialogActions>
                      <ButtonToggle onClick={handleCloseDialog} color="primary">
                        Cancel
                      </ButtonToggle>
                      <ButtonToggle onClick={handleSubmitPackage} color="primary">
                        Create
                      </ButtonToggle>
                    </DialogActions>
                  </div>
                </DialogContent>
              ))}
            </Dialog>
          )}
        </div>
      </DashboardCard2>
    </PageContainer>
  );
};

export default PackagePage;